import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
const json = {
  1: {
    // Exercise num
    unit: "Unit 4",
    id: "WB3V2-U4-P30-E1",
    audio: "",
    video: "",
    component: DesignUnderLine,
    totalInput: 2,
    exerciseKey: "img/FriendsPlus/Page30/E1/Key/answerKey.png",
    titleImage: "",
    isAllowSubmit: true,
    questionImage: [
      // Row

      [{ url: "img/FriendsPlus/Page30/E1/22.jpg" }],
      [{ url: "img/FriendsPlus/Page30/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page30/E1/2.jpg" },
        { url: "img/FriendsPlus/Page30/E1/3.jpg", input: 1 },
      ],
      [{ url: "img/FriendsPlus/Page30/E1/4.jpg" }],
      [
        { url: "img/FriendsPlus/Page30/E1/5.jpg" },
        { url: "img/FriendsPlus/Page30/E1/6.jpg", input: 1 },
      ],
      [{ url: "img/FriendsPlus/Page30/E1/7.jpg" }],
      [
        { url: "img/FriendsPlus/Page30/E1/8.jpg" },
        { url: "img/FriendsPlus/Page30/E1/9.jpg", input: 1, isCorrect: true },
      ],
      [{ url: "img/FriendsPlus/Page30/E1/10.jpg" }],
      [
        { url: "img/FriendsPlus/Page30/E1/11.jpg" },
        { url: "img/FriendsPlus/Page30/E1/12.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page30/E1/13.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page30/E1/14.jpg" }],
      [
        { url: "img/FriendsPlus/Page30/E1/15.jpg" },
        { url: "img/FriendsPlus/Page30/E1/16.jpg", input: 2 },
        { url: "img/FriendsPlus/Page30/E1/17.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page30/E1/18.jpg" }],
      [
        { url: "img/FriendsPlus/Page30/E1/19.jpg" },
        { url: "img/FriendsPlus/Page30/E1/20.jpg", input: 2 },
        { url: "img/FriendsPlus/Page30/E1/21.jpg" },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 4",
    id: "WB3V2-U4-P30-E2",
    audio: "",
    video: "",
    component: D1,
    // textAlign: "center",
    maxLength: 11,
    exerciseKey: "img/FriendsPlus/Page30/E2/Key/answerKey.png",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page30/E2/14.jpg" }],
      [{ url: "img/FriendsPlus/Page30/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page30/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page30/E2/3.jpg",
          input: true,
          answer: "monkey",
        },
      ],
      [{ url: "img/FriendsPlus/Page30/E2/4.jpg" }],
      [
        { url: "img/FriendsPlus/Page30/E2/5.jpg" },
        {
          url: "img/FriendsPlus/Page30/E2/6.jpg",
          input: true,
          answer: "It's small.",
        },
      ],
      [{ url: "img/FriendsPlus/Page30/E2/7.jpg" }],
      [
        { url: "img/FriendsPlus/Page30/E2/8.jpg" },
        {
          url: "img/FriendsPlus/Page30/E2/9.jpg",
          input: true,
          answer: "elephant",
        },
      ],
      [{ url: "img/FriendsPlus/Page30/E2/10.jpg" }],
      [
        { url: "img/FriendsPlus/Page30/E2/11.jpg" },
        {
          url: "img/FriendsPlus/Page30/E2/12.jpg",
          input: true,
          answer: "It's big.",
        },
      ],
      [{ url: "img/FriendsPlus/Page30/E2/13.jpg" }],
    ],
  },
};

export default json;
