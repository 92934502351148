import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 10",
    id: "WB3V2-U10-P70-E1",
    audio: "",
    video: "",
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: "img/FriendsPlus/Page70/E1/Key/answerKey.png",
    titleImage: "",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page70/E1/24.jpg" }],
      [
        { url: "img/FriendsPlus/Page70/E1/1.jpg" },
        { url: "img/FriendsPlus/Page70/E1/2.jpg", input: 1 },
      ],
      [{ url: "img/FriendsPlus/Page70/E1/3.jpg" }],
      [
        { url: "img/FriendsPlus/Page70/E1/4.jpg" },
        { url: "img/FriendsPlus/Page70/E1/5.jpg", input: 1 },
      ],
      [{ url: "img/FriendsPlus/Page70/E1/6.jpg" }],
      [
        { url: "img/FriendsPlus/Page70/E1/7.jpg" },
        { url: "img/FriendsPlus/Page70/E1/8.jpg", input: 1, isCorrect: true },
      ],
      [{ url: "img/FriendsPlus/Page70/E1/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page70/E1/10.jpg" },
        { url: "img/FriendsPlus/Page70/E1/11.jpg", input: 2 },
        { url: "img/FriendsPlus/Page70/E1/12.jpg" },
        { url: "img/FriendsPlus/Page70/E1/13.jpg", input: 3, isCorrect: true },
      ],
      [{ url: "img/FriendsPlus/Page70/E1/14.jpg" }],
      [
        { url: "img/FriendsPlus/Page70/E1/15.jpg" },
        { url: "img/FriendsPlus/Page70/E1/16.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page70/E1/17.jpg" },
        { url: "img/FriendsPlus/Page70/E1/18.jpg", input: 3 },
      ],
      [{ url: "img/FriendsPlus/Page70/E1/19.jpg" }],
      [
        { url: "img/FriendsPlus/Page70/E1/20.jpg" },
        { url: "img/FriendsPlus/Page70/E1/21.jpg", input: 2 },
        { url: "img/FriendsPlus/Page70/E1/22.jpg" },
        { url: "img/FriendsPlus/Page70/E1/23.jpg", input: 3 },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 10",
    id: "WB3V2-U10-P70-E2",
    isHiddenCheck:true,
    audio: "",
    video: "",
    component: T6,
    inputSize: 35,
    maxLength:1,
    textAligh:"center",
    fontSize:30,
    checkUppercase: true,
    // selectStyle: {
    //   color: "#00ADFE",
    //   width: 45,
    //   height: 45,
    //   textAlign: "center",
    // },
    // selectOptionValues: ["✓", "✗"],
    exerciseKey: "img/FriendsPlus/Page70/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page70/E2/a.jpg' /> ",
        color: "",
      },
    ],

    questions: [
      {
        title: `
					<div style=" position: relative; ">
						<div> <img src='img/FriendsPlus/Page70/E2/1.jpg' /> </div>
            <div style=" position: absolute; top: 31px; left: 309px; "><input id='0' height='33px'></input></div>
						<div style=" position: absolute; top: 31px; left: 353px; "><input id='1' height='33px'></input></div>
						<div style=" position: absolute; top: 31px; left: 397px; "><input id='2' height='33px'></input></div>
						<div style=" position: absolute; top: 31px; left: 442px; "><input id='3' height='33px'></input></div>
						<div style=" position: absolute; top: 31px; left: 487px; "><input id='4' height='33px'></input></div>
						<div style=" position: absolute; top: 325px; left: 353px; "><input id='5' height='33px'></input></div>
						<div style=" position: absolute; top: 325px; left: 397px; "><input id='6' height='33px'></input></div>
						<div style=" position: absolute; top: 325px; left: 442px; "><input id='7' height='33px'></input></div>
						<div style=" position: absolute; top: 325px; left: 487px; "><input id='8' height='33px'></input></div>
						<div style=" position: absolute; top: 450px; left: 408px; "><input id='9' height='33px'></input></div>
						<div style=" position: absolute; top: 450px; left: 450px; "><input id='10' height='33px'></input></div>
						<div style=" position: absolute; top: 450px; left: 494px; "><input id='11' height='33px'></input></div>
						<div style=" position: absolute; top: 450px; left: 536px; "><input id='12' width='39px' height='33px'></input></div>
					</div>
				`,
        answer: [
          "v", 
          "i", 
          "s", 
          "i", 
          "t", 
          "t", 
          "a", 
          "k", 
          "e", 
          "d", 
          "r", 
          "a", 
          "w", 
        
        ],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 10",
    id: "WB3V2-U10-P70-E3",
    audio: "",
    video: "",
    component: T6,
    hideBtnFooter: true,
    exerciseKey: "img/FriendsPlus/Page70/E3/Key/answerKey.png",
    inputSize: 800,
    // titleImage: "img/FriendsPlus/Page70/E3/1.jpg",
    titleQuestion: [
      { num: "2", title: "Write a sentence for each word.", color: "#5B5A5D" },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style="  line-height: 58px;">
            <div><b>1</b> (finished) #</div>
            <div><b>2</b> (painted) #</div>
            <div><b>3</b> (rained) #</div>
            <div><b>4</b> (showed) #</div>
            <div><b>5</b> (waited) #</div>
          </div>
        `,
        answer: ["", "", "", "", ""],
      },
    ],
  },
};

export default json;
