import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Unit 5",
    id: "WB3-U5-P33-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page33/E1/Key/answerKey.png",
    component: MatchDots,
    // titleImage: ,
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page33/E1/1.jpg'>.",
        color: "#23408f",
      },
    ],

    question: {
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "40px",
              left: "355px",
              width: 50,
              height: 60,
              // border: "2px solid"
            },
          },
          //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "40px",
              left: "465px",
              width: 50,
              height: 60,
              // border: "2px solid"
            },
          },
          //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "40px",
              left: "575px",
              width: 50,
              height: 60,
              // border: "2px solid"
            },
          },

          //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "40px",
              left: "680px",
              width: 50,
              height: 60,
              // border: "2px solid"
            },
          },
          //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "40px",
              left: "780px",
              width: 50,
              height: 60,
              // border: "2px solid"
            },
          },

          //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "125px",
              left: "250px",
              width: 50,
              height: 60,
              // border: "2px solid"
            },
          },

          //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "125px",
              left: "355px",
              width: 50,
              height: 60,
              // border: "2px solid"
            },
          },

          //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "125px",
              left: "465px",
              width: 50,
              height: 60,
              // border: "2px solid"
            },
          },
          //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "125px",
              left: "575px",
              width: 50,
              height: 60,
              // border: "2px solid"
            },
          },
          //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "125px",
              left: "680px",
              width: 50,
              height: 60,
              // border: "2px solid"
            },
          },
          //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "125px",
              left: "786px",
              width: 50,
              height: 60,
              // border: "2px solid"
            },
          },
          //11
          {
            boxMatchStyle: {
              position: "absolute",
              top: "215px",
              left: "250px",
              width: 50,
              height: 60,
              // border: "2px solid"
            },
          },
          //12
          {
            boxMatchStyle: {
              position: "absolute",
              top: "215px",
              left: "355px",
              width: 50,
              height: 60,
              // border: "2px solid"
            },
          },
          //13
          {
            boxMatchStyle: {
              position: "absolute",
              top: "215px",
              left: "465px",
              width: 50,
              height: 60,
              // border: "2px solid"
            },
          },
          //14
          {
            boxMatchStyle: {
              position: "absolute",
              top: "215px",
              left: "575px",
              width: 50,
              height: 60,
              // border: "2px solid"
            },
          },
          //15
          {
            boxMatchStyle: {
              position: "absolute",
              top: "215px",
              left: "680px",
              width: 50,
              height: 60,
              // border: "2px solid"
            },
          },
          //16
          {
            boxMatchStyle: {
              position: "absolute",
              top: "215px",
              left: "780px",
              width: 50,
              height: 60,
              // border: "2px solid"
            },
          },
        ],
        answers: ["0-7", "13-7", "13-14", "14-9", "16-9"],
        initialValue: [],
      },
      Layout: `
          <img src='img/FriendsPlus/Page33/E1/2.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
        
          <input id='7' type= 'boxMatch' />
          <input id='8' type= 'boxMatch' />
          <input id='9' type= 'boxMatch' />
          <input id='10' type= 'boxMatch' />
          <input id='11' type= 'boxMatch' />
          <input id='12' type= 'boxMatch' />
          <input id='13' type= 'boxMatch' />
          <input id='14' type= 'boxMatch' />
          <input id='15' type= 'boxMatch' />
          <input id='16' type= 'boxMatch' />
      `,
    },
  },
  2: {
    // Exercise num
    unit: "Unit 5",
    id: "WB3V2-U5-P33-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page33/E2/Key/answerKey.png",
    component: MatchDots,
    // titleImage: "<img src='img/FriendsPlus/Pag33/E2/1.jpg'>",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page33/E2/2.jpg'>",
        color: "",
      },
    ],

    question: {
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "80px",
              left: "100px",
              width: 140,
              height: 152,
              // border: "2px solid red",
            },
          },
          //0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "138px",
              left: "460px",
              width: 125,
              height: 135,
              // border: "2px solid red",
            },
          },
          //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "70px",
              left: "750px",
              width: 130,
              height: 122,
              // border: "2px solid red",
            },
          },
          //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "290px",
              left: "100px",
              width: 140,
              height: 138,
              // border: "2px solid red",
            },
          },
          //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "305px",
              left: "460px",
              width: 125,
              height: 140,
              // border: "2px solid red",
            },
          },
          //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "300px",
              left: "780px",
              width: 170,
              height: 140,
              // border: "2px solid red",
            },
          },
          //5

          //16
        ],
        answers: ["1-5", "1-2", "0-4", "3-4"],
        initialValue: [],
      },
      Layout: `
          <img src='img/FriendsPlus/Page33/E2/1.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
        
          
      `,
    },
  },
  3: {
    // Exercise num
    unit: "Unit 4",
    id: "WB3V2-U4-P33-E3",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    isHiddenCheck: true,
    maxLength: 1,
    exerciseKey: "img/FriendsPlus/Page33/E3/Key/answerKey.png",
    inputSize: 50,
    // titleImage: "img/FriendsPlus/Page32/E3/1.jpg",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page33/E3/5.jpg' />",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display:flex; margin: 20px 0px 50px 50px'>
                <div style='margin: 0px 100px 0px 0px'>
                    <img src='img/FriendsPlus/Page33/E3/1.jpg' />
                    <div style='margin: 30px 0px 0px 40px'>
                       ####
                    </div>
                </div>
                <div>
                    <img src='img/FriendsPlus/Page33/E3/2.jpg' />
                    <div style='margin: 30px 0px 0px 40px'>
                        ####
                    </div>
                </div>
          </div>
          <div style='display:flex; margin: 20px 0px 20px 50px'>
                <div style='margin: 0px 100px 0px 0px'>
                    <img src='img/FriendsPlus/Page33/E3/3.jpg' />
                    <div style='margin: 30px 0px 0px 80px'>
                       ###
                    </div>
                </div>
                <div>
                    <img src='img/FriendsPlus/Page33/E3/4.jpg' />
                    <div style='margin: 30px 0px'>
                        #######
                    </div>
                </div>
          </div>
        `,
        answer: [
          "g",
          "o",
          "a",
          "t",
          "g",
          "i",
          "f",
          "t",
          "h",
          "a",
          "t",
          "h",
          "a",
          "m",
          "s",
          "t",
          "e",
          "r",
        ],
      },
    ],
  },
};

export default json;
