import React, { useState } from "react";
import { Container, Row, Modal, ModalBody } from "reactstrap";
import "moment/locale/vi";
import moment from "moment";
import classNames from "classnames";
import "video-react/dist/video-react.css";
import {
  Player,
  BigPlayButton,
  ControlBar,
  ClosedCaptionButton,
  ReplayControl,
  ForwardControl,
  CurrentTimeDisplay,
} from "video-react";
import Animate from "../../../Animate";
import VideoModal from "../../../VideoModal";
import AudioPlayer from "../../../AudioPlayer";
import FooterModal from "../../../FooterModal";
import TitleQuestion from "../../../TitleQuestion";
import Firework from "../../../ResultsAndEffects/fireworks.js";
import "./style.css";

const PaintColorType = (props) => {
  const [activeColor, setActiveColor] = useState();

  const [modal, setModal] = useState(false);
  const [shapes, setShapes] = React.useState([
    {
      color: "#ffffff",
    },
    {
      color: "#ffffff",
    },
    {
      color: "#ffffff",
    },
    {
      color: "#ffffff",
    },
    {
      color: "#ffffff",
    },
    {
      color: "#ffffff",
    },
    {
      color: "#ffffff",
    },
    {
      color: "#ffffff",
    },
    {
      color: "#ffffff",
    },
    {
      color: "#ffffff",
    },
  ]);
  const videoRef = React.useRef();
  const [state, setState] = React.useState({
    star: 0,
    data: [],
    inputs: [],
    isDoing: true,
    audioUrl: null,
    videoUrl: null,
    booleanArray: [],
    studentAnswer: null,
    isDisabledRetry: true,
    isDisabledSubmit: true,
    audioImgUrl: null,
  });
  // const [activeColor, setActiveColor] = React.useState()
  // const videoRef = React.useRef()
  // const [modal, setModal] = useState(false)
  React.useEffect(() => {
    const clonedProps = JSON.parse(JSON.stringify(props));

    const data = clonedProps?.questionSVG;
    data.questionImage = props.questionImage;
    data.titleQuestion = props.titleQuestion;
    data.styleImageQuestion = clonedProps?.styleQuestionImage;
    data.colorList = clonedProps?.listColorQuestion;
    const audioUrl = clonedProps.audio;

    const videoUrl = clonedProps.video;

    setState((prevState) => ({ ...prevState, audioUrl, videoUrl, data }));
  }, [props]);
  const onClickColorList = React.useCallback((item, index) => {
    setState((prevState) => ({ ...prevState, chooseColor: item }));
    setActiveColor(index);
  });
  const renderColorList = React.useCallback(
    (item, index) => {
      return (
        <div
          className={
            activeColor === index ? "color__item active" : "color__item"
          }
          onClick={() => onClickColorList(item.color, index)}
          key={index}
        >
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 502.001 502.001"
          >
            <g>
              <polygon
                style={{ fill: item.color }}
                points="400.273,9.999 85.286,324.986 94.804,334.505 64.517,364.792 10,471.231 30.769,491.999 
		137.207,437.482 167.494,407.195 177.013,416.714 492,101.726 	"
              />

              <rect
                x="223.782"
                y="48.143"
                transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 341.8795 568.3177)"
                style={{ fill: "#F0EFE4" }}
                width="129.721"
                height="330.42"
              />
            </g>
            <path
              d="M499.071,94.655L407.344,2.928c-3.906-3.904-10.236-3.904-14.143,0L78.214,317.915c-1.875,1.876-2.929,4.419-2.929,7.071
			s1.054,5.196,2.93,7.071l2.447,2.447l-23.217,23.217c-0.736,0.737-1.354,1.585-1.829,2.513L1.1,466.672
			c-1.979,3.862-1.239,8.561,1.829,11.63l20.769,20.769c1.922,1.921,4.48,2.929,7.074,2.929c1.55,0,3.111-0.359,4.556-1.1
			l106.438-54.517c0.928-0.476,1.775-1.093,2.513-1.829l23.217-23.217l2.447,2.447c1.875,1.876,4.419,2.93,7.071,2.93
			s5.195-1.054,7.071-2.929l314.987-314.987C502.977,104.893,502.977,98.56,499.071,94.655z M217.684,361.899L140.1,284.315
			L359.602,64.813l77.584,77.584L217.684,361.899z M94.804,348.648l58.547,58.547l-16.145,16.145L78.66,364.792L94.804,348.648z
			 M32.691,479.779l-10.471-10.47l44.937-87.734l53.268,53.268L32.691,479.779z M99.428,324.986l26.529-26.529l77.585,77.585
			l-26.529,26.529L99.428,324.986z M451.329,128.255L373.744,50.67l26.529-26.529l77.585,77.585L451.329,128.255z"
            />
            <path
              d="M285.988,156.868L166.57,276.287c-3.905,3.905-3.905,10.237,0,14.143c1.953,1.952,4.512,2.929,7.071,2.929
			s5.118-0.977,7.071-2.929l119.419-119.419c3.905-3.905,3.905-10.237,0-14.143C296.225,152.964,289.895,152.964,285.988,156.868z"
            />
            <path
              d="M324.929,117.928l-12.384,12.384c-3.905,3.905-3.905,10.237,0,14.143c1.953,1.952,4.512,2.929,7.071,2.929
			s5.118-0.977,7.071-2.929l12.384-12.384c3.905-3.905,3.905-10.237,0-14.143C335.165,114.023,328.835,114.023,324.929,117.928z"
            />
          </svg>
        </div>
      );
    },
    [onClickColorList, state, activeColor]
  );

  const onClickImage = React.useCallback(
    (item, index, rowIndex) => {
      const url = item.audioUrl || item.videoUrl;
      if (url) {
        const timeStamp = Date.now();
        if (url?.includes(".mp4")) {
          setState((prevState) => ({ ...prevState, videoUrl: url }));
          return videoRef.current?.toggleModal("isVisibled");
        }
        if (state.audioUrl) {
          document.getElementById("myAudio")?.pause();
        }
        setState((prevState) => ({
          ...prevState,
          audioImgUrl: url,
          timeStamp,
        }));
      }
      if (!item.input || !state.isDoing) return;

      setState((prevState) => {
        const inputs = JSON.parse(JSON.stringify(prevState.inputs));
        const isTotal = inputs.length + 1 >= props.totalInput;
        if (isTotal) {
          setState((prevState) => ({ ...prevState, isDisabledSubmit: false }));
        }
        const inputIndex = inputs.findIndex((x) => x.input === item.input);

        if (inputIndex > -1) {
          if (
            inputs[inputIndex].select !== index ||
            inputs[inputIndex].rowIndex !== rowIndex
          ) {
            inputs[inputIndex].select = index;
            inputs[inputIndex].rowIndex = rowIndex;
          } else {
            inputs.splice(inputIndex, 1);
          }
        } else {
          inputs.push({ input: item.input, select: index, rowIndex });
        }
        return { ...prevState, inputs };
      });
    },
    [props.totalInput, state.audioUrl, state.isDoing]
  );
  const onClickImage2 = React.useCallback(
    (item, index, rowIndex) => () => {
      const url = item.audioUrl || item.videoUrl;
      if (url) {
        const timeStamp = Date.now();
        if (url?.includes(".mp4")) {
          setState((prevState) => ({ ...prevState, videoUrl: url }));
          return videoRef.current?.toggleModal("isVisibled");
        }
        if (state.audioUrl) {
          document.getElementById("myAudio")?.pause();
        }
        setState((prevState) => ({ ...prevState, audioUrl: url, timeStamp }));
      }
    },
    [state.audioUrl]
  );
  const renderImage = React.useCallback(
    (images, rowIndex) => {
      return images.map((item, index) => {
        return (
          <>
            <div key={index}>
              <svg
                style={item.style}
                stroke={item.stroke}
                viewBox={item.viewBox}
                x={item.x}
                y={item.y}
                onClick={() => {
                  if (state.chooseColor) {
                    shapes[index].color = state.chooseColor;
                    setShapes([...shapes]);
                    onClickImage(item, index, rowIndex);
                  } else {
                    alert("Vui lòng chọn màu để tô.");
                  }
                }}
              >
                <g
                  dangerouslySetInnerHTML={{ __html: item.g }}
                  onClick={(e) => {
                    if (e.target.id) {
                      document.getElementById(e.target.id).style.fill =
                        state.chooseColor;
                      document
                        .getElementById(e.target.id)
                        .classList.add("button");
                    }
                  }}
                ></g>
              </svg>
            </div>
          </>
        );
      });
    },
    [onClickImage, state, shapes]
  );

  const renderQuestionImage = React.useCallback(
    (imagesQuestion, imagesQuestionIndex) => {
      return imagesQuestion?.map((item, index) => {
        const { style } = item;
        let imageSrc = item.url;
        let cursor = "";
        if (item.audioUrl) {
          cursor = `url(img/volume.png) 12 12, auto`;
        }
        if (item.videoUrl) {
          cursor = `url(img/video.png) 12 12, auto`;
        }
        return (
          <div key={index} style={{ display: "grid", position: "relative" }}>
            <img
              alt="..."
              src={imageSrc}
              style={style}
              onClick={onClickImage2(item, index, imagesQuestionIndex)}
            />
          </div>
        );
      });
    },
    [onClickImage2, state, shapes]
  );

  const renderContent = React.useMemo(() => {
    return state.data?.colorList?.map((rowItem, rowIndex) => {
      return (
        <div
          key={rowIndex}
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          {/* {renderImage(rowItem, rowIndex)} */}
          {renderColorList(rowItem, rowIndex)}
        </div>
      );
    });
  }, [renderImage, state.data.colorList, state, shapes]);
  const renderContentImage = React.useMemo(() => {
    return state.data?.map((rowItem, rowIndex) => {
      return (
        <div
          key={rowIndex}
          style={{ display: "inline", marginTop: "-25px", textAlign: "center" }}
        >
          {renderImage(rowItem, rowIndex)}
        </div>
      );
    });
  }, [
    renderImage,
    state.data,
    state,
    shapes,
    props.isHiddenCheck,
    props.right,
  ]);

  const renderContentQuestionImage = React.useMemo(() => {
    return state.data?.questionImage?.map((image, indexImage) => {
      return (
        <div
          key={indexImage}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {renderQuestionImage(image, indexImage)}
        </div>
      );
    });
  }, [renderQuestionImage, state.data?.questionImage]);
  const onSubmit = React.useCallback(() => {
    let studentAnswer = [];
    let booleanArray = [];

    state.data.forEach((item, index) => {
      state.inputs.forEach((input, i) => {
        if (input.rowIndex === index) {
          const itemSelected = item[input.select];
          const isCorrect = itemSelected.isCorrect ?? false;
          booleanArray.push(isCorrect);
          studentAnswer.push(itemSelected);
        }
      });
    });
    if (booleanArray.length !== props.totalInput) {
      // trường hợp chọn ít hơn đáp án
      const loop = props.totalInput - booleanArray.length;
      for (let index = 0; index < loop; index++) {
        booleanArray.push(false);
      }
    }
    let correctAnswer = 0;
    booleanArray.forEach((item, index) => {
      if (item) {
        correctAnswer++;
      }
    });
    const percent = parseInt((correctAnswer / booleanArray.length) * 100);
    const resultString = `${correctAnswer}/${booleanArray.length}`;
    const star = percent / 20;

    const params = {
      score: percent,
      unit: props.unit,
      results: resultString,
      exerciseId: props.id,
      sreatedDate: moment().format(),
      studentAnswer: JSON.stringify(studentAnswer),
    };
    props.postAnswerToApi(params);

    onModal();

    setState((prevState) => ({
      ...prevState,
      isDoing: false,
      isDisabledRetry: false,
      isDisabledSubmit: true,
      booleanArray,
      star,
    }));
  }, [props, state.data, state.inputs]);

  const onRetry = React.useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      inputs: [],
      isDoing: true,
      booleanArray: [],
      isDisabledRetry: true,
      isDisabledSubmit: true,
    }));
  }, []);

  const onModal = React.useCallback(() => {
    setModal(true);
    setTimeout(() => {
      offModal();
    }, 3000);
  }, []);

  const offModal = React.useCallback(() => {
    setModal(false);
  }, []);

  if (!state.data) return null;
  return (
    <Container className="fluid test">
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TitleQuestion titleQuestion={state.data?.titleQuestion} />
      </Row>

      {state.videoUrl && (
        <Player>
          <BigPlayButton position="center" />
          <source src={props.video} type="video/mp4" default />

          <track
            kind="captions"
            src={props.videoSub}
            srcLang="en"
            label="English"
          />
          <ControlBar autoHide={false}>
            <ReplayControl seconds={10} order={1.1} />
            <ForwardControl seconds={10} order={1.2} />
            <CurrentTimeDisplay order={4.1} />
            <ClosedCaptionButton order={7} />
          </ControlBar>
        </Player>
      )}
      {state.videoUrl && (
        <VideoModal
          ref={videoRef}
          videoUrl={state.videoUrl}
          crossorigin="anonymous"
        />
      )}
      {state.audioImgUrl && (
        <AudioPlayer
          id="audio"
          timeStamp={state.timeStamp}
          isPause={state.isPause}
          audioUrl={state.audioImgUrl}
          // isVisibled={props.isVisibled}
        />
      )}

      <Row style={{ minWidth: 985 }} className="color__list">
        <div className="color__items">{renderContent}</div>
      </Row>

      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        {renderContentQuestionImage}
        {renderContentImage}
      </Row>

      <Modal centered isOpen={modal} style={{ maxWidth: 640 }}>
        <ModalBody>
          {/* {renderContent} */}
          {state.star >= 1 && <Firework />}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 240,
            }}
          >
            <Animate resultString={state.star} />
          </div>
        </ModalBody>
      </Modal>
      <FooterModal
        onRetry={onRetry}
        onSubmit={onSubmit}
        audioUrl={state.audioUrl}
        result={state?.booleanArray}
        exerciseKey={props.exerciseKey}
        isDisabledSubmit={state.isDisabledSubmit}
        isDisabledRetry={state.isDisabledRetry}
        hideBtnFooter={props.hideBtnFooter ?? false} //not answer
      />
    </Container>
  );
};

export default PaintColorType;
