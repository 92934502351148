import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    // Exercise num
    unit: "Unit 7",
    id: "WB3V2-U7-P55-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page55/E1/Key/answerKey.png",
    inputSize: 140,
    textAlign: "center",
    maxLength: 10,
    // titleImage: "img/FriendsPlus/Page53/E4/1.jpg",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page55/E1/2.jpg' />",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='display:flex ;margin: 0px 50px'>
          <img src='img/FriendsPlus/Page55/E1/1.jpg' />
          <div style='; margin-left: 30px'>
            <div style='margin-bottom: 40px'>
              <b>1.</b>
                Mom
                <u style="color: gray;">’ wearing</u> a blue
                <u style="color: gray;">&emsp;dress&emsp;</u> <br> and a yellow
                <u style="color: gray;">&emsp;bag&emsp;</u>.
              </div>
            <div style='margin-bottom: 40px'><b>2.</b> Vy # a pink # <br>and a brown #.</div>
            <div><b>3.</b> Sang # a red # <br>and blue #.</div>
            
          </div>

        </div>
        `,
        answer: ["'s wearing", "dress", "bag", "'s wearing", "shirt", "jeans"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 7",
    id: "WB3V2-U7-P55-E2",
    audio: "",
    video: "",
    component: T6,
    hideBtnFooter: true,
    inputSize: 120,
    maxLength: 7,
    // titleImage: "img/FriendsPlus/Page55/E2/1.jpg",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page55/E2/1.jpg' />",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
            <div style=' line-height: 40px;border-radius: 10px;padding: 3px 70px; border: 1px solid orange;'>
                <div>
                  skirt:#&emsp;shirt:#&emsp;T-shirt:#&emsp;boots:#
                </div>
                <div>
                  jeans:#&emsp;scarf:#&emsp;dress:#&emsp;hat:#
                </div>
            </div>
        `,
        answer: [],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 7",
    id: "WB3V2-U7-P55-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "",
    inputSize: 280,
    maxLength: 19,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page55/E3/1.jpg' />",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
            <div style='display: flex'>
                <div>
                  <img src='img/FriendsPlus/Page55/E3/2.jpg' />
                </div>
                <div style='margin-left: 30px'>
                    <div style='margin-bottom: 30px'><u style="color: gray;">I’m wearing a blue shirt.</u></div>
                    <div style='margin-bottom: 30px'>I’m wearing#.</div>
                    <div>I’m #.</div>
                </div>
            </div>
        `,
        answer: ["", ""],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 7",
    id: "WB3V2-U7-P55-E4",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page60/E1/Key/answerKey.png",
    video: "",
    recorder: true,
    component: UI,
    // component: DesignUnderLine1,
    titleImage: "",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page55/E4/1.jpg" }],
    ],
  },
};

export default json;
