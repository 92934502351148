import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    // Exercise num
    unit: "Unit 7",
    id: "WB3V2-U7-P50-E1",
    isHiddenCheck:true,
    audio: "",
    video: "",
    component: T6,
    inputSize: 35,
    maxLength: 1,
    checkUppercase: true,
    // selectStyle: {
    //   color: "#00ADFE",
    //   width: 45,
    //   height: 45,
    //   textAlign: "center",
    // },
    // selectOptionValues: ["✓", "✗"],
    exerciseKey: "img/FriendsPlus/Page50/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page50/E1/1.jpg' /> ",
        color: "",
      },
    ],

    questions: [
      {
        title: `
					<div style=" position: relative; ">
						<div> <img src='img/FriendsPlus/Page50/E1/3.jpg' /> </div>
            <div style=" position: absolute; top: 160px; left: 174px; "><input id='0'></input></div>
						<div style=" position: absolute; top: 149px; left: 423px; "><input id='1'></input></div>
						<div style=" position: absolute; top: 293px; left: 86px; "><input id='2'></input></div>
						<div style=" position: absolute; top: 340px; left: 178px; "><input id='3'></input></div>
					</div>
				`,
        answer: ["5", "2", "3", "4"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 7",
    id: "WB3V2-U7-P50-E2",
    audio: "",
    video: "",
    component: T6,
    checkUppercase: true,
    selectStyle: {
      color: "#00ADFE",
      width: 45,
      height: 45,
      textAlign: "center",
    },
    selectOptionValues: ["✓", "✗"],
    exerciseKey: "img/FriendsPlus/Page50/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: "Look and read. Put a check (✓) or a cross (✗) in the box.",
        color: "#23408f",
      },
    ],

    questions: [
      {
        title: `
					<div style=" position: relative; ">
						<div> <img src='img/FriendsPlus/Page50/E2/1.jpg' /> </div>
						<div style=" position: absolute; top: 383px; left: 255px; "><select id='0'></select></div>
						<div style=" position: absolute; top: 598px; left: 255px; "><select id='1'></select></div>
						<div style=" position: absolute; top: 160px; left: 750px; "><select id='2'></select></div>
						<div style=" position: absolute; top: 383px; left: 750px; "><select id='3'></select></div>
						<div style=" position: absolute; top: 598px; left: 750px; "><select id='4'></select></div>
					</div>
				`,
        answer: ["✓", "✗", "✓", "✓", "✗"],
      },
    ],
  },
};

export default json;
