import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    // Exercise num
    unit: "Unit 6",
    id: "WB3V2-U6-P46-E1",
    audio: "",
    video: "",
    component: T6,
    textAlign: "center",
    maxLength: 5,
    exerciseKey: "img/FriendsPlus/Page46/E1/Key/answerKey.png",
    inputSize: 100,
    // titleImage: "img/FriendsPlus/Page32/E3/1.jpg",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page46/E1/1.jpg' />",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
					<div style='border:1px solid green;border-radius: 5px;margin-left:25px;padding:2px 50px; text-align:center;width: 13cm'> 
						eyes&emsp;&emsp;<span style='color:gray; text-decoration:line-through'>hair</span>&emsp;&emsp;black&emsp;&emsp;curly
					</div>
          <div style='display:flex ; margin-top: 30px'>
							<img src='img/FriendsPlus/Page46/E1/2.jpg' />
							<div style='margin:20px 10px'> 
								I have three friends.<br> Siomon has brown (1)<u style="color: gray;">&emsp;hair&emsp;</u>.
							<br>	It is short and curly. Amy is his cousin.<br> She has long hair. It is red and (2)
								#. <br>She has blue eyes. Ed has (3)#hair.<br> He has green(4)#.

						  </div>
					</div>
        `,
        answer: ["curly", "black", "eyes"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 6",
    id: "WB3V2-U6-P46-E2",
    audio: "",
    video: "",
    component: T6,
    maxLength: 3,
    checkUppercase: true,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page46/E2/Key/answerKey.png",
    inputSize: 130,
    // titleImage: "img/FriendsPlus/Page32/E3/1.jpg",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page46/E2/1.jpg' />",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='display:flex'>  
            <img src='img/FriendsPlus/Page46/E1/2.jpg' />
            <div style='margin:40px 20px'>  
              <div style=' margin-bottom: 30px'><b>1.</b> <u style="color: gray;">&emsp;Simon&emsp;</u> has brown and curly.</div>
              <div style=' margin-bottom: 30px'><b>2.</b> # has long hair.</div>
              <div style=' margin-bottom: 30px'><b>3.</b> # has green eyes.</div>
              <div style=''><b>4.</b> # has blue eyes.</div>
            </div>
        </div>
        `,
        answer: ["Amy", "Ed", "Amy"],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 6",
    id: "WB3V2-U6-P46-E3",
    audio: "",
    video: "",
    component: T6,
    maxLength: 5,
    checkUppercase: true,
    textAlign: "center",
    hideBtnFooter: true,
    exerciseKey: "img/FriendsPlus/Page46/E3/Key/answerKey.png",
    inputSize: 130,
    // titleImage: "img/FriendsPlus/Page32/E3/1.jpg",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page46/E3/4.jpg' />",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
         <div style='display:flex'>
           <div style='margin-right: 120px'>
            <img src='img/FriendsPlus/Page46/E3/1.jpg' />
            <div style='margin:20px 0px 0px  70px'>#</div>
           </div>
           <div style='margin-right: 120px'>
            <img src='img/FriendsPlus/Page46/E3/2.jpg' />
            <div style='margin:20px 0px 0px 70px'>#</div>
           </div>
           <div style=''>
            <img src='img/FriendsPlus/Page46/E3/3.jpg' />
            <div style='margin:20px 0px 0px 70px'>#</div>
           </div>
         </div>
        `,
        answer: ["Simon", "Ed", "Amy"],
      },
    ],
  },
};

export default json;
