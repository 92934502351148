import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  9: {
    // Exercise num
    unit: "Unit Grammar Time",
    id: "WB3V2- Grammar Time-P92-E9",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page92/E9/Key/answerKey.png",
    inputSize: 90,
    maxLength: 5,
    checkUppercase: true,
    textAlign: "center",
    selectStyle: {
      color: "#00ADFE",
      width: 36,
      height: 36,
      borderRadius: 5,
      border: "2px solid gray",
    },
    selectOptionValues: ["✓", "✗"],

    // titleImage: "img/FriendsPlus/Page88/E4/1.jpg",
    titleQuestion: [
      {
        num: "",
        title:
          "<span style='; font-size: 30px;background: rgb(1 174 240);color: white; padding: 5px 10px; border-radius: 10px'>Unit 9</span>",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `

            <div style='display:flex'>
                <div style='margin: ; border-radius: 5px;line-height:40px;background:rgb(199 234 254); padding: 5px 50px 0px 10px'>
                  <b>I can</b> run.<br>
                  <b>He can</b> run.<br>
                  <b>She can</b> run.<br>
                  <b>It can</b> run.<br>
                </div>
                <div style='margin-left:5px ; border-radius: 5px;line-height:40px;background:rgb(199 234 254); padding: 5px 50px 0px 10px'>
                  <b>I can’t</b> catch.<br>
                  <b>He can’t</b> catch.<br>
                  <b>She can’t</b> catch.<br>
                  <b>It can’t</b> catch.<br>
                </div>
            </div>
            <div style='margin: -174px 0px 0px 467px' >
              <span style='color: black; font-size: 30px; margin-top:20px'><b> Write.</b></span>
              <div style='margin:; line-height: 60px ;'>
                 <b>1. ✓</b> I#run.<br>
                 <b>2. ✗</b>  He#fly.<br>
                 <b>3. ✗</b>  She#ride a bike.<br>
              </div>
            </div>
    `,
        answer: ["can", "can't", "can't"],
      },
    ],
  },
  10: {
    // Exercise num
    unit: "Unit Grammar Time",
    id: "WB3V2- Grammar Time-P92-E10",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page92/E10/Key/answerKey.png",
    inputSize: 105,
    maxLength: 5,
    checkUppercase: true,
    textAlign: "center",

    // titleImage: "img/FriendsPlus/Page88/E4/1.jpg",
    titleQuestion: [
      {
        num: "",
        title:
          "<span style='; font-size: 30px;background: rgb(1 174 240);color: white; padding: 5px 10px; border-radius: 10px'>Unit 10</span>",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `

          
          <div style='margin: -52px 0px 40px 120px; border-radius: 5px;line-height:40px;background:rgb(199 234 254); padding: 5px 50px 5px 10px'>
            <b>May I</b> visit the gift shop? <br>
            <b>Yes, you may. / No, you may not.</b><br>
          </div>
        <span style='color: black; font-size: 30px; margin-top:20px'><b> Write.</b></span>
         <div style='margin:; line-height: 60px'>
            <b>1.</b> #take a photo? Yes, you may.<br>
            <b>2.</b> #draw a picture? Yes, you may.<br>
            <b>3.</b> #have a pencil?<br>
         </div>
         
    `,
        answer: ["May i", "May i", "May i"],
      },
    ],
  },
  11: {
    // Exercise num
    unit: "Unit Grammar Time",
    id: "WB3V2- Grammar Time-P92-E11",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page92/E11/Key/answerKey.png",
    inputSize: 300,
    maxLength: 23,
    checkUppercase: true,
    textAlign: "center",

    // titleImage: "img/FriendsPlus/Page88/E4/1.jpg",
    titleQuestion: [
      {
        num: "",
        title:
          "<span style='; font-size: 30px;background: rgb(1 174 240);color: white; padding: 5px 10px; border-radius: 10px'>Unit 11</span>",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `

          
          <div style='margin: -52px 0px 40px 120px; border-radius: 5px;line-height:40px;background:rgb(199 234 254); padding: 5px 50px 5px 10px'>
            <b>There’s</b> a doll.<br>
            <b>There’s</b> three books.<br>
          </div>
        <span style='color: black; font-size: 30px; margin-top:20px'><b> Order the words.</b></span>
         <div style='margin:; line-height: 60px'>
            <b>1.</b> are some There pillows.
            <span style='margin-left:10px'>#</span><br>
            <b>2.</b> There’s a bag.
            <span style='margin-left:110px'>#</span><br>
            <b>3.</b> There toys are a lot.
            <span style='margin-left:49px'>#</span><br>
            <b>4.</b> There are four dolls.
            <span style='margin-left:49px'>#</span><br>
         </div>
         
    `,
        answer: [
          "There are some pillows.",
          "There's a bag.",
          "There toys are a lot.",
          "There are four dolls.",
        ],
      },
    ],
  },
  12: {
    // Exercise num
    unit: "Unit Grammar Time",
    id: "WB3V2- Grammar Time-P92-E12",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page92/E12/Key/answerKey.png",
    inputSize: 120,
    maxLength: 7,
    checkUppercase: true,
    textAlign: "center",
    selectStyle: {
      color: "#00ADFE",
      width: 36,
      height: 36,
      borderRadius: 5,
      border: "2px solid gray",
    },
    selectOptionValues: ["✓", "✗"],

    // titleImage: "img/FriendsPlus/Page88/E4/1.jpg",
    titleQuestion: [
      {
        num: "",
        title:
          "<span style='; font-size: 30px;background: rgb(1 174 240);color: white; padding: 5px 10px; border-radius: 10px'>Unit 12</span>",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `

        <div style='display:flex'>
            <div style='margin: -52px 0px 40px 120px; border-radius: 5px;line-height:40px;background:rgb(199 234 254); padding: 5px 50px 0px 10px'>
              <b>These are</b>  table.<br>
            </div>
            <div style='margin: -52px 0px 40px 5px; border-radius: 5px;line-height:40px;background:rgb(199 234 254); padding: 5px 50px 0px 10px'>
              <b>Those are</b>  table.<br>
            </div>
        </div>
        <span style='color: black; font-size: 30px; margin-top:20px'><b> Read and check ✓ or cross ✗.</b></span>
          <div style='display:flex'>
            <div style='margin:; line-height: 60px ;'>
               <b>1.</b>  These are my bag.<br>
               <b>2.</b>  Those are pencil cases.<br>
            </div>
            
         </div>
          <div style=" position: absolute; top:178px; left: 865px; "><select  id='0'></select></div>
					<div style=" position: absolute; top: 236px; left: 920px; "><select id='1'></select></div>
    `,
        answer: ["✗", "✓"],
      },
    ],
  },
};

export default json;
