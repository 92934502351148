import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 7',
		id: 'WB3V2-U7-P52-E1',
		audio: '',
		exerciseKey: 'img/FriendsPlus/Page52/E1/Key/answerKey.png',
		video: '',
		component: DesignUnderLine,
		// component: DesignUnderLine1,
		totalInput: 2,
		titleImage: '',
		questionImage: [
			// Row
			[{ url: 'img/FriendsPlus/Page52/E1/16.jpg' }],
			[{ url: 'img/FriendsPlus/Page52/E1/1.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page52/E1/2.jpg' },
				{ url: 'img/FriendsPlus/Page52/E1/3.jpg', input: 1, isCorrect: true },
				{ url: 'img/FriendsPlus/Page52/E1/4.jpg' },
				{ url: 'img/FriendsPlus/Page52/E1/5.jpg', input: 2 },
			],
			[{ url: 'img/FriendsPlus/Page52/E1/6.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page52/E1/7.jpg' },
				{ url: 'img/FriendsPlus/Page52/E1/8.jpg', input: 1 },
				{ url: 'img/FriendsPlus/Page52/E1/9.jpg' },
				{ url: 'img/FriendsPlus/Page52/E1/10.jpg', input: 2, isCorrect: true },
			],
			[{ url: 'img/FriendsPlus/Page52/E1/11.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page52/E1/12.jpg' },
				{ url: 'img/FriendsPlus/Page52/E1/13.jpg', input: 1 },
				{ url: 'img/FriendsPlus/Page52/E1/14.jpg' },
				{ url: 'img/FriendsPlus/Page52/E1/15.jpg', input: 2 },
			],
		],
		questions: [
			{
				title: 'The dictionaries are # the shelf',
				answer: ['table'],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 7',
		id: 'WB3V2-U7-P52-E2',
		audio: '',
		exerciseKey: 'img/FriendsPlus/Page52/E2/Key/answerKey.png',
		video: '',
		component: D1,
		fontSize: 30,
		maxLength: 5,
		// component: DesignUnderLinE2,
		titleImage: 'img/FriendsPlus/Page52/E2/8.jpg',
		questionImage: [
			// Row
			[{ url: 'img/FriendsPlus/Page52/E2/8.jpg' }],
			[{ url: 'img/FriendsPlus/Page52/E2/1.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page52/E2/2.jpg' },
				{ url: 'img/FriendsPlus/Page52/E2/3.jpg', input: true, answer: 'hat' },
				{ url: 'img/FriendsPlus/Page52/E2/4.jpg' },
			],
			[{ url: 'img/FriendsPlus/Page52/E2/5.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page52/E2/6.jpg' },
				{
					url: 'img/FriendsPlus/Page52/E2/7.jpg',
					input: true,
					answer: 'dress',
				},
			],
		],
		questions: [
			{
				title: 'The dictionaries are # the shelf',
				answer: ['table'],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 7',
		id: 'WB3V2-U7-P52-E3',
		audio: '',
		video: '',
		component: T6,
		isHiddenCheck: true,
		textAlign: 'center',
		inputSize: 100,
		maxLength: 7,
		checkUppercase: true,
		exerciseKey: 'img/FriendsPlus/Page52/E3/Key/answerKey.png',
		titleQuestion: [
			{
				num: '',
				title: "<img src='img/FriendsPlus/Page52/E3/4.jpg' /> ",
				color: '',
			},
		],

		questions: [
			{
				title: `
					<div style='margin:20px; display:flex'>
              <div>
                <img src='img/FriendsPlus/Page52/E3/1.jpg' /> 
                <div style='margin:0px 30px'>
                  She’s wearing a pink <br> <u style="color: gray;">&emsp;dress&emsp;</u>.
                   He’s <br> wearing a #.
                </div>
              </div>
              <div style='margin-left: 40px'>
                <img src='img/FriendsPlus/Page52/E3/2.jpg' /> 
                <div style='margin:0px 30px'>
                  He’s wearing a blue <br> # and <br>a blue
                   #.
                </div>
              </div>
              <div style='margin-left: 40px'>
                <img src='img/FriendsPlus/Page52/E3/3.jpg' /> 
                <div style='margin:0px 30px'>
                  She’s wearing a yellow <br> #
                   and <br> a red #.
                </div>
              </div>
          </div>
				`,
				answer: ['T-shirt', 'hat', 'T-shirt', 'hat', 'T-shirt'],
			},
		],
	},
}

export default json
