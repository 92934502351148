import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
const json = {
  1: {
    // Exercise num
    unit: "Unit 3",
    id: "WB3V2-U3-P22-E1",
    audio: "",
    video: "",
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: "img/FriendsPlus/Page22/E1/Key/answerKey.png",
    questionImage: [
      // [{ url: "img/FriendsPlus/Page21/E1/26.jpg" }],
      [{ url: "img/FriendsPlus/Page22/E1/28.jpg" }],
      [{ url: "img/FriendsPlus/Page22/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page22/E1/2.jpg" },
        { url: "img/FriendsPlus/Page22/E1/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page22/E1/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page22/E1/5.jpg" },
        { url: "img/FriendsPlus/Page22/E1/6.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page22/E1/7.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page22/E1/8.jpg" },
        { url: "img/FriendsPlus/Page22/E1/9.jpg", input: 1 },
        { url: "img/FriendsPlus/Page22/E1/10.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page22/E1/11.jpg" }],
      [
        { url: "img/FriendsPlus/Page22/E1/12.jpg" },
        { url: "img/FriendsPlus/Page22/E1/13.jpg", input: 2 },
        { url: "img/FriendsPlus/Page22/E1/14.jpg" },
        { url: "img/FriendsPlus/Page22/E1/15.jpg", input: 3 },
        { url: "img/FriendsPlus/Page22/E1/16.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page22/E1/17.jpg" },
        { url: "img/FriendsPlus/Page22/E1/18.jpg", input: 2 },
        { url: "img/FriendsPlus/Page22/E1/19.jpg" },
        { url: "img/FriendsPlus/Page22/E1/20.jpg", input: 3 },
        { url: "img/FriendsPlus/Page22/E1/21.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page22/E1/22.jpg" },
        { url: "img/FriendsPlus/Page22/E1/23.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page22/E1/24.jpg" },
        { url: "img/FriendsPlus/Page22/E1/25.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page22/E1/26.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page22/E1/27.jpg" }],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 3",
    id: "WB3V2-U3-P22-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page22/E2/Key/answerKey.png",
    component: D1,
    padding: 0,
    textAlign: "center",
    maxLength: 12,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page22/E2/14.jpg" }],
      [{ url: "img/FriendsPlus/Page22/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page22/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page22/E2/3.jpg",
          input: true,
          answer: "my mom",
        },
        { url: "img/FriendsPlus/Page22/E2/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page22/E2/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page22/E2/6.jpg" },
        {
          url: "img/FriendsPlus/Page22/E2/7.jpg",
          input: true,
          answer: "is my dad",
        },
        { url: "img/FriendsPlus/Page22/E2/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page22/E2/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page22/E2/10.jpg" },
        {
          url: "img/FriendsPlus/Page22/E2/11.jpg",
          input: true,
          answer: "is my sister",
        },
        { url: "img/FriendsPlus/Page22/E2/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page22/E2/13.jpg" }],
    ],
  },
};

export default json;
