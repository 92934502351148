import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    // Exercise num
    unit: "Unit 8",
    id: "WB3V2-U8-P60-E1",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page60/E1/Key/answerKey.png",
    video: "",
    recorder: true,
    component: UI,
    // component: DesignUnderLine1,
    titleImage: "",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page60/E1/1.jpg" }],
      [{ url: "img/FriendsPlus/Page60/E1/2.jpg" }],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 9",
    id: "WB3V2-U9-P60-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page60/E2/Key/answerKey.png",
    inputSize: 80,
    textAlign: "center",
    titleImage: "img/FriendsPlus/Page60/E2/1.jpg",
    // titleQuestion: [{ num: '2', title: 'Write.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <img style='height:10cm;width:15cm' src='img/FriendsPlus/Page60/E1/2.jpg' />
        <div style='line-height: 50px;'>
          <div><b>1.</b> This is an email from Kim. <u style="color: gray;">&emsp;yes&emsp;</u></div>
          <div><b>2.</b> Her favorite food is banh chung. #</div>
          <div><b>3.</b> Her family has a party at Tet. #</div>
          <div><b>4.</b> Here, she’s watching a video. #</div>
          <div><b>5.</b> Here, her brother is playing music. #</div>
        </div> 
        
        `,
        answer: ["yes", "yes", "no", "no"],
      },
    ],
  },
};

export default json;
