import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    // Exercise num
    unit: "Unit 7",
    id: "WB3V2-U7-P51-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page51/E1/Key/answerKey.png",
    inputSize: 264,
    maxLength: 22,
    titleImage: "img/FriendsPlus/Page51/E1/6.jpg",

    questionImage: [],
    questions: [
      {
        title: `
          <div style='border:1px solid red;text-align: center;margin:5px 50px; width: 16cm;border-radius: 10px '>
            a skirt&emsp;&emsp;a scarf&emsp;&emsp;
            <span style='text-decoration: line-through '>jeans</span>&emsp;&emsp;boots&emsp;&emsp;a shirt
          </div>
          <div style=' display:flex ; margin: 20px 20px'>
              <div>
                <img src='img/FriendsPlus/Page51/E1/1.jpg' /> 
                <div style='margin: 10px 0px'><u style="color: gray;"> He’s wearing jeans.&emsp;</u></div>
              </div>
              <div style='margin-left:200px'>
                <img src='img/FriendsPlus/Page51/E1/2.jpg' /> 
                <div style='margin: 10px 0px'>#</div>
              </div>
          </div>
          <div style=' display:flex ; margin: 40px 20px'>
              <div>
                <img src='img/FriendsPlus/Page51/E1/3.jpg' /> 
                <div style='margin: 10px 0px'>#</div>
              </div>
              <div style='margin-left:194px'>
                <img src='img/FriendsPlus/Page51/E1/4.jpg' /> 
                <div style='margin: 10px 0px'>#</div>
              </div>
          </div>
          <div style='margin:40px 20px'>
            <img src='img/FriendsPlus/Page51/E1/5.jpg' /> 
            <div style='margin: 10px 0px'>#</div>
          </div>
        `,
        answer: [
          "He's wearing a scarf.",
          "She's wearing boots.",
          "She's wearing a skirt.",
          "He's wearing a shirt.",
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 7",
    id: "WB3V2-U7-P51-E2",
    audio: "Audios/Track 11/audio.mp3",
    video: "",
    component: T6,
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page51/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and write. <headphone name='11' src='Audios/Track 11/tieude.mp3' ></headphone>",
        color: "#233f94",
      },
    ],
    textAlign: "center",
    inputSize: 200,

    questions: [
      {
        title: `
          <div style='border:1px solid red;text-align: center;margin:15px 50px; width: 23cm;border-radius: 10px '>
            skirt&emsp;&emsp;shirt&emsp;&emsp;
            <span style='text-decoration: line-through '>jeans</span>&emsp;&emsp;boots&emsp;&emsp;I’m&emsp;&emsp;T-shirt
            &emsp;&emsp;wearing&emsp;&emsp;He’s
          </div>
          <div style=" position: relative; ">
            <img src='img/FriendsPlus/Page51/E2/1.jpg' /> 
						<div style=" position: absolute; top: 60px; left: 757px; "><input id='1'></input></div>
						<div style=" position: absolute; top: 260px; left: 293px; "><input id='2'></input></div>
						<div style=" position: absolute; top: 355px; left: 293px; "><input id='3'></input></div>
						<div style=" position: absolute; top: 250px; left: 809px; "><input id='2'></input></div>
						<div style=" position: absolute; top: 345px; left: 809px; "><input id='3'></input></div>
					</div>

          
        `,
        answer: ["shirt", "I'm", "skirt.", "He's", "T-shirt."],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 7",
    id: "WB3V2-U7-P51-E3",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page51/E3/Key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: "Read the text in the Student Book. Circle the correct word.",
        color: "#5B5A5D",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          border: "solid 2px",
          borderRadius: "50%",
          borderColor: "transparent",
        },
        selectWordStyle: { padding: 3, borderColor: "#4285F4" },
        limitSelect: 1,
        listWords: [
          "rice_field / water_pool.", //0
          "screen / band", //1
          "costume / hair", //2
        ],
        answers: ["0-4", "1-0", "2-0"],
        initialValue: [],
      },
      Layout: `
      <div style="line-height: 58px;">
        <div><b>1</b> The water puppets are made from <b style="border: 2px solid rgb(66, 133, 244); border-radius: 50%; padding: 3px;">wood</b> <b>/ rice.</b></div>
        <div><b>2</b> Today, the stage is a rice <b><input id='0' type='Circle' /></b></div>
        <div><b>3</b> People hide behind a <b><input id='1' type='Circle' /></b> to act.</div>
        <div><b>4</b> Be careful not to get your puppets’ <b><input id='2' type='Circle' /></b> wet.</div>
      </div>
      `,
    },
  },
};

export default json;
