import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 8",
    id: "WB3V2-U8-P58-E1",
    isHiddenCheck: true,
    audio: "",
    video: "",
    component: T6,
    inputSize: 35,
    fontSize: 30,
    maxLength: 1,
    textInputStyle: {
      borderBottom: "none",
    },
    checkUppercase: true,
    // selectStyle: {
    //   color: "#00ADFE",
    //   width: 45,
    //   height: 45,
    //   textAlign: "center",
    // },
    // selectOptionValues: ["✓", "✗"],
    exerciseKey: "img/FriendsPlus/Page58/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page58/E1/2.jpg' /> ",
        color: "",
      },
    ],

    questions: [
      {
        title: `
					<div style=" position: relative; ">
						<div> <img src='img/FriendsPlus/Page58/E1/1.jpg' /> </div>
            <div style=" position: absolute; top: 87px; left: 272px; "><input id='0'></input></div>
						<div style=" position: absolute; top: 165px; left: 184px; "><input id='1'></input></div>
					</div>
				`,
        answer: ["a", "b"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 8",
    id: "WB3V2-U8-P58-E2",
    audio: "Audios/Track 13/audio.mp3",
    video: "",
    component: D1,
    isHiddenCheck: true,
    maxlength: 1,
    exerciseKey: "img/FriendsPlus/Page58/E2/Key/answerKey.png",

    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page58/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page58/E2/b.jpg",
          audioUrl: "Audios/Track 13/tieude.mp3",
        },
        { url: "img/FriendsPlus/Page58/E2/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page58/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page58/E2/2.jpg" },
        { url: "img/FriendsPlus/Page58/E2/3.jpg", input: true, answer: "b" },
        { url: "img/FriendsPlus/Page58/E2/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page58/E2/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page58/E2/6.jpg" },
        { url: "img/FriendsPlus/Page58/E2/7.jpg", input: true, answer: "a" },
        { url: "img/FriendsPlus/Page58/E2/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page58/E2/9.jpg" }],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 8",
    id: "WB3V2-U8-P58-E3",
    audio: "",
    video: "",
    component: DesignUnderLine,
    fontSize: 20,
    totalInput: 2,
    exerciseKey: "img/FriendsPlus/Page58/E3/Key/answerKey.png",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page58/E3/25.jpg" }],
      [{ url: "img/FriendsPlus/Page58/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page58/E3/2.jpg" },
        { url: "img/FriendsPlus/Page58/E3/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page58/E3/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page58/E3/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page58/E3/6.jpg" },
        { url: "img/FriendsPlus/Page58/E3/7.jpg", input: 1 },
        { url: "img/FriendsPlus/Page58/E3/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page58/E3/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page58/E3/10.jpg" },
        { url: "img/FriendsPlus/Page58/E3/11.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page58/E3/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page58/E3/13.jpg" }],
      [
        { url: "img/FriendsPlus/Page58/E3/14.jpg" },
        { url: "img/FriendsPlus/Page58/E3/15.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page58/E3/16.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page58/E3/17.jpg" }],
      [
        { url: "img/FriendsPlus/Page58/E3/18.jpg" },
        { url: "img/FriendsPlus/Page58/E3/19.jpg", input: 2 },
        { url: "img/FriendsPlus/Page58/E3/20.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page58/E3/21.jpg" }],
      [
        { url: "img/FriendsPlus/Page58/E3/22.jpg" },
        { url: "img/FriendsPlus/Page58/E3/23.jpg", input: 2 },
        { url: "img/FriendsPlus/Page58/E3/24.jpg" },
      ],
    ],
  },
};

export default json;
