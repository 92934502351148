import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "WB3V2-U1-P14-E1",
    audio: "",
    video: "",
    component: DesignUnderLine,
    totalInput: 2,
    exerciseKey: "img/FriendsPlus/Page14/E1/Key/answerKey.png",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page14/E1/26.jpg" }],
      [{ url: "img/FriendsPlus/Page14/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page14/E1/2.jpg" },
        { url: "img/FriendsPlus/Page14/E1/3.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page14/E1/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page14/E1/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page14/E1/6.jpg" },
        { url: "img/FriendsPlus/Page14/E1/7.jpg", input: 1 },
        { url: "img/FriendsPlus/Page14/E1/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page14/E1/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page14/E1/10.jpg" },
        { url: "img/FriendsPlus/Page14/E1/11.jpg", input: 1 },
        { url: "img/FriendsPlus/Page14/E1/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page14/E1/13.jpg" }],
      [
        { url: "img/FriendsPlus/Page14/E1/14.jpg" },
        { url: "img/FriendsPlus/Page14/E1/15.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page14/E1/16.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page14/E1/17.jpg" }],
      [
        { url: "img/FriendsPlus/Page14/E1/18.jpg" },
        { url: "img/FriendsPlus/Page14/E1/19.jpg", input: 2 },
        { url: "img/FriendsPlus/Page14/E1/20.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page14/E1/21.jpg" }],
      [
        { url: "img/FriendsPlus/Page14/E1/22.jpg" },
        { url: "img/FriendsPlus/Page14/E1/23.jpg", input: 2 },
        { url: "img/FriendsPlus/Page14/E1/24.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page14/E1/25.jpg" }],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "WB3V2-U1-P14-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page14/E2/Key/answerKey.png",
    component: D1,
    inputHeight: 33,
    fontSize: 30,
    maxLength: 6,

    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page14/E2/24.jpg" }],
      [{ url: "img/FriendsPlus/Page14/E2/25.jpg" }],
      [{ url: "img/FriendsPlus/Page14/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page14/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page14/E2/3.jpg",
          input: true,
          answer: "green",
        },
        { url: "img/FriendsPlus/Page14/E2/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page14/E2/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page14/E2/6.jpg" },
        { url: "img/FriendsPlus/Page14/E2/7.jpg", input: true, answer: "ball" },
        { url: "img/FriendsPlus/Page14/E2/8.jpg" },
        { url: "img/FriendsPlus/Page14/E2/9.jpg", input: true, answer: "red" },
        { url: "img/FriendsPlus/Page14/E2/10.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page14/E2/11.jpg" }],
      [
        { url: "img/FriendsPlus/Page14/E2/12.jpg" },
        {
          url: "img/FriendsPlus/Page14/E2/13.jpg",
          input: true,
          answer: "doll",
        },
        { url: "img/FriendsPlus/Page14/E2/14.jpg" },
        {
          url: "img/FriendsPlus/Page14/E2/15.jpg",
          input: true,
          answer: "yellow",
        },
        { url: "img/FriendsPlus/Page14/E2/16.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page14/E2/17.jpg" }],
      [
        { url: "img/FriendsPlus/Page14/E2/18.jpg" },
        { url: "img/FriendsPlus/Page14/E2/19.jpg", input: true, answer: "car" },
        { url: "img/FriendsPlus/Page14/E2/20.jpg" },
        {
          url: "img/FriendsPlus/Page14/E2/21.jpg",
          input: true,
          answer: "blue",
        },
        { url: "img/FriendsPlus/Page14/E2/22.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page14/E2/23.jpg" }],
    ],
  },
};

export default json;
