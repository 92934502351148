import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    unit: "Unit 4",
    id: "WB3V2-U4-P34-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [
      [{ url: "img/FriendsPlus/Page34/E1/1.jpg" }],
      [{ url: "img/FriendsPlus/Page34/E1/2.jpg" }],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 4",
    id: "WB3V2-U4-P34-E2",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page34/E2/Key/answerKey.png",
    video: "",
    component: DesignUnderLine,
    // component: DesignUnderLine1,
    totalInput: 1,
    titleImage: "",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page34/E2/7.jpg" }],
      [{ url: "img/FriendsPlus/Page34/E1/2.jpg" }],
      [
        { url: "img/FriendsPlus/Page34/E2/1.jpg" },
        { url: "img/FriendsPlus/Page34/E2/2.jpg", input: 1 },
        { url: "img/FriendsPlus/Page34/E2/3.jpg" },
        { url: "img/FriendsPlus/Page34/E2/4.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page34/E2/5.jpg" },
        { url: "img/FriendsPlus/Page34/E2/6.jpg", input: 3 },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 4",
    id: "WB3V2-U4-P34-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page34/E3/Key/answerKey.png",
    inputSize: 70,
    maxLength: 3,
    // titleImage: "img/FriendsPlus/Page32/E3/1.jpg",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page34/E3/3.jpg' />",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='display:flex'>
          <img src='img/FriendsPlus/Page34/E3/2.jpg' />
          <div style='margin-left:20px'>
            <div style='margin-bottom:50px'><b>1.</b>The boys and girls are tall and small. <u style="color: gray;">&emsp;yes&emsp;</u> </div>
            <div style='margin-bottom:50px'><b>2.</b>The boys and girls like birds.#</div>
            <div style='margin-bottom:50px'><b>3.</b>The monkeys are big and small.#</div>
            <div style='margin-bottom:50px'><b>4.</b>The boys and girls are big.#</div>
            <div><b>5.</b>The birds are tall.#</div>
          </div>
        </div>
        `,
        answer: ["yes", "yes", "no", "no"],
      },
    ],
  },
};

export default json;
