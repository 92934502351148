import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import { CgEnter } from "react-icons/cg";
const json = {
  1: {
    // Exercise num
    unit: "Unit 5",
    id: "WB3V2-U5-P42-E1",
    audio: "",
    video: "",
    component: UI,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [
        // Column2
        { url: "img/FriendsPlus/Page42/E1/1.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page42/E1/3.jpg" },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 6",
    id: "WB3V2-U6-P42-E2",
    audio: "",
    video: "",
    component: T6,
    hideBtnFooter: true,
    checkUppercase: true,
    // exerciseKey: "img/FriendsPlus/Page42/E2/Key/answerKey.png",
    inputSize: 150,
    textAlign: "center",
    maxLength: 8,
    // titleImage: "img/FriendsPlus/Page41/E2/title.jpg",
    titleQuestion: [
      {
        num: "2",
        title: "Loot at your picture. Read and circle. Write.",
        color: "#22418e",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='margin-bottom:20px'>
           <b>1.</b> My eyes are <span style='border:1px solid orange;padding: 3px 10px; border-radius: 10px'>blue&emsp;&emsp;brown&emsp;&emsp;green&emsp;&emsp;black</span>.
           <div>I have#eyes.</div>
        </div>
        <div style='margin-bottom:20px'>
           <b>2.</b> My hair is <span style='border:1px solid orange;padding: 3px 10px; border-radius: 10px'>black&emsp;&emsp;brown&emsp;&emsp;red</span>.
           <div>I have#hair.</div>
        </div>
        <div style='margin-bottom:20px'>
           <b>3.</b> My hair is <span style='border:1px solid orange;padding: 3px 10px; border-radius: 10px'>straight&emsp;&emsp;curly</span>.
           <div>I have#hair.</div>
        </div>
        <div>
           <b>4.</b> My hair is <span style='border:1px solid orange;padding: 3px 10px; border-radius: 10px'>long&emsp;&emsp;short</span>.
           <div>I have#hair.</div>
        </div>
        `,
        answer: ["", "", "", ""],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 6",
    id: "WB3V2-U6-P42-E3",
    audio: "img/FriendsPlus/Page42/Audio/audio-e3-p42.mp3",
    video: "",
    component: D1,
    padding: 0,
    textAlign: "center",
    maxLength: 2,
    exerciseKey: "img/FriendsPlus/Page42/E3/Key/answerKey.png",
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page42/E3/1.jpg" },
        {
          url: "img/FriendsPlus/Page42/E3/2.jpg",
          audioUrl: "img/FriendsPlus/Page42/Audio/tieude-e3-p42.mp3",
        },
        { url: "img/FriendsPlus/Page42/E3/3.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page42/E3/4.jpg" },
      ],
      [
        // Column3
        { url: "img/FriendsPlus/Page42/E3/5.jpg" },
        { url: "img/FriendsPlus/Page42/E3/6.jpg", input: true, answer: "rr" },
        { url: "img/FriendsPlus/Page42/E3/7.jpg" },
        { url: "img/FriendsPlus/Page42/E3/8.jpg", input: true, answer: "rr" },
        { url: "img/FriendsPlus/Page42/E3/9.jpg" },
        { url: "img/FriendsPlus/Page42/E3/10.jpg", input: true, answer: "ll" },
        { url: "img/FriendsPlus/Page42/E3/11.jpg" },
        { url: "img/FriendsPlus/Page42/E3/12.jpg", input: true, answer: "rr" },
        { url: "img/FriendsPlus/Page42/E3/13.jpg" },
        { url: "img/FriendsPlus/Page42/E3/14.jpg", input: true, answer: "ll" },
        { url: "img/FriendsPlus/Page42/E3/15.jpg" },
      ],
      [
        // Column4
        { url: "img/FriendsPlus/Page42/E3/16.jpg" },
      ],
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 6",
    id: "WB3V2-U6-P42-E4",
    audio: "",
    video: "",
    component: T6,
    hideBtnFooter: true,
    exerciseKey: "img/FriendsPlus/Page42/E4/Key/answerKey.png",
    inputSize: 600,
    // titleImage: "img/FriendsPlus/Page41/E2/title.jpg",
    titleQuestion: [
      {
        num: "2",
        title: "Write a sentence using each word.",
        color: "#5B5A5D",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" line-height: 58px;">
          <div><b>1</b> (smell) <u style="color:gray;"> &ensp; I like to smell flowers. &ensp;</u> </div>
          <div><b>2</b> (mirror) # </div>
          <div><b>3</b> (carrot) # </div>
          <div><b>4</b> (shell) # </div>
          <div><b>5</b> (bell) # </div>
          <div><b>6</b> (cherry) # </div>
        </div>
        `,
        answer: ["", "", "", "", ""],
      },
    ],
  },
};

export default json;
