import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
const json = {
  1: {
    // Exercise num
    unit: "Unit 6",
    id: "WB3V2-U6-P47-E1",
    audio: "",
    video: "",
    component: T6,
    checkUppercase: true,
    maxLength: 23,
    exerciseKey: "img/FriendsPlus/Page47/E1/Key/answerKey.png",
    inputSize: 280,
    // titleImage: "img/FriendsPlus/Page32/E3/1.jpg",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page47/E1/1.jpg' />",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='margin-bottom: 20px'>
					<b>1.</b> I do not have short hair. &emsp;&emsp;<u style="color: gray;">&emsp;I don’t have short hair.&emsp;</u>
				</div>
        <div style='margin-bottom: 20px'>
					<b>2.</b> I do not have long hair. &emsp;&emsp;#.
				</div>
        <div style='margin-bottom: 20px'>
					<b>3.</b> I do not have blue eyes.&emsp;&emsp;#.
				</div>
        <div>
					<b>4.</b> I do not have curly hair. &emsp;&emsp;#.
				</div>
        `,
        answer: [
          "I don't have long hair",
          "I don't have blue eyes",
          "I don't have curly hair",
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 6",
    id: "WB3V2-U6-P47-E2",
    audio: "",
    video: "",
    component: Circle_Write,
    hideBtnFooter: true,
    exerciseKey: "",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page47/E2/29.jpg'/>   ",
        color: "",
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "7px ",
          paddingRight: "30px",
          fontWeight: 600,
          color: "",
          fontSize: 27,
          fontWeight: 400,
          borderRadius: "20px",
        },
        selectWordStyle: { border: "2px solid #2eb6e1" },
        limitSelect: 1,
        listWords: [
          "long short curly straight  ",
          "black brown red ",
          "blue brown black green",
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
        <div style="margin-top: 30px; display: flex; flex-direction: column; gap: 20px; padding: 20px 30px; border: 1px solid rgb(243, 90, 33); border-radius: 20px;">
          <div><span style="font-size: 27px; font-weight: 700;">hair:</span> <input id='0'  type='Circle' /><input id='1'  type='Circle' /></div>
          <div><span style="font-size: 27px; font-weight: 700;">eyes:</span> <input id='2'  type='Circle' /></div>
        </div>
        `,
    },
  },
  3: {
    // Exercise num
    unit: "Unit 6",
    id: "WB3V2-U6-P47-E3",
    audio: "",
    video: "",
    component: T6,
    checkUppercase: true,
    maxLength: 23,
    inputSize: 280,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page47/E3/1.jpg' />",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
       <div style='display:flex'>
        <img src='img/FriendsPlus/Page47/E3/2.jpg' />
        <div style='margin:30px 20px; line-height:50px'>
          <input id='0' width='130px' ></input>has<input id='1' width='130px' ></input>eyes.<br>
          <input id='2' width='130px' ></input>has<input id='3' width='130px' ></input>hair.<br>
            #<br>#<br>#<br>
        </div>
       </div>
        `,
        answer: [],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 6",
    id: "WB3V2-U6-P47-E4",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // component: DesignUnderLine1,
    totalInput: 3,
    // exerciseKey: "",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page47/E4/1.jpg" }],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
};

export default json;
