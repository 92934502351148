import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Unit 10",
    id: "WB3V2-U10-P73-E1",
    audio: "",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page73/E1/Key/answerKey.png",
    maxLength: 4,
    fontSize: 28,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page73/E1/11.jpg" }],
      [{ url: "img/FriendsPlus/Page73/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page73/E1/2.jpg" },
        { url: "img/FriendsPlus/Page73/E1/3.jpg", input: true, answer: "boy" },
        { url: "img/FriendsPlus/Page73/E1/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page73/E1/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page73/E1/6.jpg" },
        { url: "img/FriendsPlus/Page73/E1/7.jpg", input: true, answer: "goat" },
        { url: "img/FriendsPlus/Page73/E1/8.jpg" },
        { url: "img/FriendsPlus/Page73/E1/9.jpg", input: true, answer: "toy" },
        { url: "img/FriendsPlus/Page73/E1/10.jpg" },
      ],
    ],
  },
  2:{
    // Exercise num
    unit: "Unit 10",
    id: "WB3V2-U10-P73-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page73/E2/Key/answerKey.png",
    component: MatchDots,
    // titleImage: "<img src='img/FriendsPlus/Pag73/E2/1.jpg'>",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page33/E2/2.jpg'>",
        color: "",
      },
    ],

    question: {
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "80px",
              left: "100px",
              width: 140,
              height: 152,
              // border: "2px solid red",
            },
          },
          //0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "130px",
              left: "460px",
              width: 125,
              height: 135,
              // border: "2px solid red",
            },
          },
          //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "100px",
              left: "760px",
              width: 160,
              height: 96,
              // border: "2px solid red",
            },
          },
          //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "290px",
              left: "100px",
              width: 140,
              height: 138,
              // border: "2px solid red",
            },
          },
          //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "295px",
              left: "460px",
              width: 125,
              height: 140,
              // border: "2px solid red",
            },
          },
          //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "300px",
              left: "780px",
              width: 170,
              height: 140,
              // border: "2px solid red",
            },
          },
          //5

          //16
        ],
        answers: ["0-4"
        , "4-5"
        , "1-3"
        , "1-2"],
        initialValue: [],
      },
      Layout: `
          <img src='img/FriendsPlus/Page73/E2/2.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
        
          
      `,
    },
  },
  3: {
    // Exercise num
    unit: "Unit 10",
    id: "WB3V2-U10-P73-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page73/E3/Key/answerKey.png",
    inputSize: 100,
    textAlign: "center",
    maxLength: 4,
    titleImage: "img/FriendsPlus/Page73/E3/1.jpg",
    // titleQuestion: [{ num: '4', title: 'Write.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='display:flex'>
          <img src='img/FriendsPlus/Page73/E3/3.jpg' />
          <div style='margin: 30px 50px;line-height:45px'>
              Tom is a<u style="color: gray;">&emsp;boy&emsp;</u><br>
              He has a#.<br>
              This is a#.<br>
              It’s on a#.<br>
          </div>
          <img src='img/FriendsPlus/Page73/E3/2.jpg' />
        </div>
        `,
        answer: ["toy", "goat", "boat"],
      },
    ],
  },
};

export default json;
