import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 8",
    id: "WB3V2-U8-P56-E1",
    audio: "",
    video: "",
    component: T6,
    maxLength: 8,
    exerciseKey: "img/FriendsPlus/Page56/E1/Key/answerKey.png",
    inputSize: 225,
    textAlign: "center",
    // titleImage: "img/FriendsPlus/Page56/E4/1.jpg",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page56/E1/7.jpg' />",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='display:flex; margin: 20px'>
          <div style='margin-right: 30px'>
            <img src='img/FriendsPlus/Page56/E1/1.jpg' />
            <div style='margin-left:15px'><u style="color: gray;">&emsp;&emsp;&emsp;talking&emsp;&emsp;&emsp;</u></div>
          </div>
          <div style='margin-right: 30px'>
            <img src='img/FriendsPlus/Page56/E1/2.jpg' />
            <div style='margin-left:6px'>#</div>
          </div>
          <div>
            <img src='img/FriendsPlus/Page56/E1/3.jpg' />
            <div style='margin-left:6px'>#</div>
          </div>
        </div>
        <div style='display:flex; margin:30px 20px'>
          <div style='margin-right: 30px'>
            <img src='img/FriendsPlus/Page56/E1/4.jpg' />
            <div style='margin-left:6px'>#</div>
          </div>
          <div style='margin-right: 30px'>
            <img src='img/FriendsPlus/Page56/E1/5.jpg' />
            <div style='margin-left:6px'>#</div>
          </div>
          <div>
            <img src='img/FriendsPlus/Page56/E1/6.jpg' />
            <div style='margin-left:6px'>#</div>
          </div>
        </div>
        `,
        answer: ["dancing", "eating", "singing", "watching", "playing"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 8",
    id: "WB3V2-U8-P56-E2",
    audio: "Audios/Track 12/audio.mp3",
    exerciseKey: "img/FriendsPlus/Page56/E2/Key/answerKey.png",
    video: "",
    component: DesignUnderLine,
    // component: DesignUnderLine1,
    totalInput: 3,

    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page56/E2/a.jpg" },
        {
          url: "img/FriendsPlus/Page56/E2/b.jpg",
          audioUrl: "Audios/Track 12/tieude.mp3",
        },
        { url: "img/FriendsPlus/Page56/E2/c.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page56/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page56/E2/2.jpg" },
        { url: "img/FriendsPlus/Page56/E2/3.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page56/E2/4.jpg" },
        { url: "img/FriendsPlus/Page56/E2/5.jpg", input: 1 },
        { url: "img/FriendsPlus/Page56/E2/6.jpg" },
        { url: "img/FriendsPlus/Page56/E2/7.jpg", input: 1 },
        { url: "img/FriendsPlus/Page56/E2/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page56/E2/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page56/E2/10.jpg" },
        { url: "img/FriendsPlus/Page56/E2/11.jpg", input: 2 },
        { url: "img/FriendsPlus/Page56/E2/12.jpg" },
        { url: "img/FriendsPlus/Page56/E2/13.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page56/E2/14.jpg" },
        { url: "img/FriendsPlus/Page56/E2/15.jpg", input: 2 },
        { url: "img/FriendsPlus/Page56/E2/16.jpg" },
        { url: "img/FriendsPlus/Page56/E2/17.jpg", input: 3 },
        { url: "img/FriendsPlus/Page56/E2/18.jpg" },
        { url: "img/FriendsPlus/Page56/E2/19.jpg", input: 3 },
        { url: "img/FriendsPlus/Page56/E2/20.jpg" },
        { url: "img/FriendsPlus/Page56/E2/21.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page56/E2/22.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
};

export default json;
