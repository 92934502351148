import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    unit: "Unit 3",
    id: "WB3V2-U3-P25-E1",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page25/E1/Key/answerKey.png",
    stylesTextInput: { borderBottom: "dotted" },
    inputSize: 700,
    maxLength: 200,
    checkUppercase: true,
    // exerciseKey: "img/FriendsPlus/Page32/E8/Key/answerKey.png",
    titleQuestion: [
      {
        num: "",
        title: " <img src='img/FriendsPlus/Page25/E1/1.jpg' /> ",
        color: "",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "35px",
              left: "531px",
              width: "40px",
              height: "70px",
              borderRadius: "20px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "35px",
              left: "637px",
              width: "40px",
              height: "70px",
              borderRadius: "20px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "35px",
              left: "743px",
              width: "40px",
              height: "70px",
              borderRadius: "20px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "35px",
              left: "848px",
              width: "40px",
              height: "70px",
              borderRadius: "20px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "120px",
              left: "304px",
              width: "40px",
              height: "70px",
              borderRadius: "20px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "120px",
              left: "409px",
              width: "40px",
              height: "70px",
              borderRadius: "20px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "120px",
              left: "518px",
              width: "50px",
              height: "60px",
              borderRadius: "20px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "120px",
              left: "634px",
              width: "50px",
              height: "60px",
              borderRadius: "20px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "120px",
              left: "744px",
              width: "40px",
              height: "70px",
              borderRadius: "20px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "120px",
              left: "854px",
              width: "50px",
              height: "60px",
              borderRadius: "20px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "207px",
              left: "302px",
              width: "40px",
              height: "70px",
              borderRadius: "20px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "207px",
              left: "409px",
              width: "40px",
              height: "70px",
              borderRadius: "20px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 11
          {
            boxMatchStyle: {
              position: "absolute",
              top: "207px",
              left: "517px",
              width: "40px",
              height: "70px",
              borderRadius: "20px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 12
          {
            boxMatchStyle: {
              position: "absolute",
              top: "207px",
              left: "624px",
              width: "40px",
              height: "70px",
              borderRadius: "20px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 13
          {
            boxMatchStyle: {
              position: "absolute",
              top: "207px",
              left: "737px",
              width: "50px",
              height: "60px",
              borderRadius: "20px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 14
          {
            boxMatchStyle: {
              position: "absolute",
              top: "210px",
              left: "850px",
              width: "40px",
              height: "70px",
              borderRadius: "20px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 15
          {
            boxMatchStyle: {
              position: "absolute",
              top: "35px",
              left: "420px",
              width: "50px",
              height: "60px",
              borderRadius: "20px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 16
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "500px",
          //     left: "450px",
          //     width: "40px",
          //     height: "70px",
          //     borderRadius: "20px",
          //     border: "1px solid #2eb6e1",
          //     background: "white",
          //   },
          // }, // 17
        ],
        answers: ["16-6", "6-7", "14-7", "14-9"],
        initialValue: [],
      },
      Layout: `
      <div><img src='img/FriendsPlus/Page25/E1/2.jpg' /></div>
    
       <div><input id='0'type='boxMatch'></input></div> 
       <div><input id='1'type='boxMatch'></input></div> 
       <div><input id='2'type='boxMatch'></input></div> 
      
      
      <div><input id='3'type='boxMatch'></input></div> 
      <div><input id='4'type='boxMatch'></input></div> 
      <div><input id='5'type='boxMatch'></input></div> 
     
    
      <div><input id='6'type='boxMatch'></input></div> 
      <div><input id='7'type='boxMatch'></input></div> 
      <div><input id='8'type='boxMatch'></input></div> 
     
    
      <div><input id='9'type='boxMatch'></input></div> 
      <div><input id='10'type='boxMatch'></input></div> 
      <div><input id='11'type='boxMatch'></input></div> 
    
    

      <div><input id='12'type='boxMatch'></input></div> 
      <div><input id='13'type='boxMatch'></input></div> 
      <input id='14'type='boxMatch'></input></div> 
    
    
      <div><input id='15'type='boxMatch'></input></div> 
      <div><input id='16'type='boxMatch'></input></div> 
    



      
      `,
    },
  },
  2: {
    // Exercise num
    unit: "Unit 3",
    id: "WB3V2-U3-P25-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page25/E2/Key/answerKey.png",
    component: MatchDots,
    // titleImage: "<img src='img/FriendsPlus/Page19/E2/1.jpg'>",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page19/E2/1.jpg'>",
        color: "",
      },
    ],

    question: {
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "80px",
              left: "100px",
              width: 140,
              height: 162,
              // border: "2px solid red",
            },
          },
          //0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "144px",
              left: "460px",
              width: 125,
              height: 135,
              // border: "2px solid red",
            },
          },
          //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "70px",
              left: "810px",
              width: 240,
              height: 122,
              // border: "2px solid red",
            },
          },
          //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "315px",
              left: "100px",
              width: 140,
              height: 138,
              // border: "2px solid red",
            },
          },
          //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "310px",
              left: "460px",
              width: 125,
              height: 140,
              // border: "2px solid red",
            },
          },
          //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "300px",
              left: "800px",
              width: 170,
              height: 140,
              // border: "2px solid red",
            },
          },
          //5

          //16
        ],
        answers: ["1-3", "1-5", "2-4", "0-4"],
        initialValue: [],
      },
      Layout: `
          <img src='img/FriendsPlus/Page25/E2/1.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
        
          
      `,
    },
  },
  3: {
    // Exercise num
    unit: "Unit 3",
    id: "WB3V2-U3-P25-E3",
    audio: "",
    video: "",
    component: D1,
    maxLength: 1,
    inputHeight: 37,
    fontSize: 34,
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page25/E3/Key/answerKey.png",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page25/E3/23.jpg" }],
      [{ url: "img/FriendsPlus/Page25/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page25/E3/2.jpg" },
        { url: "img/FriendsPlus/Page25/E3/3.jpg", input: true, answer: "f" },
        { url: "img/FriendsPlus/Page25/E3/4.jpg" },
        { url: "img/FriendsPlus/Page25/E3/5.jpg", input: true, answer: "i" },
        { url: "img/FriendsPlus/Page25/E3/6.jpg" },
        { url: "img/FriendsPlus/Page25/E3/7.jpg", input: true, answer: "g" },
        { url: "img/FriendsPlus/Page25/E3/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page25/E3/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page25/E3/10.jpg" },
        { url: "img/FriendsPlus/Page25/E3/11.jpg", input: true, answer: "e" },
        { url: "img/FriendsPlus/Page25/E3/12.jpg" },
        { url: "img/FriendsPlus/Page25/E3/13.jpg", input: true, answer: "g" },
        { url: "img/FriendsPlus/Page25/E3/14.jpg" },
        { url: "img/FriendsPlus/Page25/E3/15.jpg", input: true, answer: "g" },
        { url: "img/FriendsPlus/Page25/E3/16.jpg" },
        { url: "img/FriendsPlus/Page25/E3/17.jpg", input: true, answer: "f" },
        { url: "img/FriendsPlus/Page25/E3/18.jpg" },
        { url: "img/FriendsPlus/Page25/E3/19.jpg", input: true, answer: "a" },
        { url: "img/FriendsPlus/Page25/E3/20.jpg" },
        { url: "img/FriendsPlus/Page25/E3/21.jpg", input: true, answer: "n" },
        { url: "img/FriendsPlus/Page25/E3/22.jpg" },
      ],
    ],
  },
};

export default json;
