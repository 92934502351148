import Proptypes from "prop-types";
import styles from "./mywork.module.css";
import React from "react";
export default function MyWork({
  text: propsListTitle,
  colobg,
  wordColor,
  boxColor,
  imgList,
}) {
  return (
    <div>
      <fieldset
        className={styles.fieldset}
        style={{
          border: `2px solid ${colobg}`,
          boxShadow: `2px 2px ${wordColor}`,
        }}
      >
        <legend
          className={styles.legend}
          style={{
            color: `${wordColor}`,
            background: `${colobg}`,
          }}
        >
          MY WORK
        </legend>
        <div>
          {propsListTitle.map((listTile, indexTitle) => {
            return (
              <content key={indexTitle} className={styles.content}>
                {listTile.text}
              </content>
            );
          })}
        </div>
        <div>
          {imgList?.map((listImage, indexListImage) => {
            return (
              <img
                key={indexListImage}
                src={listImage.url}
                atl={`image-${indexListImage}`}
                style={{
                  width: "22cm",
                  marginTop: "20px",
                }}
              />
            );
          })}
        </div>
      </fieldset>
    </div>
  );
}

MyWork.propTypes = {
  text: Proptypes.object,
  colobg: Proptypes.string,
  wordColor: Proptypes.string,
  boxColor: Proptypes.string,
  imgList: Proptypes.object,
};
