import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    // Exercise num
    unit: "Unit 5",
    id: "WB3V2-U5-P38-E1",
    audio: "Audios/Track 9/audio.mp3",
    video: "",
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: "img/FriendsPlus/Page38/E1/Key/answerKey.png",
    titleImage: "",
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, fill in the correct circle. <headphone name='09' src='Audios/Track 9/tieude.mp3'></headphone>",
        color: "#224192",
      },
    ],
    questionImage: [
      // Row

      [
        // Column2
        { url: "img/FriendsPlus/Page38/E1/4.jpg" },
      ],
      [
        // Column3
        { url: "img/FriendsPlus/Page38/E1/5.jpg" },
        { url: "img/FriendsPlus/Page38/E1/6.jpg", input: 1 },
        { url: "img/FriendsPlus/Page38/E1/7.jpg" },
        { url: "img/FriendsPlus/Page38/E1/8.jpg", input: 1 },
        { url: "img/FriendsPlus/Page38/E1/9.jpg" },
        { url: "img/FriendsPlus/Page38/E1/10.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page38/E1/11.jpg" },
      ],
      [
        // Column4
        { url: "img/FriendsPlus/Page38/E1/12.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page38/E1/13.jpg" },
        { url: "img/FriendsPlus/Page38/E1/14.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page38/E1/15.jpg" },
        { url: "img/FriendsPlus/Page38/E1/16.jpg", input: 2 },
        { url: "img/FriendsPlus/Page38/E1/17.jpg" },
        { url: "img/FriendsPlus/Page38/E1/18.jpg", input: 2 },
        { url: "img/FriendsPlus/Page38/E1/19.jpg" },
      ],
      [
        // Column6
        { url: "img/FriendsPlus/Page38/E1/20.jpg" },
      ],
      [
        // Column7
        { url: "img/FriendsPlus/Page38/E1/21.jpg" },
        { url: "img/FriendsPlus/Page38/E1/22.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page38/E1/23.jpg" },
        { url: "img/FriendsPlus/Page38/E1/24.jpg", input: 3 },
        { url: "img/FriendsPlus/Page38/E1/25.jpg" },
        { url: "img/FriendsPlus/Page38/E1/26.jpg", input: 3 },
        { url: "img/FriendsPlus/Page38/E1/27.jpg" },
      ],
      [
        // Column8
        { url: "img/FriendsPlus/Page38/E1/28.jpg" },
      ],
      [
        // Column9
        { url: "img/FriendsPlus/Page38/E1/29.jpg" },
        { url: "img/FriendsPlus/Page38/E1/30.jpg", input: 4 },
        { url: "img/FriendsPlus/Page38/E1/31.jpg" },
        { url: "img/FriendsPlus/Page38/E1/32.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page38/E1/33.jpg" },
        { url: "img/FriendsPlus/Page38/E1/34.jpg", input: 4 },
        { url: "img/FriendsPlus/Page38/E1/35.jpg" },
      ],
      [
        // Column10
        { url: "img/FriendsPlus/Page38/E1/36.jpg" },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 5",
    id: "WB3V2-U5-P38-E2",
    audio: "",
    video: "",
    component: Circle_Write,
    hideBtnFooter: true,
    isHiddenCheck: true,
    maxLength: 5,
    // exerciseKey: "img/FriendsPlus/Page38/E2/Key/answerKey.png",

    question: {
      Write: {
        inputStyle: {
          maxLength: 5,
          width: 145,
          textAlign: "center",
          color: "#818285",
        },
        answers: ["water", "juice"],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          border: "solid 2px",
          borderRadius: "50%",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 3,
          border: "3px solid",
          borderColor: "gray",
        },
        limitSelect: 1,
        listWords: ["Yes,_I_do. / No,_I_don’t.", "Yes,_I_do / No,_I_don’t"],
        answers: ["0-0", "1-0"],
        initialValue: [],
      },
      Layout: `
 

      <div style="position: relative;">
        <div><img src='img/FriendsPlus/Page38/E2/1.jpg' /></div>
          <div style="position: absolute;top: 185px;left: 340px;font-size: 25px;text-align:center">
            <div><input id='0' /></div>
          </div>
         
          <div style="position: absolute;top: 185px;left: 650px;font-size: 25px;">
            <div><input id='0' type='Circle' /></div>     
          </div>
          <div style="position: absolute;top: 270px;left: 340px;font-size: 25px;text-align:center">
            <div><input id='1' /></div>
          </div>
          <div style="position: absolute;top: 270px;left: 650px;font-size: 25px;">
            <div><input id='1' type='Circle' /></div>     
          </div>

      </div>

      `,
    },
  },
};

export default json;
