import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";


const json = {
  1: {
    // Exercise num
    unit: "Unit 11",
    id: "WB3V2-U11-P79-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page79/E1/Key/answerKey.png",
    component: MatchDots,
    // titleImage: "<img src='img/FriendsPlus/Pag73/E2/1.jpg'>",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page79/E1/2.jpg'>",
        color: "",
      },
    ],

    question: {
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "80px",
              left: "130px",
              width: 190,
              height: 132,
              // border: "2px solid red",
            },
          },
          //0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "120px",
              left: "470px",
              width: 165,
              height: 135,
              // border: "2px solid red",
            },
          },
          //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "70px",
              left: "770px",
              width: 160,
              height: 130,
              // border: "2px solid red",
            },
          },
          //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "300px",
              left: "130px",
              width: 180,
              height: 138,
              // border: "2px solid red",
            },
          },
          //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "285px",
              left: "470px",
              width: 165,
              height: 140,
              // border: "2px solid red",
            },
          },
          //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "300px",
              left: "780px",
              width: 140,
              height: 140,
              // border: "2px solid red",
            },
          },
          //5

          //16
        ],
        answers: [
          "0-4"
, "4-5"
, "1-3"
, "1-2"
        ],
        initialValue: [],
      },
      Layout: `
          <img src='img/FriendsPlus/Page79/E1/1.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
        
          
      `,
    },
  },
  2: {
    // Exercise num
    unit: "Unit 11",
    id: "WB3V2-U11-P79-E2",
    audio: "",
    video: "",
    isHiddenCheck:true,
    component: T6,
    exerciseKey: "img/FriendsPlus/Page79/E2/Key/answerKey.png",
    inputSize: 35,
    textAlign: "center",
    maxLength: 1,
    titleImage: "img/FriendsPlus/Page79/E2/7.jpg",
    titleQuestion: [{ num: "", title: "", color: "" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='display:flex; margin: 10px 50px'>
          <div style='margin:'>
            <img src='img/FriendsPlus/Page79/E2/1.jpg' />
            <div style='margin:5px 115px'>ea<u style="color: gray;">r</u> <u style="color: gray;">s</u></div>
          </div>
          <div style='margin:0px 100px'>
            <img src='img/FriendsPlus/Page79/E2/2.jpg' />
            <div style='margin:5px 96px'>#ea#</div>
          </div>
        </div>
        <div style='display:flex; margin: 10px 50px'>
          <div style='margin:'>
            <img src='img/FriendsPlus/Page79/E2/3.jpg' />
            <div style='margin:5px 0px 0px 90px'>##are</div>
          </div>
          <div style='margin:0px 100px'>
            <img src='img/FriendsPlus/Page79/E2/4.jpg' />
            <div style='margin:5px 66px'>##are#</div>
          </div>
        </div>
        <div style='display:flex; margin: 10px 50px'>
          <div style='margin:'>
            <img src='img/FriendsPlus/Page79/E2/5.jpg' />
            <div style='margin:5px 0px 0px 85px'>#ea#</div>
          </div>
          <div style='margin:0px 100px'>
            <img src='img/FriendsPlus/Page79/E2/6.jpg' />
            <div style='margin:5px 70px'>###are</div>
          </div>
        </div>
        `,
        answer: ["h", "r", "s", "h", "s", "c", "d", "n", "r", "s", "q", "u"],
      },
    ],
  },
};

export default json;
