import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DrawColor from "../../components/ExcerciseTypes/DrawColor";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    unit: "Unit 5",
    id: "WB3V2-U5-P15-E1",
    audio: "",
    video: "",
    component: T6,
    maxLength: 20,
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page15/E1/Key/answerKey.png",
    selectStyle: {
      width: 40,
      textAlign: "center",
      // height: 100,
      fontSize: 26,
      backgroundColor: "none",
      border: "2px solid",
    },
    stylesTextInput: { borderBottom: "dotted", width: 250 },
    // selectOptionRandom: true,
    selectOptionValues: ["a", "b", "c", "d"],
    questionImage: [],
    titleImage: "img/FriendsPlus/Page15/E1/1.jpg",
    questions: [
      {
        title: `
        <div style='position: relative;'>
          <span style=" position: absolute; top: 38px; left: 300px; "><select id='4'></select></span>
          <div style=" position: absolute; top: 185px; left: 300px; "><select id='5'></select></div>
          <div style=" position: absolute; top: 335px; left: 300px; "><select id='6'></select></div>
          <div style=" position: absolute; top: 483px; left: 300px; "><select id='7'></select></div>
          <div>
              <div style='display:flex;margin:20px 0px'>
                <b>1.</b>
                <div style='margin:0px 200px 0px 10px'>
                  Thisismybike. <br># 
                </div>
                <img src="img/FriendsPlus/Page15/E1/a.jpg">
              </div>
              <div style='display:flex;margin:20px 0px'>
                <b>2.</b>
                <div style='margin:0px 200px 0px 10px'>
                  Openthedoor. <br># 
                </div>
                <img src="img/FriendsPlus/Page15/E1/b.jpg">
              </div>
              <div style='display:flex;margin:20px 0px'>
                <b>3.</b>
                <div style='margin:0px 200px 0px 10px'>
                  Thisismyredball. <br># 
                </div>
                <img src="img/FriendsPlus/Page15/E1/c.jpg">
              </div>
              <div style='display:flex;margin:20px 0px'>
                <b>4.</b>
                <div style='margin:0px 200px 0px 10px'>
                  Closethewindow. <br># 
                </div>
                <img src="img/FriendsPlus/Page15/E1/d.jpg">
              </div>

          </div>
        </div>

        `,
        answer: [
          "This is my bike.",
          "Open the door.",
          "This is my red ball.",
          "close the window.",
          "b",
          "c",
          "d",
          "a",
        ],
        initialValue: ["a"],
      },
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 1",
    id: "WB3V2-U1-P15-E2",
    audio: "",
    video: "",
    component: DrawColor,
    // exerciseKey: "img/FriendsPlus/Page15/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page15/E2/1.jpg'>",
        color: "",
      },
    ],
    hideBtnFooter: true,
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        listStyle: {
          // normal: {},
          YourToy: {
            padding: 3,
            border: "solid 2px",
            borderRadius: "50%",
            borderColor: "#4285F4",
          },
          YourFavoriteToy: { borderBottom: "2px solid #4285F4" },
          // square: { padding: 3, border: "solid 2px", borderColor: "#4285F4" },
        },
        limitSelect: 1,
        listWords: [
          "bike", //0
          "car", //1
          "train", //2
          "doll", //3
          "teddy_bear", //4
          "kite", //5
          "ball", //6
        ],
        answers: {},
        initialValue: {},
      },
      Layout: `
      <div style='border:3px solid green; padding: 10px 40px; margin:20px; border-radius: 20px'>
        <span style='margin-right:50px'><input id='0' type='Circle' /></span>
        <span style='margin-right:50px'><input id='1' type='Circle' /></span>
        <span style='margin-right:50px'><input id='2' type='Circle' /></span>
        <span style='margin-right:50px'><input id='3' type='Circle' /></span>
        <span style='margin-right:50px'><input id='4' type='Circle' /></span>
        <span style='margin-right:50px'><input id='5' type='Circle' /></span>
        <input id='6' type='Circle' />
      </div>

      `,
    },
  },
  3: {
    unit: "Unit 5",
    id: "WB3V2-U5-P15-E3",
    audio: "",
    video: "",
    component: T6,
    // maxLength: 20,
    isHiddenCheck: true,
    // exerciseKey: "img/FriendsPlus/Page15/E1/Key/answerKey.png",
    // selectStyle: {
    //   width: 40,
    //   textAlign: "center",
    //   // height: 100,
    //   fontSize: 26,
    //   backgroundColor: "none",
    //   border: "2px solid",
    // },
    stylesTextInput: { borderBottom: "dash", width: 250 },
    // selectOptionRandom: true,
    // selectOptionValues: ["a", "b", "c", "d"],
    questionImage: [],
    titleImage: "img/FriendsPlus/Page15/E3/1.jpg",
    questions: [
      {
        title: `
          <div style='display:flex'>
            <img src='img/FriendsPlus/Page15/E3/2.jpg'>
            <div style=' line-height:45px;margin: 20px 10px'>
              This is my #.<br>
              This is#.<br>
              My favorite is#.<br>
              <input id='3' width='400px'></input>.
            </div>
          </div>

        `,
        answer: ["", "", "", ""],
        initialValue: [],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 1",
    id: "WB3V2-U1-P15-E4",
    audio: "",
    video: "",
    exerciseKey: "",
    recorder: true,
    component: UI,
    fontSize: 30,
    maxLength: 1,

    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page15/E4/1.jpg" }],
    ],
  },
};

export default json;
