import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 9",
    id: "WB3V2-U9-P63-E1",
    audio: "Audios/Track 15/audio.mp3",
    video: "",
    component: T6,
    checkUppercase: true,
    selectStyle: {
      color: "#00ADFE",
      width: 36,
      height: 45,
      textAlign: "center",
    },
    selectOptionValues: ["✓", "✗"],
    exerciseKey: "img/FriendsPlus/Page63/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen. Put and check (✓) or cross (✗) in the box. <headphone name='15' src='Audios/Track 15/tieude.mp3' ></headphone>",
        color: "#233f94",
      },
    ],

    questions: [
      {
        title: `
					<div style=" position: relative; ">
						<div> <img src='img/FriendsPlus/Page63/E1/2.jpg' /> </div>
						<div style=" position: absolute; top: 260px; left: 516px; "><select id='0'></select></div>
						<div style=" position: absolute; top: 385px; left: 516px; "><select id='1'></select></div>
						<div style=" position: absolute; top: 137px; left: 750px; "><select id='2'></select></div>
						<div style=" position: absolute; top: 260px; left: 750px; "><select id='3'></select></div>
					</div>
				`,
        answer: ["✗", "✗", "✓", "✗"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 9",
    id: "WB3V2-U9-P63-E2",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    // exerciseKey: 'img/FriendsPlus/Page62/E2/Key/answerKey.png',
    inputSize: 83,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page63/E2/Key/answerKey.png",

    // titleImage: "img/FriendsPlus/Page62/E2/1.jpg",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page63/E2/6.jpg' />",
        color: "",
      },
    ],
    maxLength: 5,
    questionImage: [],

    questions: [
      {
        title: `
        <div style='display:flex' >
          <div style='margin-left: 20px'>
            <img src='img/FriendsPlus/Page63/E2/1.jpg' />
            <div style='margin-left:50px'>I <u style="color: gray;">&emsp;can’t&emsp;</u> fly.</div>
          </div>
          <div style='margin-left: 20px'>
            <img src='img/FriendsPlus/Page63/E2/2.jpg' />
            <div style='margin-left:50px'>It#catch.</div>
          </div>
          <div style='margin-left: 20px'>
            <img src='img/FriendsPlus/Page63/E2/3.jpg' />
            <div style='margin-left:5px'>She#ride a bike.</div>
          </div>
        </div>
        <div style='display:flex;margin-top: 20px' >
          <div style='margin-left: 150px'>
            <img src='img/FriendsPlus/Page63/E2/4.jpg' />
            <div style='margin-left:2px'>He#play soccer.</div>
          </div>
          <div style='margin-left: 20px'>
            <img src='img/FriendsPlus/Page63/E2/5.jpg' />
            <div style='margin-left:55px'>It#run.</div>
          </div>
        </div>
                `,
        answer: ["can", "can't", "can", "can"],
      },
    ],
  },
};

export default json;
