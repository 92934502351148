import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "WB3V2-U1-P12-E1",
    audio: "",
    video: "",
    component: DesignUnderLine,
    totalInput: 2,
    exerciseKey: "img/FriendsPlus/Page12/E1/Key/answerKey.png",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page12/E1/12.jpg" }],
      [{ url: "img/FriendsPlus/Page12/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page12/E1/2.jpg" },
        { url: "img/FriendsPlus/Page12/E1/3.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page12/E1/4.jpg" },
        { url: "img/FriendsPlus/Page12/E1/5.jpg", input: 2 },
        { url: "img/FriendsPlus/Page12/E1/6.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page12/E1/7.jpg" },
        { url: "img/FriendsPlus/Page12/E1/8.jpg", input: 1 },
        { url: "img/FriendsPlus/Page12/E1/9.jpg" },
        { url: "img/FriendsPlus/Page12/E1/10.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page12/E1/11.jpg" },
      ],
    ],
  },
  2: {
    unit: "Unit 1",
    id: "WB3V2-U1-P12-E2",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page12/E2/Key/answerKey.png",
    stylesTextInput: { borderBottom: "dotted" },
    inputSize: 700,
    maxLength: 200,
    checkUppercase: true,
    // exerciseKey: "img/FriendsPlus/Page32/E8/Key/answerKey.png",
    titleQuestion: [
      {
        num: "",
        title: " <img src='img/FriendsPlus/Page12/E2/7.jpg' /> ",
        color: "",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "200px",
              width: 300,
              height: 150,
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "727px",
              width: 300,
              height: 150,
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "257px",
              left: "152px",
              width: 300,
              height: 150,
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "257px",
              left: "700px",
              width: 300,
              height: 150,
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "450px",
              left: "150px",
              width: 300,
              height: 150,
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "450px",
              left: "700px",
              width: 300,
              height: 150,
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "450px",
              width: 85,
              height: "35px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "115px",
              left: "450px",
              width: 85,
              height: "35px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "450px",
              width: 85,
              height: "35px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "307px",
              left: "450px",
              width: 85,
              height: "35px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "405px",
              left: "450px",
              width: 85,
              height: "35px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "500px",
              left: "450px",
              width: 85,
              height: "35px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 12
        ],
        answers: ["0-11", "1-10", "2-7", "3-6", "4-8", "5-9"],
        initialValue: [],
      },
      Layout: `
      <div style='display:flex; margin-bottom:40px'>
        <div style=' margin-right: 200px'><img src='img/FriendsPlus/Page12/E2/1.jpg' /></div>
        <div><input id='0'  type='boxMatch' /></div>
        <div><input id='1'  type='boxMatch' /></div>  
        <div style=' margin-right: 176px'>
          <div style='margin-bottom: 50px;font-size: 30px'>train</div>
          <div style='font-size: 30px'>ball</div>
          <div><input id='2'  type='boxMatch' /></div>
          <div><input id='3'  type='boxMatch' /></div> 
         </div>
        <div><img src='img/FriendsPlus/Page12/E2/2.jpg' /></div>
      </div>       
      <div style='display:flex;margin-bottom:40px'>
        <div style=' margin-right: 200px'><img src='img/FriendsPlus/Page12/E2/3.jpg' /></div>
        <div><input id='4'  type='boxMatch' /></div>
        <div><input id='5'  type='boxMatch' /></div>
        <div style=' margin-right: 176px'>
          <div style='margin-bottom: 50px;font-size: 30px'>doll</div>
          <div style='font-size: 30px'>car</div>
          <div><input id='6'  type='boxMatch' /></div>
          <div><input id='7'  type='boxMatch' /></div>
        </div>
        <div><img src='img/FriendsPlus/Page12/E2/4.jpg' /></div>
      </div>       
      <div style='display:flex'>
        <div style=' margin-right: 200px'><img src='img/FriendsPlus/Page12/E2/5.jpg' /></div>
        <div><input id='8'  type='boxMatch' /></div>
        <div><input id='9'  type='boxMatch' /></div>
        <div style=' margin-right: 176px'>
          <div style='margin-bottom: 50px;font-size: 30px'>kite</div>
          <div style='font-size: 30px'>bike</div>
          <div><input id='10'  type='boxMatch' /></div>
          <div><input id='11'  type='boxMatch' /></div>
        </div>
        <div><img src='img/FriendsPlus/Page12/E2/6.jpg' /></div>
      </div> 
     



      
      `,
    },
  },
};

export default json;
