import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "WB3V2-U2-P16-E1",
    audio: "",
    video: "",
    component: DesignUnderLine,
    totalInput: 4,
    exerciseKey: "img/FriendsPlus/Page16/E1/Key/answerKey.png",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page16/E1/24.jpg" }],
      [{ url: "img/FriendsPlus/Page16/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page16/E1/2.jpg" },
        { url: "img/FriendsPlus/Page16/E1/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page16/E1/4.jpg" },
        { url: "img/FriendsPlus/Page16/E1/5.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page16/E1/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page16/E1/7.jpg" }],
      [
        { url: "img/FriendsPlus/Page16/E1/8.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page16/E1/9.jpg" },
        { url: "img/FriendsPlus/Page16/E1/10.jpg", input: 2 },
        { url: "img/FriendsPlus/Page16/E1/11.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page16/E1/12.jpg" }],
      [
        { url: "img/FriendsPlus/Page16/E1/13.jpg" },
        { url: "img/FriendsPlus/Page16/E1/14.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page16/E1/15.jpg" },
        { url: "img/FriendsPlus/Page16/E1/16.jpg", input: 3 },
      ],
      [{ url: "img/FriendsPlus/Page16/E1/17.jpg" }],
      [
        { url: "img/FriendsPlus/Page16/E1/18.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page16/E1/19.jpg" },
        { url: "img/FriendsPlus/Page16/E1/20.jpg", input: 4 },
        { url: "img/FriendsPlus/Page16/E1/21.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page16/E1/22.jpg" }],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 2",
    id: "WB3V2-U2-P16-E2",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page16/E2/Key/answerKey.png",
    inputSize: 35,
    maxLength: 1,
    // titleImage: 'img/FriendsPlus/Page13/E2/1.jpg',
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page16/E2/1.jpg' />",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display:flex'>
            <div><img src='img/FriendsPlus/Page16/E2/2.jpg' /></div>
            <div style='margin-left:30px'>
              <div style='margin-bottom: 25px'>
              <span style='color:gray; text-decoration:underline'>p</span>
              <span style='color:gray; text-decoration:underline'>e</span>
              n
              
              </div>
              <div style='margin-bottom: 25px'>#####l</div>
              <div style='margin-bottom: 25px'>##o#</div>
              <div style='margin-bottom: 25px'>###s##</div>
              <div style='margin-bottom: 25px'>##n###c###</div>

            </div>

          </div>
          `,
        answer: [
          "p",
          "e",
          "n",
          "c",
          "i",
          "b",
          "o",
          "k",
          "e",
          "r",
          "a",
          "e",
          "r",
          "p",
          "e",
          "c",
          "i",
          "l",
          "a",
          "s",
          "e",
        ],
      },
    ],
  },
};

export default json;
