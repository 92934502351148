import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  5: {
    // Exercise num
    unit: "Unit Grammar Time",
    id: "WB3V2- Grammar Time-P91-E5",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page91/E5/Key/answerKey.png",
    inputSize: 120,
    maxLength: 7,
    checkUppercase: true,
    textAlign: "center",

    // titleImage: "img/FriendsPlus/Page88/E4/1.jpg",
    titleQuestion: [
      {
        num: "",
        title:
          "<span style='; font-size: 30px;background: rgb(1 174 240);color: white; padding: 5px 10px; border-radius: 10px'>Unit 5</span>",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `

          
          <div style='margin: -52px 0px 40px 120px; border-radius: 5px;line-height:40px;background:rgb(199 234 254); padding: 5px 50px 5px 10px'>
            <b>Do</b> you <b>like</b> carrot? <br>
            <b>Yes, i do. / No, I don’t.</b><br>
          </div>
        <span style='color: black; font-size: 30px; margin-top:20px'><b> Write.</b></span>
         <div style='margin:; line-height: 60px'>
            <b>1.</b>  Do you like rice? Yes,#.<br>
            <b>2.</b> #you like meat? No,#.<br>
         </div>
         
    `,
        answer: ["i do", "Do", "i don't"],
      },
    ],
  },
  6: {
    // Exercise num
    unit: "Unit Grammar Time",
    id: "WB3V2- Grammar Time-P91-E6",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page91/E6/Key/answerKey.png",
    inputSize: 120,
    maxLength: 7,
    checkUppercase: true,
    textAlign: "center",
    selectStyle: {
      color: "#00ADFE",
      width: 36,
      height: 36,
      borderRadius: 5,
      border: "2px solid gray",
    },
    selectOptionValues: ["✓", "✗"],

    // titleImage: "img/FriendsPlus/Page88/E4/1.jpg",
    titleQuestion: [
      {
        num: "",
        title:
          "<span style='; font-size: 30px;background: rgb(1 174 240);color: white; padding: 5px 10px; border-radius: 10px'>Unit 6</span>",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `

        <div style='display:flex'>
            <div style='margin: -52px 0px 40px 120px; border-radius: 5px;line-height:40px;background:rgb(199 234 254); padding: 5px 50px 0px 10px'>
              <b>I have</b> black hair. <br>
              <b>I don’t</b> have blue eyes.<br>
            </div>
            <div style='margin: -52px 0px 40px 5px; border-radius: 5px;line-height:40px;background:rgb(199 234 254); padding: 5px 50px 0px 10px'>
              <b>She has</b> green eyes. <br>
              <b>He has</b> curly hair.<br>
            </div>
        </div>
        <span style='color: black; font-size: 30px; margin-top:20px'><b> Read and check ✓ or cross ✗.</b></span>
          <div style='display:flex'>
            <div style='margin:; line-height: 60px ;'>
               <b>1.</b>  I has long hair.<br>
               <b>2.</b>  He has brown hair.<br>
            </div>
            <div style='margin-left: 150px; line-height: 60px ;'>
               <b>3.</b>  She have brown eyes.<br>
               <b>4.</b> I don’t have brown eyes.<br>
            </div>
         </div>
          <div style=" position: absolute; top:215px; left: 765px; "><select  id='0'></select></div>
					<div style=" position: absolute; top: 215px; left: 1221px; "><select id='1'></select></div>
					<div style=" position: absolute; top: 275px; left: 809px; "><select id='2'></select></div>
					<div style=" position: absolute; top: 275px; left: 1245px; "><select id='3'></select></div>
    `,
        answer: ["✗", "✗", "✓", "✓"],
      },
    ],
  },
  7: {
    // Exercise num
    unit: "Unit Grammar Time",
    id: "WB3V2- Grammar Time-P91-E7",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page91/E7/Key/answerKey.png",
    inputSize: 55,
    maxLength: 2,
    checkUppercase: true,
    // textAlign: "center",

    // titleImage: "img/FriendsPlus/Page88/E4/1.jpg",
    titleQuestion: [
      {
        num: "",
        title:
          "<span style='; font-size: 30px;background: rgb(1 174 240);color: white; padding: 5px 10px; border-radius: 10px'>Unit 7</span>",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `

          
          <div style='margin: -52px 0px 40px 120px; border-radius: 5px;line-height:40px;background:rgb(199 234 254); padding: 5px 50px 5px 10px'>
            <b>I’m</b> wear<b>ing</b> a yellow shirt.<br>
            <b>He’s</b> wear<b>ing</b> a yellow shirt.<br>
            <b>She’s</b> wear<b>ing</b> a yellow shirt.<br>
          </div>
        <span style='color: black; font-size: 30px; margin-top:20px'><b> Write.</b></span>
         <div style='margin:; line-height: 60px'>
            <b>1.</b> She#wearing black shoes.<br>
            <b>2.</b> I#wearing a green hat.<br>
            <b>3.</b> He#wearing blue pants.<br>
         </div>
         
    `,
        answer: ["is / 's", "'m / am", "'s / is"],
      },
    ],
  },
  8: {
    // Exercise num
    unit: "Unit Grammar Time",
    id: "WB3V2- Grammar Time-P91-E8",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page91/E8/Key/answerKey.png",
    inputSize: 100,
    maxLength: 6,
    checkUppercase: true,
    textAlign: "center",

    // titleImage: "img/FriendsPlus/Page88/E4/1.jpg",
    titleQuestion: [
      {
        num: "",
        title:
          "<span style='; font-size: 30px;background: rgb(1 174 240);color: white; padding: 5px 10px; border-radius: 10px'>Unit 8</span>",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `

          
          <div style='margin: -52px 0px 40px 120px; border-radius: 5px;line-height:40px;background:rgb(199 234 254); padding: 5px 50px 5px 10px'>
            What <b>are you doing? I’m</b> talk<b>ing</b>.<br>
            What’<b>s</b> he do<b>ing</b>? <b>He’</b> eat<b>ing</b>.<br>
            What’<b>s</b> she do<b>ing</b>? <b>She’</b>s eat<b>ing</b>.<br>
          </div>
        <span style='color: black; font-size: 30px; margin-top:20px'><b> Write.</b></span>
         <div style='margin:; line-height: 60px'>
            <b>1.</b> What#he doing? #washing the car.<br>
            <b>2.</b> What#you doing? #taking photos.<br>
            <b>3.</b> What#she doing? #brushing her hair.<br>
         </div>
         
    `,
        answer: [
          "is / 's",
          "He is / He's",
          "are",
          "I'm / I am",
          "'s / is",
          "She is / She's",
        ],
      },
    ],
  },
};

export default json;
