import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    // Exercise num
    unit: "Stater",
    id: "WB3V2-S-P5-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page5/E1/Key/answerKey.png",
    component: D1,
    maxLength: 5,

    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page5/E1/22.jpg" }],
      [{ url: "img/FriendsPlus/Page5/E1/21.jpg" }],
      [{ url: "img/FriendsPlus/Page5/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page5/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page5/E1/3.jpg",
          input: true,
          answer: "name",
        },
        { url: "img/FriendsPlus/Page5/E1/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page5/E1/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page5/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page5/E1/7.jpg",
          input: true,
          answer: "Tim",
        },
        { url: "img/FriendsPlus/Page5/E1/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page5/E1/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page5/E1/10.jpg" },
        {
          url: "img/FriendsPlus/Page5/E1/11.jpg",
          input: true,
          answer: "you",
        },
        { url: "img/FriendsPlus/Page5/E1/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page5/E1/13.jpg" }],
      [
        { url: "img/FriendsPlus/Page5/E1/14.jpg" },
        {
          url: "img/FriendsPlus/Page5/E1/15.jpg",
          input: true,
          answer: "bye",
        },
        { url: "img/FriendsPlus/Page5/E1/16.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page5/E1/17.jpg" },
        {
          url: "img/FriendsPlus/Page5/E1/18.jpg",
          input: true,
          answer: "i'm",
        },
        { url: "img/FriendsPlus/Page5/E1/19.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page5/E1/20.jpg" }],
    ],
  },
  2: {
    unit: "Starter",
    id: "WB3V2-S-P5-E2",
    audio: "",
    video: "",
    component: T6,
    maxLength: 1,
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page5/E2/1.jpg' />",
        color: "#0067b4",
      },
    ],
    stylesTextInput: {
      fontSize: 28,
      textAlign: "center",
      width: 40,
      height: 40,
      borderBottom: "dash",
      backgroundColor: "transparent",
      border: "2px solid black",
    },
    inputSize: 110,
    maxLength: 7,
    exerciseKey: "img/FriendsPlus/Page5/E2/Key/answerKey.png",
    questionImage: [{ src: "img/FriendsPlus/Page5/E2/1.jpg" }],
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style='display:flex; position: relative; margin:10px 50px;line-height: 50px'>
            <div>
              <b>1.</b>&emsp;Hello.<br>
              <b>2.</b>&emsp;How are you?<br>
              <b>3.</b>&emsp;What’s your name?<br>
              <b>4.</b>&emsp;Goodbye.<br>
            </div>
            <div style='margin-left: 200px'>
              <b>a.</b>&emsp;Bye.<br>
              <b>b.</b>&emsp;Hello.<br>
              <b>c.</b>&emsp;I’m fine.<br>
              <b>d.</b>&emsp;My name’s Tim.<br>
            </div>
            <div style=" position: absolute; top: 0px; left: 105px;">#</div>
            <div style=" position: absolute; top: 50px; left: 192px;">#</div>
            <div style=" position: absolute; top: 100px; left: 245px;">#</div>
            <div style=" position: absolute; top: 150px; left: 146px;">#</div>
					</div>
				`,
        answer: ["b", "c", "d", "a"],
      },
    ],
  },
};

export default json;
