import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Stater",
    id: "WB3-S-P7-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page7/E1/Key/answerKey.png",
    component: MatchDots,
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page7/E1/1.jpg' />",
        color: "",
      },
    ],

    question: {
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          
          {
            boxMatchStyle: {
              position: "absolute",
              top: "56px",
              left: "215px",
              width: 50,
              height: 80,
              // border: "2px solid"
            },
          },
          //b
          {
            boxMatchStyle: {
              position: "absolute",
              top: "90px",
              left: "370px",
              width: 50,
              height: 72,
              // border: "2px solid"
            },
          },
          //h
          {
            boxMatchStyle: {
              position: "absolute",
              top: "68px",
              left: "469px",
              width: 20,
              height: 72,
              // border: "2px solid"
            },
          },
          //i
          {
            boxMatchStyle: {
              position: "absolute",
              top: "86px",
              left: "594px",
              width: 30,
              height: 92,
              // border: "2px solid"
            },
          },

          //j
          {
            boxMatchStyle: {
              position: "absolute",
              top: "60px",
              left: "725px",
              width: 25,
              height: 77,
              // border: "2px solid"
            },
          },
          //l
          {
            boxMatchStyle: {
              position: "absolute",
              top: "145px",
              left: "144px",
              width: 50,
              height: 72,
              // border: "2px solid"
            },
          },

          //d
          {
            boxMatchStyle: {
              position: "absolute",
              top: "165px",
              left: "280px",
              width: 40,
              height: 52,
              // border: "2px solid"
            },
          },

          //c
          {
            boxMatchStyle: {
              position: "absolute",
              top: "200px",
              left: "495px",
              width: 50,
              height: 72,
              // border: "2px solid"
            },
          },

          //k
          {
            boxMatchStyle: {
              position: "absolute",
              top: "176px",
              left: "694px",
              width: 80,
              height: 52,
              // border: "2px solid"
            },
          },
          //m
          {
            boxMatchStyle: {
              position: "absolute",
              top: "237px",
              left: "62px",
              width: 48,
              height: 56,
              // border: "2px solid"
            },
          },
          //e
          {
            boxMatchStyle: {
              position: "absolute",
              top: "250px",
              left: "216px",
              width: 45,
              height: 72,
              // border: "2px solid"
            },
          },
          //f
          {
            boxMatchStyle: {
              position: "absolute",
              top: "265px",
              left: "363px",
              width: 50,
              height: 75,
              // border: "2px solid"
            },
          },
          //g
          {
            boxMatchStyle: {
              position: "absolute",
              top: "254px",
              left: "590px",
              width: 50,
              height: 52,
              // border: "2px solid"
            },
          },
          //o
          {
            boxMatchStyle: {
              position: "absolute",
              top: "249px",
              left: "760px",
              width: 50,
              height: 52,
              // border: "2px solid"
            },
          },
          //n
          {
            boxMatchStyle: {
              position: "absolute",
              top: "350px",
              left: "114px",
              width: 50,
              height: 58,
              // border: "2px solid"
            },
          },
          //x
          {
            boxMatchStyle: {
              position: "absolute",
              top: "339px",
              left: "279px",
              width: 60,
              height: 52,
              // border: "2px solid"
            },
          },
          //w
          {
            boxMatchStyle: {
              position: "absolute",
              top: "319px",
              left: "447px",
              width: 38,
              height: 72,
              // border: "2px solid"
            },
          },
          //t
          {
            boxMatchStyle: {
              position: "absolute",
              top: "378px",
              left: "540px",
              width: 40,
              height: 54,
              // border: "2px solid"
            },
          },//s
          {
            boxMatchStyle: {
              position: "absolute",
              top: "340px",
              left: "680px",
              width: 50,
              height: 72,
              // border: "2px solid"
            },
          },//p
          {
            boxMatchStyle: {
              position: "absolute",
              top: "450px",
              left: "70px",
              width: 50,
              height: 72,
              // border: "2px solid"
            },
          },//y
          {
            boxMatchStyle: {
              position: "absolute",
              top: "450px",
              left: "175px",
              width: 48,
              height: 52,
              // border: "2px solid"
            },
          },//z
          {
            boxMatchStyle: {
              position: "absolute",
              top: "440px",
              left: "300px",
              width: 50,
              height: 52,
              // border: "2px solid"
            },
          },//v
          {
            boxMatchStyle: {
              position: "absolute",
              top: "445px",
              left: "415px",
              width: 50,
              height: 52,
              // border: "2px solid"
            },
          },//u
          {
            boxMatchStyle: {
              position: "absolute",
              top: "460px",
              left: "613px",
              width: 37,
              height: 52,
              // border: "2px solid"
            },
          },//r
          {
            boxMatchStyle: {
              position: "absolute",
              top: "425px",
              left: "765px",
              width: 50,
              height: 72,
              // border: "2px solid"
            },
          },//q
        ],
        answers: ["0-6"
        , "5-6"
        , "5-9"
        , "10-9"
        , "10-11"
        , "1-11"
        , "1-2"
        , "2-3"
        , "3-7"
        , "4-7"
        , "4-8"
        , "13-8"
        , "12-13"
        , "12-18"
        , "18-24"
        , "23-24"
        , "17-23"
        , "16-17"
        , "16-22"
        , "21-22"
        , "15-21"
        , "14-15"
        , "14-19"
        , "19-20"],
        initialValue: [],
      },
      Layout: `
          <img src='img/FriendsPlus/Page7/E1/2.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
        
          <input id='7' type= 'boxMatch' />
          <input id='8' type= 'boxMatch' />
          <input id='9' type= 'boxMatch' />
          <input id='10' type= 'boxMatch' />
          <input id='11' type= 'boxMatch' />
          <input id='12' type= 'boxMatch' />
          <input id='13' type= 'boxMatch' />
          <input id='14' type= 'boxMatch' />
          <input id='15' type= 'boxMatch' />
          <input id='16' type= 'boxMatch' />
          <input id='17' type= 'boxMatch' />
          <input id='18' type= 'boxMatch' />
          <input id='19' type= 'boxMatch' />
          <input id='20' type= 'boxMatch' />
          <input id='21' type= 'boxMatch' />
          <input id='22' type= 'boxMatch' />
          <input id='23' type= 'boxMatch' />
          <input id='24' type= 'boxMatch' />
      `,
    },
  },
  2: {
    // Exercise num
    unit: "Starter",
    id: "WB3V2-S-P7-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page7/E2/Key/answerKey.png",
    component: D1,
    maxLength: 2,
    fontSize: 50,
    inputHeight: 50,
    checkUppercase: true,
    checkDuplicated: true,
    questionImage: [
      // Row

      [
        {
          url: "img/FriendsPlus/Page7/E2/27.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page7/E2/1.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page7/E2/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page7/E2/3.jpg",
          input: true,
          answer: "Bb",
        },
        {
          url: "img/FriendsPlus/Page7/E2/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page7/E2/5.jpg",
          input: true,
          answer: "Ff",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page7/E2/6.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page7/E2/7.jpg",
        },
        {
          url: "img/FriendsPlus/Page7/E2/8.jpg",
          input: true,
          answer: "Ii",
        },
        {
          url: "img/FriendsPlus/Page7/E2/9.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page7/E2/10.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page7/E2/11.jpg",
        },
        {
          url: "img/FriendsPlus/Page7/E2/12.jpg",
          input: true,
          answer: "Mm",
        },
        {
          url: "img/FriendsPlus/Page7/E2/13.jpg",
        },
        {
          url: "img/FriendsPlus/Page7/E2/14.jpg",
          input: true,
          answer: "Oo",
        },
        {
          url: "img/FriendsPlus/Page7/E2/15.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page7/E2/16.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page7/E2/17.jpg",
        },
        {
          url: "img/FriendsPlus/Page7/E2/18.jpg",
          input: true,
          answer: "Ss",
        },
        {
          url: "img/FriendsPlus/Page7/E2/19.jpg",
        },
        {
          url: "img/FriendsPlus/Page7/E2/20.jpg",
          input: true,
          answer: "Vv",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page7/E2/21.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page7/E2/22.jpg",
        },
        {
          url: "img/FriendsPlus/Page7/E2/23.jpg",
          input: true,
          answer: "Xx",
        },
        {
          url: "img/FriendsPlus/Page7/E2/24.jpg",
        },
        {
          url: "img/FriendsPlus/Page7/E2/25.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page7/E2/26.jpg",
        },
      ],
    ],
  },
};

export default json;
