import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Review 3",
    id: "WB3V2-R3-P68-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page68/E1/Key/answerKey.png",
    checkDuplicated: true,
    isHiddenCheck: true,
    stylesTextInput: {
      fontSize: 24,
      backgroundColor: "transparent",
      borderBottom: "none",
      // borderBottomColor: 'red',
    },
    inputSize: 170,
    titleImage: "img/FriendsPlus/Page68/E1/2.jpg",
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex; gap: 50px; flex-wrap: wrap; padding: 10px 50px; border: 1px solid rgb(244, 142, 142); border-radius: 10px;'>
            <span>boots</span> <span>a scarf</span> <span style='text-decoration: line-through;'>jeans</span> 
            <span>a shirt</span> <span>a hat</span> <span>a T-shirt</span>
          </div>
          <div style='margin-top: 20px;'><img src='img/FriendsPlus/Page68/E1/1.jpg' style='width: 100%;' /></div>  
          <div style='position: relative;'>
            <div style='position: absolute; top: -472px; left: 103px;'>#</div>
            <div style='position: absolute; top: -218px; left: 370px;'>#</div>
            <div style='position: absolute; top: -179px; left: 300px;'>#</div>
            <div style='position: absolute; top: -218px; left: 729px;'>#</div>
            <div style='position: absolute; top: -179px; left: 652px;'>#</div>
          </div>
        `,
        answer: [
          "a shirt",
          "a hat / a T-shirt",
          "a hat / a T-shirt",
          "a scarf / boots",
          "a scarf / boots",
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Review 3",
    id: "WB3V2-R3-P68-E3",
    audio: "",
    video: "",
    component: T6,
    hintBox: [
      {
        src: [
          "<s>dance</s>",
          "jeans",
          "run",
          "skirt",
          "catch",
          "fly",
          "talk",
          "sing",
          "scarf",
        ],
        borderColor: "#f25822",
        width: 450,
        textAlign: "center",
      },
    ],
    checkDuplicated: true,
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page68/E2/Key/answerKey.png",
    inputSize: 220,
    textAlign: "center",
    titleImage: "img/FriendsPlus/Page68/E2/1.jpg",
    questionImage: [],
    questions: [
      {
        title: `
          <hintbox id='0'></hintbox>

          <div style="margin-top: 40px; max-width: 900px; 
                display: flex; flex-wrap: wrap; margin-right: -15px; margin-left: -15px;">
            <div>
              <fieldset style='border: 2px solid rgb(237, 27, 36); border-radius: 25px; 
                  margin-right: 25px; padding: 20px; text-align: center; font-size: 26px;'>
                <legend style='font-size: 26px; background-color: rgb(237, 27, 36); border-radius: 10px; 
                    color: white; padding: 5px 10px; text-align: center; width: fit-content;'>
                  actions
                </legend>
                <div style='margin-top: -20px; display: flex; 
                      flex-direction: column; gap: 20px;'>
                  <input style='color: black; font-size: 26px; width: 100%;' />
                  <input style='color: black; font-size: 26px; width: 100%;' />
                  <input style='color: black; font-size: 26px; width: 100%;' />
                </div>
              </fieldset>
            </div>
            <div>
              <fieldset style='border: 2px solid rgb(237, 27, 36); border-radius: 25px; 
                  margin-right: 25px; padding: 20px; text-align: center; font-size: 26px;'>
                <legend style='font-size: 26px; background-color: rgb(237, 27, 36); border-radius: 10px; 
                    color: white; padding: 5px 10px; text-align: center; width: fit-content;'>
                  party
                </legend>
                <div style='margin-top: -20px; display: flex; flex-direction: column; gap: 20px;'>
                <div style='margin-top: -10px; border-bottom: 2px rgb(168,168,168) solid; color: rgb(168,168,168)'>dance</div>
                  <input style='color: black; font-size: 26px; width: 100%;' />
                  <input style='color: black; font-size: 26px; width: 100%;' />
                </div>
              </fieldset>
            </div>
            <div>
              <fieldset style='border: 2px solid rgb(237, 27, 36); border-radius: 25px; 
                  margin-right: 25px; padding: 20px; text-align: center; font-size: 26px;'>
                <legend style='font-size: 26px; background-color: rgb(237, 27, 36); border-radius: 10px; 
                    color: white; padding: 5px 10px; text-align: center; width: fit-content;'>
                  clothes
                </legend>
                <div style='margin-top: -20px; display: flex; flex-direction: column; gap: 20px;'>
                  <input style='color: black; font-size: 26px; width: 100%;' />
                  <input style='color: black; font-size: 26px; width: 100%;' />
                  <input style='color: black; font-size: 26px; width: 100%;' />
                </div>
              </fieldset>
            </div>
          </div>
        `,
        answer: [
          "run / fly / catch",
          "run / fly / catch",
          "run / fly / catch",
          "talk / sing",
          "talk / sing",
          "jeans / skirt / scarf",
          "jeans / skirt / scarf",
          "jeans / skirt / scarf",
        ],
      },
    ],
  },
};

export default json;
