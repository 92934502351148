import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  3: {
    // Exercise num
    unit: "Unit Review 4",
    id: "WB3V2-R4-P89-E3",
    audio: "",
    video: "",
    component: T6,
    inputSize: 48,
    maxLength: 2,
    checkUppercase: true,
    isHiddenCheck: true,
    stylesTextInput: { borderBottom: "none" },
    // selectStyle: {
    //   color: "#00ADFE",
    //   width: 45,
    //   height: 45,
    //   textAlign: "center",
    // },
    // selectOptionValues: ["✓", "✗"],
    exerciseKey: "img/FriendsPlus/Page89/E3/Key/answerKey.png",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page89/E3/1.jpg' /> ",
        color: "",
      },
    ],

    questions: [
      {
        title: `
        <div style=" position: relative; ">
          <div> <img src='img/FriendsPlus/Page89/E3/2.jpg' /> </div>
          <div style=" position: absolute; top: 22px; left: 356px; "><input id='0'></input></div>
          <div style=" position: absolute; top: 45px; left: 710px; "><input id='1'></input></div>
          <div style=" position: absolute; top: 187px; left: 549px; "><input id='2'></input></div>
        </div>
        <div style='display:flex; margin-left:50px'>
            <div style='line-height:50px'>
              <b>1.</b> The car is number fourteen.<br>
              <b>2.</b> The train is number nine teen.
            </div>
            <div style='line-height:50px; margin-left:30px'>
              <b>3.</b> The doll is number seventeen.<br>
              <b>4.</b> The kite is number twelve.
            </div>
        </div>
      `,
        answer: ["17", "20", "19"],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit Review 4",
    id: "WB3V2-R4-P89-E4",
    audio: "",
    video: "",
    component: T6,
    // exerciseKey: "img/FriendsPlus/Page89/E4/Key/answerKey.png",
    inputSize: 645,
    // maxLength: 9,
    // checkUppercase: true,
    // textAlign: "center",
    titleImage: "img/FriendsPlus/Page89/E4/1.jpg",
    // titleQuestion: [{ num: '2', title: 'Look and write.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: ` 
        <div style='display:flex'>
          <div>
            <u style="color: gray;">&emsp;There’s&emsp;</u> a board in my classroom <br>
            #<br>
            #<br>
          </div>
          <div style='line-height: 50px;border:1px solid gray; padding: 10px 10px; margin: 0px 50px'>
            There’s<br>There are
          </div>
        </div>
       
        `,
        answer: ["", ""],
      },
    ],
  },

  5: {
    unit: "Review 4",
    id: "WB3V2-R4-P89-E5",
    audio: "",
    video: "",
    component: T6,
    // exerciseKey: "img/FriendsPlus/Page68/E1/Key/answerKey.png",
    checkDuplicated: true,
    isHiddenCheck: true,
    hideBtnFooter: true,
    mywork: [
      {
        text: "My favorite story in Units 10-12 is",
      },
      { text: "My favorite song in Units 10-12 is" },
      { text: "My favorite unit in Units 10-12 is" },
      { text: "I need to practice" },
      0,
    ],
    myImage: [{ url: "img/FriendsPlus/Page89/images/1.jpg" }],
    colorBG: "#234090",
    wordColor: "#e9f8ff",
    boxColor: "red",
    questions: [
      {
        title: `
      <div style=' position: relative; '>
				<div><mywork></mywork></div>
          <div style=' position: absolute; top: 45px; left:350px; '><input id='0' width='520px'></input></div>
          <div style=' position: absolute; top: 83px; left:350px; '><input id='0' width='520px'></input></div>
          <div style=' position: absolute; top: 121px; left:340px; '><input id='0' width='530px'></input></div>
          <div style=' position: absolute; top: 159px; left:210px; '><input id='0' width='660px'></input></div>
                
      </div>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
