import TextEditer from '../../components/ExcerciseTypes/TextEditer'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB3V2-U1-P13-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page13/E1/Key/answerKey.png',
		component: MatchDots,
		titleImage: "<img src='img/FriendsPlus/Page13/E1/1.jpg'>",
		titleQuestion: [
			{
				num: '',
				title: "<img src='img/FriendsPlus/Page13/E1/1.jpg'>",
				color: '#23408f',
			},
		],

		question: {
			DrawLines: {
				multipleLine: true,
				boxMatch: [
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '54px',
							left: '350px',
							width: 70,
							height: 62,
							// border: "2px solid red"
						},
					},
					//0
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '54px',
							left: '457px',
							width: 70,
							height: 62,
							// border: "2px solid red"
						},
					},
					//1
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '54px',
							left: '565px',
							width: 70,
							height: 62,
							// border: "2px solid red"
						},
					},
					//2
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '56px',
							left: '671px',
							width: 70,
							height: 62,
							// border: "2px solid red"
						},
					},
					//3
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '54px',
							left: '778px',
							width: 70,
							height: 67,
							// border: "2px solid red"
						},
					},
					//4
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '145px',
							left: '244px',
							width: 70,
							height: 67,
							// border: "2px solid red"
						},
					},
					//5
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '145px',
							left: '350px',
							width: 70,
							height: 62,
							// border: "2px solid red"
						},
					},
					//6
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '145px',
							left: '457px',
							width: 70,
							height: 67,
							// border: "2px solid red"
						},
					},
					//7
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '145px',
							left: '565px',
							width: 70,
							height: 62,
							// border: "2px solid red"
						},
					},
					//8
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '145px',
							left: '671px',
							width: 70,
							height: 67,
							// border: "2px solid red"
						},
					},
					//9
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '145px',
							left: '778px',
							width: 70,
							height: 62,
							// border: "2px solid red"
						},
					},
					//10
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '237px',
							left: '244px',
							width: 70,
							height: 67,
							// border: "2px solid red"
						},
					},
					//11
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '237px',
							left: '350px',
							width: 70,
							height: 67,
							// border: "2px solid red"
						},
					},
					//12
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '237px',
							left: '457px',
							width: 70,
							height: 62,
							// border: "2px solid red"
						},
					},
					//13
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '237px',
							left: '565px',
							width: 70,
							height: 67,
							// border: "2px solid red"
						},
					},
					//14
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '237px',
							left: '671px',
							width: 70,
							height: 67,
							// border: "2px solid red"
						},
					},
					//15
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '237px',
							left: '778px',
							width: 70,
							height: 67,
							// border: "2px solid red"
						},
					},
					//16
				],
				answers: ['0-7', '13-7', '13-14', '14-9', '16-9'],
				initialValue: [],
			},
			Layout: `
          <img src='img/FriendsPlus/Page13/E1/2.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
        
          <input id='7' type= 'boxMatch' />
          <input id='8' type= 'boxMatch' />
          <input id='9' type= 'boxMatch' />
          <input id='10' type= 'boxMatch' />
          <input id='11' type= 'boxMatch' />
          <input id='12' type= 'boxMatch' />
          <input id='13' type= 'boxMatch' />
          <input id='14' type= 'boxMatch' />
          <input id='15' type= 'boxMatch' />
          <input id='16' type= 'boxMatch' />
      `,
		},
	},
	2: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB3V2-U1-P13-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page13/E2/Key/answerKey.png',
		component: MatchDots,
		// titleImage: "<img src='img/FriendsPlus/Page13/E2/1.jpg'>",
		titleQuestion: [
			{
				num: '',
				title: "<img src='img/FriendsPlus/Page13/E2/1.jpg'>",
				color: '',
			},
		],

		question: {
			DrawLines: {
				multipleLine: true,
				boxMatch: [
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '105px',
							left: '100px',
							width: 140,
							height: 162,
							// border: "2px solid red"
						},
					},
					//0
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '105px',
							left: '447px',
							width: 140,
							height: 140,
							// border: "2px solid red"
						},
					},
					//1
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '105px',
							left: '770px',
							width: 140,
							height: 162,
							// border: "2px solid red"
						},
					},
					//2
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '310px',
							left: '100px',
							width: 140,
							height: 162,
							// border: "2px solid red"
						},
					},
					//3
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '270px',
							left: '447px',
							width: 140,
							height: 140,
							// border: "2px solid red"
						},
					},
					//4
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '310px',
							left: '770px',
							width: 140,
							height: 167,
							// border: "2px solid red"
						},
					},
					//5
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '145px',
							left: '350px',
							width: 70,
							height: 62,
							// border: "2px solid red"
						},
					},
					//6
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '145px',
							left: '457px',
							width: 70,
							height: 67,
							// border: "2px solid red"
						},
					},
					//7
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '145px',
							left: '565px',
							width: 70,
							height: 62,
							// border: "2px solid red"
						},
					},
					//8
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '145px',
							left: '671px',
							width: 70,
							height: 67,
							// border: "2px solid red"
						},
					},
					//9
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '145px',
							left: '778px',
							width: 70,
							height: 62,
							// border: "2px solid red"
						},
					},
					//10
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '237px',
							left: '244px',
							width: 70,
							height: 67,
							// border: "2px solid red"
						},
					},
					//11
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '237px',
							left: '350px',
							width: 70,
							height: 67,
							// border: "2px solid red"
						},
					},
					//12
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '237px',
							left: '457px',
							width: 70,
							height: 62,
							// border: "2px solid red"
						},
					},
					//13
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '237px',
							left: '565px',
							width: 70,
							height: 67,
							// border: "2px solid red"
						},
					},
					//14
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '237px',
							left: '671px',
							width: 70,
							height: 67,
							// border: "2px solid red"
						},
					},
					//15
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '237px',
							left: '778px',
							width: 70,
							height: 67,
							// border: "2px solid red"
						},
					},
					//16
				],
				answers: ['0-4', '1-3', '1-2', '4-5'],
				initialValue: [],
			},
			Layout: `
          <img src='img/FriendsPlus/Page13/E2/2.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
        
          
      `,
		},
	},
	3: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB3V2-U1-P13-E3',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page13/E3/Key/answerKey.png',
		inputSize: 40,
		maxLength: 1,
		isHiddenCheck: true,
		// titleImage: 'img/FriendsPlus/Page13/E2/1.jpg',
		titleQuestion: [
			{
				num: '',
				title: "<img src='img/FriendsPlus/Page13/E3/9.jpg' />",
				color: '',
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
           <div style='margin: 30px 100px'>
              <div style='display:flex'>
                <div style='margin-right: 200px'><img src='img/FriendsPlus/Page13/E3/1.jpg' /></div>
                <div><img src='img/FriendsPlus/Page13/E3/2.jpg' /></div>

              </div>
              <div style='display:flex'>
                <div style='margin:0px 110px 0px 50px'>#### <img src='img/FriendsPlus/Page13/E3/6.jpg'/> </div>
                <div>##### <img src='img/FriendsPlus/Page13/E3/5.jpg'/> </div>

              </div>
              <div style='display:flex'>
                <div style='margin-right: 200px'><img src='img/FriendsPlus/Page13/E3/3.jpg' /></div>
                <div><img src='img/FriendsPlus/Page13/E3/4.jpg' /></div>

              </div>
              <div style='display:flex'>
                <div style='margin:0px 130px 0px 30px'>#####<img src='img/FriendsPlus/Page13/E3/8.jpg'/> </div>
                <div>### <img src='img/FriendsPlus/Page13/E3/7.jpg'/> </div>

              </div>
            </div> 
          `,
				answer: [
					'b',
					'a',
					'l',
					'l',
					'a',
					'n',
					'n',
					'i',
					'e',
					'a',
					'p',
					'p',
					'l',
					'e',
					'b',
					'a',
					't',
				],
			},
		],
	},
}
export default json
