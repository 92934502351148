import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Stater",
    id: "WB3V2-S-P6-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page6/E1/Key/answerKey.png",
    component: D1,
    fontSize: 30,
    maxLength: 1,

    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page6/E1/14.jpg" }],
      [{ url: "img/FriendsPlus/Page6/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page6/E1/2.jpg" },
        { url: "img/FriendsPlus/Page6/E1/3.jpg", input: true, answer: "4" },
        { url: "img/FriendsPlus/Page6/E1/4.jpg" },
        { url: "img/FriendsPlus/Page6/E1/5.jpg", input: true, answer: "5" },
        { url: "img/FriendsPlus/Page6/E1/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page6/E1/7.jpg" }],
      [
        { url: "img/FriendsPlus/Page6/E1/8.jpg" },
        { url: "img/FriendsPlus/Page6/E1/9.jpg", input: true, answer: "7" },
        { url: "img/FriendsPlus/Page6/E1/10.jpg" },
        { url: "img/FriendsPlus/Page6/E1/11.jpg", input: true, answer: "8" },
        { url: "img/FriendsPlus/Page6/E1/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page6/E1/13.jpg" }],
    ],
  },
  2: {
    // Exercise num
    unit: "Starter",
    id: "WB3V2-S-P6-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page6/E2/Key/answerKey.png",
    component: D1,
    maxLength: 3,
    // checkUppercase: true,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page6/E2/30.jpg" }],
      [{ url: "img/FriendsPlus/Page6/E2/1.jpg" }],
      [
        // Column2
        { url: "img/FriendsPlus/Page6/E2/2.jpg" },
        { url: "img/FriendsPlus/Page6/E2/3.jpg", input: true, answer: "9" },
        { url: "img/FriendsPlus/Page6/E2/4.jpg" },
        { url: "img/FriendsPlus/Page6/E2/5.jpg", input: true, answer: "3" },
        { url: "img/FriendsPlus/Page6/E2/6.jpg" },
        { url: "img/FriendsPlus/Page6/E2/7.jpg", input: true, answer: "8" },
        { url: "img/FriendsPlus/Page6/E2/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page6/E2/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page6/E2/10.jpg" },
        { url: "img/FriendsPlus/Page6/E2/11.jpg", input: true, answer: "one" },
        { url: "img/FriendsPlus/Page6/E2/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page6/E2/13.jpg" }],
      [
        { url: "img/FriendsPlus/Page6/E2/14.jpg" },
        { url: "img/FriendsPlus/Page6/E2/15.jpg", input: true, answer: "ten" },
        { url: "img/FriendsPlus/Page6/E2/16.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page6/E2/17.jpg",
        },
      ],
      [
        { url: "img/FriendsPlus/Page6/E2/18.jpg" },
        { url: "img/FriendsPlus/Page6/E2/19.jpg", input: true, answer: "six" },
        { url: "img/FriendsPlus/Page6/E2/20.jpg" },
        { url: "img/FriendsPlus/Page6/E2/21.jpg", input: true, answer: "two" },
        { url: "img/FriendsPlus/Page6/E2/22.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page6/E2/23.jpg",
        },
      ],
      [
        { url: "img/FriendsPlus/Page6/E2/24.jpg" },
        { url: "img/FriendsPlus/Page6/E2/25.jpg", input: true, answer: "7" },
        { url: "img/FriendsPlus/Page6/E2/26.jpg" },
        { url: "img/FriendsPlus/Page6/E2/27.jpg", input: true, answer: "4" },
        { url: "img/FriendsPlus/Page6/E2/28.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page6/E2/29.jpg",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Starter",
    id: "WB3V2-S-P6-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page6/E3/Key/answerKey.png",
    component: D1,
    textAlign: "center",
    maxLength: 7,
    questionImage: [
      // Row

      [
        {
          url: "img/FriendsPlus/Page6/E3/10.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page6/E3/1.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page6/E3/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page6/E3/3.jpg",
          input: true,
          answer: "I'm ten",
        },
        {
          url: "img/FriendsPlus/Page6/E3/4.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page6/E3/5.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page6/E3/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page6/E3/7.jpg",
          input: true,
          answer: "six",
        },
        {
          url: "img/FriendsPlus/Page6/E3/8.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page6/E3/9.jpg",
        },
      ],
    ],
  },
};

export default json;
