import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Unit 9",
    id: "WB3V2-U9-P65-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page65/E1/Key/answerKey.png",
    component: MatchDots,
    titleImage: "<img src='img/FriendsPlus/Page65/E1/1.jpg'>",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page65/E1/1.jpg'>",
        color: "#23408f",
      },
    ],

    question: {
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "40px",
              left: "390px",
              width: 70,
              height: 72,
              // border: "2px solid red"
            },
          },
          //0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "40px",
              left: "500px",
              width: 70,
              height: 62,
              // border: "2px solid red"
            },
          },
          //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "40px",
              left: "610px",
              width: 70,
              height: 62,
              // border: "2px solid red"
            },
          },
          //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "50px",
              left: "720px",
              width: 40,
              height: 72,
              // border: "2px solid red"
            },
          },
          //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "43px",
              left: "830px",
              width: 60,
              height: 63,
              // border: "2px solid red"
            },
          },
          //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "130px",
              left: "280px",
              width: 60,
              height: 67,
              // border: "2px solid red"
            },
          },
          //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "135px",
              left: "390px",
              width: 40,
              height: 72,
              // border: "2px solid red"
            },
          },
          //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "125px",
              left: "500px",
              width: 70,
              height: 70,
              // border: "2px solid red"
            },
          },
          //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "125px",
              left: "610px",
              width: 70,
              height: 70,
              // border: "2px solid red"
            },
          },
          //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "130px",
              left: "720px",
              width: 70,
              height: 67,
              // border: "2px solid red"
            },
          },
          //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "130px",
              left: "830px",
              width: 70,
              height: 62,
              // border: "2px solid red"
            },
          },
          //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "220px",
              left: "280px",
              width: 40,
              height: 77,
              // border: "2px solid red"
            },
          },
          //11
          {
            boxMatchStyle: {
              position: "absolute",
              top: "220px",
              left: "390px",
              width: 60,
              height: 63,
              // border: "2px solid red"
            },
          },
          //12
          {
            boxMatchStyle: {
              position: "absolute",
              top: "220px",
              left: "500px",
              width: 40,
              height: 72,
              // border: "2px solid red"
            },
          },
          //13
          {
            boxMatchStyle: {
              position: "absolute",
              top: "220px",
              left: "610px",
              width: 40,
              height: 77,
              // border: "2px solid red"
            },
          },
          //14
          {
            boxMatchStyle: {
              position: "absolute",
              top: "210px",
              left: "720px",
              width: 70,
              height: 70,
              // border: "2px solid red"
            },
          },
          //15
          {
            boxMatchStyle: {
              position: "absolute",
              top: "220px",
              left: "830px",
              width: 70,
              height: 67,
              // border: "2px solid red"
            },
          },
          //16
          {
            boxMatchStyle: {
              position: "absolute",
              top: "167px",
              left: "983px",
              width: 130,
              height: 130,
              // border: "2px solid red"
              // background: 'purple',
            },
          },
          //17
        ],
        answers: ["0-7", "7-8", "15-8", "10-15", "10-17"],
        initialValue: [],
      },
      Layout: `
          <img src='img/FriendsPlus/Page65/E1/3.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
        
          <input id='7' type= 'boxMatch' />
          <input id='8' type= 'boxMatch' />
          <input id='9' type= 'boxMatch' />
          <input id='10' type= 'boxMatch' />
          <input id='11' type= 'boxMatch' />
          <input id='12' type= 'boxMatch' />
          <input id='13' type= 'boxMatch' />
          <input id='14' type= 'boxMatch' />
          <input id='15' type= 'boxMatch' />
          <input id='16' type= 'boxMatch' />
          <input id='17' type= 'boxMatch' />
      `,
    },
  },
  2: {
    // Exercise num
    unit: "Unit 9",
    id: "WB3V2-U9-P65-E2",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    // exerciseKey: 'img/FriendsPlus/Page65/E2/Key/answerKey.png',
    inputSize: 40,
    exerciseKey: "img/FriendsPlus/Page65/E2/Key/answerKey.png",

    // titleImage: "img/FriendsPlus/Page65/E2/1.jpg",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page65/E2/5.jpg' />",
        color: "",
      },
    ],
    maxLength: 2,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display:flex; margin: 5px 30px'>
            <div style='margin-right:50px'>
              <img src='img/FriendsPlus/Page65/E2/1.jpg' />
              <div style='margin: 10px 123px'>r<u style="color: gray;"> ai </u>n</div>
            </div>
            <div>
              <img src='img/FriendsPlus/Page65/E2/2.jpg' />
              <div style='margin: 10px 120px'>fl#</div>
            </div>
          </div>
          <div style='display:flex; margin: 25px 30px'>
            <div style='margin-right:50px'>
              <img src='img/FriendsPlus/Page65/E2/3.jpg' />
              <div style='margin: 10px 120px'>sk#</div>
            </div>
            <div>
              <img src='img/FriendsPlus/Page65/E2/4.jpg' />
              <div style='margin: 10px 105px'>tr#n</div>
            </div>
          </div>
                `,
        answer: ["y", "y", "ai"],
      },
    ],
  },
  3: {
    unit: "Unit 9",
    id: "WB3V2-U9-P65-E3",
    audio: "Audios/Track 17/audio.mp3",
    video: "",
    isHiddenCheck: true,
    component: T6,
    inputSize: 110,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page65/E3/Key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and write. <headphone name='17' src='Audios/Track 17/tieude.mp3' ></headphone>",
        color: "#233f94",
      },
    ],
    maxLength: 5,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='margin-top: 30px; display: flex; gap: 20px;'>
            <div style="flex: 1; display: flex; flex-direction: column; justify-content: center;
                        padding: 10px 20px; border-radius: 20px;">
              <div>I can’t<u style="color: gray;">&emsp;fly&emsp;</u> in the #.</div>
              <div>I’m a # in the #.</div>
              <div>I can’t # in the #.</div>
              <div>I’m a #, not a plane.</div>
            </div>
            <div style='flex: 1'><img src='img/FriendsPlus/Page65/E3/2.jpg' style='width: 100%; border-radius: 20px;' /></div>
          </div>
        `,
        answer: ["sky", "train", "rain", "fly", "sky", "train"],
      },
    ],
  },
};

export default json;
