import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 5",
    id: "WB3V2-U5-P36-E1",
    audio: "Audios/Track 8/audio.mp3",
    video: "",
    component: T6,
    checkUppercase: true,
    selectStyle: { color: "#00ADFE", width: 100, textAlign: "center" },
    selectOptionValues: ["✓", "✗"],
    exerciseKey: "img/FriendsPlus/Page36/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen. Put a check (✓) or a cross (✗). <headphone name='08' src='Audios/Track 8/tieude.mp3'></headphone>",
        color: "#224192",
      },
    ],

    questions: [
      {
        title: `
					<div style=" position: relative; ">
						<div> <img src='img/FriendsPlus/Page36/E1/4.jpg' /> </div>
            <div style=" position: absolute; top: 140px; left: 160px; "><select id='0'></select></div>
						<div style=" position: absolute; top: 140px; left: 310px; "><select id='1'></select></div>
						<div style=" position: absolute; top: 140px; left: 460px; "><select id='2'></select></div>
						<div style=" position: absolute; top: 140px; left: 610px; "><select id='3'></select></div>
						<div style=" position: absolute; top: 140px; left: 760px; "><select id='4'></select></div>
						<div style=" position: absolute; top: 245px; left: 160px; "><select id='5'></select></div>
						<div style=" position: absolute; top: 245px; left: 310px; "><select id='6'></select></div>
						<div style=" position: absolute; top: 245px; left: 460px; "><select id='7'></select></div>
						<div style=" position: absolute; top: 245px; left: 610px; "><select id='8'></select></div>
						<div style=" position: absolute; top: 245px; left: 760px; "><select id='9'></select></div> 
					</div>
				`,
        answer: ["✓", "✗", "✗", "✓", "✓", "✗", "✓", "✓", "✓", "✓"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 5",
    id: "WB3V2-U5-P36-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page36/E2/Key/answerKey.png",
    component: D1,
    fontSize: 26,
    maxLength: 7,
    questionImage: [
      [
        // Column1
        { url: "img/FriendsPlus/Page36/E2/1.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page36/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page36/E2/3.jpg",
          input: true,
          answer: "yogurt",
        },
        { url: "img/FriendsPlus/Page36/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page36/E2/5.jpg",
          input: true,
          answer: "bread",
        },
        { url: "img/FriendsPlus/Page36/E2/6.jpg" },
      ],
      [
        // Column3
        { url: "img/FriendsPlus/Page36/E2/7.jpg" },
      ],
      [
        // Column4
        { url: "img/FriendsPlus/Page36/E2/8.jpg" },
        {
          url: "img/FriendsPlus/Page36/E2/9.jpg",
          input: true,
          answer: "carrots",
        },
        { url: "img/FriendsPlus/Page36/E2/10.jpg" },
        {
          url: "img/FriendsPlus/Page36/E2/11.jpg",
          input: true,
          answer: "rice",
        },
        { url: "img/FriendsPlus/Page36/E2/12.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page36/E2/13.jpg" },
      ],
      [
        // Column6
        { url: "img/FriendsPlus/Page36/E2/14.jpg" },
        {
          url: "img/FriendsPlus/Page36/E2/15.jpg",
          input: true,
          answer: "meat",
        },
        { url: "img/FriendsPlus/Page36/E2/16.jpg" },
      ],
      [
        // Column7
        { url: "img/FriendsPlus/Page36/E2/17.jpg" },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 5",
    id: "WB3V2-U5-P36-E3",
    audio: "",
    video: "",
    component: UI,
    // recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [
        // Column2
        { url: "img/FriendsPlus/Page36/E3/1.jpg" },
      ],
    ],
  },
};

export default json;
