import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
const json = {
	1: {
		// Exercise num
		unit: 'Unit 5',
		id: 'WB3V2-U5-P41-E1',
		audio: '',
		video: '',
		component: Circle_Write,
		exerciseKey: 'img/FriendsPlus/Page41/E1/Key/answerKey.png',
		titleQuestion: [
			{
				num: '1',
				title: "Circle n't in the sentence.",
				color: '#394893',
			},
		],
		question: {
			Write: {
				inputStyle: { width: 70 },
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: 0,
					border: 'none',
					borderRadius: '50%',
					borderColor: 'transparent',
				},
				selectWordStyle: {
					padding: 0,
					border: 'solid 2px',
					borderColor: '#bcbdc0',
				},
				limitSelect: 1,
				listWords: [
					"Th|is is|n't a yo|gu|rt.", //1
					"No, it is|n't|.", //2
					"I do|n't li|ke ju|ic|e.", //3
				],
				answers: ['0-6', '1-6', '2-4'],
				// initialValue: ['0-2', '1-8', '1-4', '2-6', '3-8', '4-10', '5-6', '6-8'],
				initialValue: [],
			},
			Layout: `
      <div style=" position: relative; width:934px; font-size:30px">   
        <div style="position: absolute;">        
          <div><b>1. </b>I do<span style=" border: solid 2px #bcbdc0; border-radius: 50%; padding: 3px;">n't</span> like carrots.</div>
          <div><b>2. </b><input id='0' type='Circle' /></div>       
        </div>
        <div style="position: absolute;left: 450px;">
          <div><b>3. </b><input id='1' type='Circle' /></div>
          <div><b>4. </b><input id='2' type='Circle' /></div>
        </div>
      </div>
      `,
		},
	},
	2: {
		// Exercise num
		unit: 'Unit 5',
		id: 'WB3V2-U5-P41-E2',
		audio: '',
		video: '',
		component: T6,
		checkUppercase: true,
		exerciseKey: 'img/FriendsPlus/Page41/E2/Key/answerKey.png',
		inputSize: 300,
		// titleImage: "img/FriendsPlus/Page41/E2/title.jpg",
		titleQuestion: [
			{ num: '2', title: 'Write the short form.', color: '#394893' },
		],
		questionImage: [],
		questions: [
			{
				title: `
        <div style=" display: flex; flex-direction: row; line-height: 58px; justify-content: space-between;width: 800px;">
        <div>
          <div><b>1.</b> I do not like apples.</div>
          <div><b>2.</b> The monkey is not small.</div>
          <div><b>3.</b> I do not like fish.</div>
          <div><b>4.</b> This is not rice.</div>
        </div>
        <div>
          <div><u style="color:gray;">I don't like apples. &emsp;</u></div>
          <div> # </div>
          <div> # </div>
          <div> # </div>
        </div>
      </div>
        `,
				answer: [
					"The monkey isn't small.",
					"I don't like fish.",
					"This isn't rice.",
				],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 5',
		id: 'WB3V2-U5-P41-E3',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		// titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
		questionImage: [
			// Row
			[
				// Column2
				{ url: 'img/FriendsPlus/Page41/E3/1.jpg' },
			],
		],
	},

	4: {
		// Exercise num
		unit: 'Unit 5',
		id: 'WB3V2-U5-P41-E3',
		audio: '',
		video: '',
		component: D1,
		hideBtnFooter: true,
		exerciseKey: '',
		questionImage: [
			// Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page41/E4/1.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page41/E4/2.jpg' },
				{ url: 'img/FriendsPlus/Page41/E4/3.jpg', input: true, answer: '' },
				{ url: 'img/FriendsPlus/Page41/E4/4.jpg' },
			],
			[
				// Column3
				{ url: 'img/FriendsPlus/Page41/E4/5.jpg' },
			],
			[
				// Column4
				{ url: 'img/FriendsPlus/Page41/E4/6.jpg' },
				{ url: 'img/FriendsPlus/Page41/E4/7.jpg', input: true, answer: '' },
				{ url: 'img/FriendsPlus/Page41/E4/8.jpg' },
			],
			[
				// Column5
				{ url: 'img/FriendsPlus/Page41/E4/9.jpg' },
			],
			[
				// Column6
				{ url: 'img/FriendsPlus/Page41/E4/10.jpg' },
				{ url: 'img/FriendsPlus/Page41/E4/11.jpg', input: true, answer: '' },
				{ url: 'img/FriendsPlus/Page41/E4/12.jpg' },
			],
			[
				// Column7
				{ url: 'img/FriendsPlus/Page41/E4/13.jpg' },
			],
			[
				// Column8
				{ url: 'img/FriendsPlus/Page41/E4/14.jpg' },
				{ url: 'img/FriendsPlus/Page41/E4/15.jpg', input: true, answer: '' },
				{ url: 'img/FriendsPlus/Page41/E4/16.jpg' },
			],
			[
				// Column9
				{ url: 'img/FriendsPlus/Page41/E4/17.jpg' },
			],
		],
	},
	5: {
		// Exercise num
		unit: 'Unit 5',
		id: 'WB3V2-U5-P41-E5',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		// titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
		questionImage: [
			// Row
			[
				// Column2
				{ url: 'img/FriendsPlus/Page41/E5/2.jpg' },
			],
		],
	},
}

export default json
