import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import TextEditer from '../../components/ExcerciseTypes/TextEditer'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'

const json = {
	1: {
		unit: 'Unit 6',
		id: 'WB3V2-U6-P44-E1',
		audio: '',
		video: '',
		component: MatchDots,
		exerciseKey: 'img/FriendsPlus/Page44/E1/Key/answerKey.png',
		stylesTextInput: { borderBottom: 'dotted' },
		inputSize: 700,
		maxLength: 200,
		fontSize: 30,
		checkUppercase: true,
		// exerciseKey: "img/FriendsPlus/Page32/E8/Key/answerKey.png",
		titleQuestion: [
			{
				num: '',
				title: " <img src='img/FriendsPlus/Page44/E1/1.jpg'>",
				color: '',
			},
		],
		question: {
			DrawLines: {
				multipleLine: false,
				boxMatch: [
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '150px',
							left: '202px',
							width: 50,
							height: 150,
							// background: 'purple',
							// borderRadius: "50%",
							// border: "1px solid #2eb6e1",
							// background: "white",
						},
					}, // 0
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '209px',
							left: '389px',
							width: 80,
							height: 120,
							// background: 'purple',
							// borderRadius: "50%",
							// border: "1px solid #2eb6e1",
							// background: "white",
						},
					}, // 1
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '148px',
							left: '722px',
							width: 50,
							height: 170,
							// background: 'purple',
							// borderRadius: "50%",
							// border: "1px solid #2eb6e1",
							// background: "white",
						},
					}, // 2
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '385px',
							left: '160px',
							width: 70,
							height: '30px',
							// borderRadius: "50%",
							// border: "1px solid #2eb6e1",
							// background: "white",
						},
					}, // 3
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '385px',
							left: '399px',
							width: 75,
							height: '30px',
							// borderRadius: "50%",
							// border: "1px solid #2eb6e1",
							// background: "white",
						},
					}, // 4
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '385px',
							left: '639px',
							width: 70,
							height: '30px',
							// borderRadius: "50%",
							// border: "1px solid #2eb6e1",
							// background: "white",
						},
					}, // 5
				],
				answers: ['0-5', '1-4', '2-3'],
				initialValue: ['0-5'],
			},
			Layout: `
      
       <img src='img/FriendsPlus/Page44/E1/2.jpg'>
        
        <input id='0' type= 'boxMatch' />
        <input id='1' type= 'boxMatch' />
        <input id='2' type= 'boxMatch' />
        <input id='3' type= 'boxMatch' />
        <input id='4' type= 'boxMatch' />
        <input id='5' type= 'boxMatch' />
        
        
     
        
      `,
		},
	},
	// 1: {
	//   // Exercise num
	//   unit: "Unit 6",
	//   id: "WB3V2-U6-P44-E1",
	//   audio: "",
	//   video: "",
	//   component: TextEditer,
	//   exerciseKey: "img/FriendsPlus/Page44/E1/Key/answerKey.png",
	//   // titleImage: "img/FriendsPlus/Page13/E1/1.jpg",
	//   titleQuestion: [
	//     {
	//       num: "1",
	//       title:
	//         'Write <b style=" border: 1px solid; width: 30px; height: 30px; display: inline-block; text-align: center; ">’</b> in the correct places.',
	//       color: "#5B5A5D",
	//     },
	//   ],
	//   questionImage: [],
	//   question: {
	//     editer: {
	//       editerStyle: {},
	//       initialValue:
	//         "1 This player ' s bag is green. \n 2 Look at this bird. Its wings are really beautiful. \n 3 Janes team won at basketball. \n 4 The schools new soccer team is playing tonight. \n 5 The boys jacket is red and yellow. \n 6 Do you like my new bike? Its a racing bike. \n 7 James Naismiths new sport was named basketball.",
	//       answer:
	//         "1 This player ' s bag is green. \n 2 Look at this bird. Its wings are really beautiful. \n 3 Jane's team won at basketball. \n 4 The school's new soccer team is playing tonight. \n 5 The boy's jacket is red and yellow. \n 6 Do you like my new bike? It's a racing bike. \n 7 James Naismith's new sport was named basketball.",
	//     },
	//     Layout: `
	//       <div>
	//         <div style=" width: 700px; "><editer></editer></div>
	//       </div>
	//     `,
	//   },
	// },
	2: {
		// Exercise num
		unit: 'Unit 6',
		id: 'WB3V2-U6-P44-E2',
		audio: '',
		video: '',
		component: T6,
		checkUppercase: true,
		selectStyle: {
			color: '#00ADFE',
			width: 45,
			height: 45,
			textAlign: 'center',
		},
		selectOptionValues: ['✓', '✗'],
		exerciseKey: 'img/FriendsPlus/Page44/E2/Key/answerKey.png',
		titleQuestion: [
			{
				num: '1',
				title: 'Look and read. Put a check (✓) or a cross (✗) in the box.',
				color: '#23408f',
			},
		],

		questions: [
			{
				title: `
					<div style=" position: relative; ">
						<div> <img src='img/FriendsPlus/Page44/E2/1.jpg' /> </div>
            <div style=" position: absolute; top: 180px; left: 255px; "><select id='0'></select></div>
						<div style=" position: absolute; top: 425px; left: 255px; "><select id='1'></select></div>
						<div style=" position: absolute; top: 667px; left: 255px; "><select id='2'></select></div>
						<div style=" position: absolute; top: 185px; left: 720px; "><select id='3'></select></div>
						<div style=" position: absolute; top: 425px; left: 720px; "><select id='4'></select></div>
						<div style=" position: absolute; top: 667px; left: 720px; "><select id='5'></select></div>
					</div>
				`,
				answer: ['✓', '✗', '✗', '✗', '✓', '✓'],
			},
		],
	},
}

export default json
