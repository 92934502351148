import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	1: {
		// Exercise num
		unit: 'Review 2',
		id: 'WB3V2-R2-P48-E1',
		audio: '',
		video: '',
		component: D1,
		totalInput: 7,
		exerciseKey: 'img/FriendsPlus/Page48/E1/Key/answerKey.png',
		fontSize: 28,
		titleQuestion: [
			{
				num: '',
				title: "<img src='img/FriendsPlus/Page48/E1/23.jpg' />",
				color: '',
			},
		],
		checkUppercase: true,
		inputHeight: 33,
		questionImage: [
			// Row
			[{ url: 'img/FriendsPlus/Page48/E1/1.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page48/E1/2.jpg' },
				{
					url: 'img/FriendsPlus/Page48/E1/3.jpg',
					input: true,
					answer: 'This is a big elephant.',
				},
				{ url: 'img/FriendsPlus/Page48/E1/4.jpg' },
			],
			[{ url: 'img/FriendsPlus/Page48/E1/5.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page48/E1/6.jpg' },
				{ url: 'img/FriendsPlus/Page48/E1/7.jpg' },
				{ url: 'img/FriendsPlus/Page48/E1/8.jpg' },
				{ url: 'img/FriendsPlus/Page48/E1/9.jpg' },
				{ url: 'img/FriendsPlus/Page48/E1/10.jpg' },
			],
			[{ url: 'img/FriendsPlus/Page48/E1/11.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page48/E1/12.jpg' },
				{
					url: 'img/FriendsPlus/Page48/E1/13.jpg',
					input: true,
					answer: 'This is a tall giraffe.',
				},
				{ url: 'img/FriendsPlus/Page48/E1/14.jpg' },
				{
					url: 'img/FriendsPlus/Page48/E1/15.jpg',
					input: true,
					answer: 'This is a blue bird.',
				},
				{ url: 'img/FriendsPlus/Page48/E1/16.jpg' },
			],
			[{ url: 'img/FriendsPlus/Page48/E1/17.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page48/E1/18.jpg' },
				{ url: 'img/FriendsPlus/Page48/E1/19.jpg' },
				{ url: 'img/FriendsPlus/Page48/E1/20.jpg' },
				{ url: 'img/FriendsPlus/Page48/E1/21.jpg' },
				{ url: 'img/FriendsPlus/Page48/E1/22.jpg' },
			],
		],
	},
	2: {
		// Exercise num
		unit: 'Review 2',
		id: 'WB3V2-R2-P48-E2',
		audio: '',
		video: '',
		component: T6,
		checkUppercase: true,
		maxLength: 18,
		exerciseKey: 'img/FriendsPlus/Page48/E2/Key/answerKey.png',
		inputSize: 240,
		// titleImage: "img/FriendsPlus/Page32/E3/1.jpg",
		titleQuestion: [
			{
				num: '',
				title: "<img src='img/FriendsPlus/Page48/E2/2.jpg' />",
				color: '',
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
				<div style='display:flex'>
        		<img src='img/FriendsPlus/Page48/E2/1.jpg' />
						<div>
								<div style='margin-bottom:41px'>
										<b> 1.</b> 
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>giraffes.</span> 
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>like</span>
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>I</span>
										<span style='margin-left:99px'>#</span>
								</div>
								<div style='margin-bottom:41px'>
										<b> 2.</b> 
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>birds.</span> 
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>don't</span>
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>I</span>
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>like</span>
										<span style='margin-left:44px'>#</span>
								</div>
								<div style='margin-bottom:41px'>
										<b> 3.</b> 
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>like</span> 
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>Do</span>
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>milk?</span>
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>you</span>
										<span style='margin-left:36px'>#</span>
								</div>
								<div style='margin-bottom:41px'>
										<b> 4.</b> 
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>don't.</span> 
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>No,</span>
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>I</span>
										<span style='margin-left:125px'>#</span>
								</div>
								<div style='margin-bottom:41px'>
										<b> 5.</b> 
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>you</span> 
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>bread?</span>
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>like</span>
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>Do</span>
										<span style='margin-left:17px'>#</span>
								</div>
								<div>
										<b> 6.</b> 
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>I</span> 
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>do.</span>
										<span style='margin-left:10px;background: pink ;padding: 5px 10px; border-radius: 10px'>Yes,</span>
										<span style='margin-left:141px'>#</span>
								</div>
						</div>
				</div>
        `,
				answer: [
					'I like giraffes.',
					"I don't like birds.",
					'Do you like milk?',
					"No, I don't.",
					'Do you like bread?',
					'Yes, I do.',
				],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Review 2',
		id: 'WB3V2-R2-P48-E3',
		audio: '',
		video: '',
		component: T6,
		hintBox: [
			{
				src: [
					'milk',
					'yogurt',
					'juice',
					'bird',
					'cat',
					'<s>rice</s>',
					'water',
					'elephant',
					'carrots',
				],
				borderColor: '#f25822',
				width: 450,
				textAlign: 'center',
			},
		],
		checkDuplicated: true,
		isHiddenCheck: true,
		exerciseKey: 'img/FriendsPlus/Page48/E3/Key/answerKey.png',
		inputSize: 220,
		textAlign: 'center',
		titleImage: 'img/FriendsPlus/Page48/E3/1.jpg',
		// titleImage: 'img/FriendsPlus/Page28/E2/1.jpg',
		// maxLength: 10,
		questionImage: [],
		questions: [
			{
				title: `
          <hintbox id='0'></hintbox>

          <div style="margin-top: 40px; max-width: 900px; 
                display: flex; flex-wrap: wrap; margin-right: -15px; margin-left: -15px;">
            <div>
              <fieldset style='border: 2px solid rgb(242, 88, 34); border-radius: 25px; 
                  margin-right: 25px; padding: 20px; text-align: center; font-size: 26px;'>
                <legend style='font-size: 26px; background-color: rgb(242, 88, 34); border-radius: 10px; 
                    color: white; padding: 5px 10px; text-align: center; width: fit-content;'>
                  food
                </legend>
                <div style='margin-top: -20px; display: flex; 
                      flex-direction: column; gap: 20px;'>
                  <div style='margin-top: -10px; border-bottom: 2px rgb(168,168,168) solid; color: rgb(168,168,168)'>rice</div>
                  <input style='color: black; font-size: 26px; width: 100%;' />
                  <input style='color: black; font-size: 26px; width: 100%;' />
                </div>
              </fieldset>
            </div>
            <div>
              <fieldset style='border: 2px solid rgb(242, 88, 34); border-radius: 25px; 
                  margin-right: 25px; padding: 20px; text-align: center; font-size: 26px;'>
                <legend style='font-size: 26px; background-color: rgb(242, 88, 34); border-radius: 10px; 
                    color: white; padding: 5px 10px; text-align: center; width: fit-content;'>
                  animals
                </legend>
                <div style='margin-top: -20px; display: flex; flex-direction: column; gap: 20px;'>
                  <input style='color: black; font-size: 26px; width: 100%;' />
                  <input style='color: black; font-size: 26px; width: 100%;' />
                  <input style='color: black; font-size: 26px; width: 100%;' />
                </div>
              </fieldset>
            </div>
            <div>
              <fieldset style='border: 2px solid rgb(242, 88, 34); border-radius: 25px; 
                  margin-right: 25px; padding: 20px; text-align: center; font-size: 26px;'>
                <legend style='font-size: 26px; background-color: rgb(242, 88, 34); border-radius: 10px; 
                    color: white; padding: 5px 10px; text-align: center; width: fit-content;'>
                  drinks
                </legend>
                <div style='margin-top: -20px; display: flex; flex-direction: column; gap: 20px;'>
                  <input style='color: black; font-size: 26px; width: 100%;' />
                  <input style='color: black; font-size: 26px; width: 100%;' />
                  <input style='color: black; font-size: 26px; width: 100%;' />
                </div>
              </fieldset>
            </div>
          </div>
          
        `,
				answer: [
					'yogurt / carrots',
					'yogurt / carrots',
					'bird / cat / elephant',
					'bird / cat / elephant',
					'bird / cat / elephant',
					'milk / juice / water',
					'milk / juice / water',
					'milk / juice / water',
				],
			},
		],
	},
}

export default json
