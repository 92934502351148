import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
import CreateImageCode from '../../components/ExcerciseTypes/Design/CreateImageCode'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 10',
		id: 'WB3V2-U10-P75-E1',
		audio: '',
		video: '',
		component: T6,
		checkUppercase: false,
		isHiddenCheck: true,
		exerciseKey: 'img/FriendsPlus/Page75/E1/Key/answerKey.png',
		inputSize: 160,
		titleImage: 'img/FriendsPlus/Page75/E1/1.jpg',
		textAlign: 'center',
		maxLength: 6,
		questionImage: [],
		questions: [
			{
				title: `
          <div style='border:1px solid gray;border-radius: 10px;margin-left:5px;padding:2px 50px;text-align: center'>
            Listen&emsp;&emsp;<span style='color:gray; text-decoration:line-through'>visit</span>&emsp;&emsp;
            look&emsp;&emsp;draw
          </div>
          <div style='margin:10px; line-height:50px'>
            <b>1.</b> <u style="color: gray;">&emsp;visit&emsp;</u> the gift shop<br>
            <b>2.</b> #at paintings<br>
            <b>3.</b> #a picture<br>
            <b>4.</b> #to an audio guide<br>
          </div>
        `,
				answer: ['look', 'draw', 'listen'],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 10',
		id: 'WB3V2-U10-P75-E2',
		audio: '',
		video: '',
		component: T6,
		hideBtnFooter: true,
		inputSize: 400,
		titleImage: 'img/FriendsPlus/Page75/E2/2.jpg',
		textAlign: 'center',
		questionImage: [],
		questions: [
			{
				title: `
          <div style='display:flex;margin:10px'>
            <div style='margin-right:70px'>
              This is a museum in my town.<br>
              In the museum you may ...<br>
              ✓#<br>
              ✓#<br>
              ✓#<br>
              In the museum you may not...<br>
              ✗#<br>
              ✗#<br>
              ✗#<br>
            </div>
            <img src='img/FriendsPlus/Page75/E2/1.jpg' />

          </div>
        `,
				answer: ['', '', '', '', '', ''],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 10',
		id: 'WB3V2-U10-P75-E3',
		audio: '',
		video: '',
		component: UI,
		titleImage: '',
		recorder: true,
		questionImage: [
			// Row

			[{ url: 'img/FriendsPlus/Page75/E3/1.jpg' }],
		],
	},
}

export default json
