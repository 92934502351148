import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 12',
		id: 'WB3V2-U12-P82-E1',
		audio: '',
		video: '',
		component: MatchDots,
		exerciseKey: 'img/FriendsPlus/Page82/E1/Key/answerKey.png',
		titleQuestion: [
			{
				num: '',
				title: "<img src='img/FriendsPlus/Page82/E1/1.jpg' >",
				color: '',
			},
		],
		question: {
			DrawLines: {
				multipleLine: false,
				boxMatch: [
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '50px',
							left: '115px',
							width: 130,
							height: 75,
							// border: "1px solid #2eb6e1",
						},
					}, //0
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '175px',
							left: '115px',
							width: 135,
							height: 90,
							// border: "1px solid #2eb6e1",
						},
					}, //1

					{
						boxMatchStyle: {
							position: 'absolute',
							top: '310px',
							left: '145px',
							width: 180,
							height: 95,
							// border: "1px solid #2eb6e1",
						},
					}, //2
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '440px',
							left: '120px',
							width: 135,
							height: 105,
							// border: "1px solid #2eb6e1",
						},
					}, //3
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '163px',
							left: '602px',
							width: 70,
							height: 25,
							// border: "1px solid #2eb6e1",
						},
					}, //4
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '222px',
							left: '602px',
							width: 70,
							height: 25,
							// border: "1px solid #2eb6e1",
							borderRadius: '50px',
						},
					}, //5
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '282px',
							left: '602px',
							width: 70,
							height: 25,
							// border: "1px solid #2eb6e1",
							borderRadius: '50px',
						},
					}, //6

					{
						boxMatchStyle: {
							position: 'absolute',
							top: '342px',
							left: '625px',
							width: 120,
							height: 25,
							// border: "1px solid #2eb6e1",
							borderRadius: '50px',
						},
					}, //7
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '300px',
							left: '365px',
							width: 60,
							height: 30,
							// border: "1px solid #2eb6e1",
							borderRadius: '50px',
						},
					}, //8
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '367px',
							left: '365px',
							width: 60,
							height: 30,
							// border: "1px solid #2eb6e1",
							borderRadius: '50px',
						},
					}, //9
				],
				answers: ['0-5', '1-7', '2-6', '3-4'],
				initialValue: [],
			},
			Layout: `
          <img src='img/FriendsPlus/Page82/E1/2.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />

          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />

          <input id='7' type= 'boxMatch' />
          <input id='8' type= 'boxMatch' />
          <input id='9' type= 'boxMatch' />
      `,
		},
	},
	2: {
		// Exercise num
		unit: 'Unit 12',
		id: 'WB3V2-U12-P82-E2',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page82/E2/Key/answerKey.png',
		inputSize: 150,
		// maxLength: 8,
		textAlign: 'center',
		titleImage: 'img/FriendsPlus/Page82/E2/5.jpg',
		questionImage: [],
		questions: [
			{
				title: `
        <div style='line-height: 50px'>

            <div style='display:flex; margin-top:20px'>
              <img src='img/FriendsPlus/Page82/E2/1.jpg' />
              <div style='marign-left:40px'>
                <b>1.</b> There is a <u style="color: gray;">&emsp;table&emsp;</u>
              </div>
            </div>
            <div style='display:flex; margin-top:20px'>
              <img src='img/FriendsPlus/Page82/E2/2.jpg' />
              <div style='marign-left:40px'>
                <b>2.</b> There are # .
              </div>
            </div>
            <div style='display:flex; margin-top:20px'>
              <img src='img/FriendsPlus/Page82/E2/3.jpg' />
              <div style='marign-left:40px'>
                <b>3.</b> There are # .
              </div>
            </div>
            <div style='display:flex; margin-top:20px'>
              <img src='img/FriendsPlus/Page82/E2/4.jpg' />
              <div style='marign-left:40px'>
                <b>4.</b> There is a # .
              </div>
            </div>
        </div>
        `,
				answer: ['computers', 'chairs', 'board'],
				initialValue: ['table'],
			},
		],
	},
}

export default json
