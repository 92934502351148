import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Unit 11",
    id: "WB3V2-U11-P76-E1",
    audio: "",
    video: "",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page76/E1/1.jpg' >",
        color: "",
      },
    ],
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page76/E1/Key/answerKey.png",
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "80px",
              left: "120px",
              width: 170,
              height: 125,
              // border: "1px solid #2eb6e1",
            },
          }, //0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "235px",
              left: "115px",
              width: 225,
              height: 65,
              // border: "1px solid #2eb6e1",
            },
          }, //1

          {
            boxMatchStyle: {
              position: "absolute",
              top: "400px",
              left: "120px",
              width: 180,
              height: 120,
              // border: "1px solid #2eb6e1",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "160px",
              left: "655px",
              width: 135,
              height: 140,
              // border: "1px solid #2eb6e1",
            },
          }, //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "360px",
              left: "665px",
              width: 170,
              height: 100,
              // border: "1px solid #2eb6e1",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "80px",
              left: "380px",
              width: 100,
              height: 30,
              // border: "1px solid #2eb6e1",
              borderRadius: "50px",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "150px",
              left: "380px",
              width: 100,
              height: 30,
              // border: "1px solid #2eb6e1",
              borderRadius: "50px",
            },
          }, //6

          {
            boxMatchStyle: {
              position: "absolute",
              top: "225px",
              left: "380px",
              width: 90,
              height: 30,
              // border: "1px solid #2eb6e1",
              borderRadius: "50px",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "300px",
              left: "365px",
              width: 60,
              height: 30,
              // border: "1px solid #2eb6e1",
              borderRadius: "50px",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "367px",
              left: "365px",
              width: 60,
              height: 30,
              // border: "1px solid #2eb6e1",
              borderRadius: "50px",
            },
          }, //9
        ],
        answers: ["0-8", "1-9", "2-6", "3-5", "4-7"],
        initialValue: [],
      },
      Layout: `
          <img src='img/FriendsPlus/Page76/E1/2.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />

          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />

          <input id='7' type= 'boxMatch' />
          <input id='8' type= 'boxMatch' />
          <input id='9' type= 'boxMatch' />
      `,
    },
  },
  2: {
    // Exercise num
    unit: "Unit 11",
    id: "WB3V2-U11-P76-E2",
    audio: "Audios/Track 19/audio.mp3",
    video: "",
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and write. <headphone name='19' src='Audios/Track 19/tieude.mp3' ></headphone>",
        color: "#233f94",
      },
    ],
    component: T6,
    checkUppercase: true,
    isHiddenCheck: true,
    stylesTextInput: {
      fontSize: 24,
    },
    exerciseKey: "img/FriendsPlus/Page76/E2/Key/answerKey.png",
    inputSize: 210,

    questionImage: [],
    questions: [
      {
        title: `
          <div style='margin-top: 20px; width: fit-content; padding: 10px 40px; border-radius: 10px; border: 1px solid rgb(147, 164, 204); 
                  display: flex; flex-wrap: wrap; gap: 50px;'>
            <span>bed</span><span>cabinet</span><span>rug</span><span>shelf</span>
          </div>
          <div style='margin-top: 30px; display: flex;'>
            <div style='display: flex; flex-direction: column; gap: 30px;'>
              <div style='display: flex; gap: 10px;'>
                <div>1.</div>
                <div>
                  <div>Where's the pillow?</div>
                  <div>It's on the <span style='border-bottom: 2px solid gray; color: gray; padding: 0 25px;'>bed</span>.</div>
                </div>
              </div>
              <div style='display: flex; gap: 10px;'>
                <div>2.</div>
                <div>
                  <div>Where's the book?</div>
                  <div>It's on the <input id='0' width='100px'>.</div>
                </div>
              </div>
              <div style='display: flex; gap: 10px;'>
                <div>3.</div>
                <div>
                  <div>Where's the blanket?</div>
                  <div>#</div>
                </div>
              </div>
            </div>
            <div style='margin-left: 50px; display: flex; flex-direction: column; gap: 30px;'>
              <div style='display: flex; gap: 10px;'>
                <div>4.</div>
                <div>
                  <div>Where's the pen?</div>
                  <div>#</div>
                </div>
              </div>
              <div style='display: flex; gap: 10px;'>
                <div>5.</div>
                <div>
                  <div>Where's the T-shirt?</div>
                  <div>#</div>
                </div>
              </div>
              <div style='display: flex; gap: 10px;'>
                <div>6.</div>
                <div>
                  <div>Where's the teddy bear?</div>
                  <div>#</div>
                </div>
              </div>
            </div>
          </div> 
        `,
        answer: [
          "shelf",
          "It's on the bed.",
          "It's on the shelf.",
          "It's in the cabinet.",
          "It's on the rug.",
        ],
      },
    ],
  },
};

export default json;
