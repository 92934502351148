import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 9",
    id: "WB3V2-U9-P66-E1",
    audio: "",
    video: "",
    component: DesignUnderLine,
    exerciseKey: "img/FriendsPlus/Page66/E1/Key/answerKey.png",
    totalInput: 1,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page66/E1/6.jpg" }],
      [{ url: "img/FriendsPlus/Page66/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page66/E1/2.jpg" },
        { url: "img/FriendsPlus/Page66/E1/3.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page66/E1/4.jpg" },
        { url: "img/FriendsPlus/Page66/E1/5.jpg", input: 1 },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 9",
    id: "WB3V2-U9-P66-E2",
    audio: "",
    video: "",
    component: T6,
    // exerciseKey: 'img/FriendsPlus/Page66/E3/Key/answerKey.png',
    inputSize: 80,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page66/E2/Key/answerKey.png",

    // titleImage: "img/FriendsPlus/Page66/E3/1.jpg",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page66/E2/2.jpg' />",
        color: "",
      },
    ],
    maxLength: 3,
    questionImage: [],
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page66/E2/1.jpg' />
         <div style='margin-left:20px;line-height: 60px'>
          <b>1.</b> It’s sunny. <u style="color: gray;">&emsp;yes&emsp;</u><br>
          <b>2.</b> Hoa is happy. #<br>
          <b>3.</b> Nam can fly. #<br>
          <b>4.</b> Nam can run and jump. #<br>
         </div>
                `,
        answer: ["no", "no", "yes"],
      },
    ],
  },
};

export default json;
