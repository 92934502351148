import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    // Exercise num
    unit: "Stater",
    id: "WB3V2-S-P4-E2",
    audio: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page4/E1/Key/answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/E2/Key/answerKey.png',
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page4/E1/5.jpg'/>   ",
        color: "",
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "5px",
          fontWeight: 600,
          color: "",
          fontSize: 27,
          borderRadius: "20px",
        },
        selectWordStyle: { border: "2px solid #2eb6e1" },
        limitSelect: 1,
        listWords: [
          "Tim / Billy",
          "Miss_Jones / Tim",
          "Rosy / Billy",
          "Miss_Jones / Rosy",
        ],
        answers: ["0-0", "1-0", "2-4", "3-4"],
        initialValue: [],
      },
      Layout: `
      <div style='display:flex ; margin-left: 50px'>
        <div style='margin-right: 50px'>
          <div><img src='img/FriendsPlus/Page4/E1/1.jpg'></div>        
          <div> My name’s <input id='0'  type='Circle' /></div>        
        </div>
        <div>
          <div><img src='img/FriendsPlus/Page4/E1/2.jpg'></div>        
          <div>My name’s <input id='1'  type='Circle' /></div>        
        </div>
      </div>
      <div style='display:flex ; margin-left: 50px'>
        <div style='margin-right: 50px'>
          <div><img src='img/FriendsPlus/Page4/E1/3.jpg'></div>        
          <div> My name’s <input id='2'  type='Circle' /></div>        
        </div>
        <div>
          <div><img src='img/FriendsPlus/Page4/E1/4.jpg'></div>        
          <div>My name’s <input id='3'  type='Circle' /></div>        
        </div>
      </div>

          
        `,
    },
  },
  2: {
    unit: "Stater",
    id: "WB3V2-S-P4-E2",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted" },
    inputSize: 410,
    maxLength: 34,
    // exerciseKey: 'img/FriendsPlus/Page58/E1/Key/answerKey.png',
    // exerciseKey: "img/FriendsPlus/Page46/E3/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page4/E2/1.jpg'/>   ",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='display: flex'>
          <img src='img/FriendsPlus/Page4/E2/2.jpg'> 
          <div style='margin-top: 3cm'>What’s your name?<br>My name’s#</div>
        </div>
            
          `,
        answer: [""],
      },
    ],
  },
};
export default json;
