import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 3',
		id: 'WB3V2-U3-P27-E1',
		audio: '',
		video: '',
		component: T6,
		checkUppercase: true,
		exerciseKey: 'img/FriendsPlus/Page27/E1/Key/answerKey.png',
		inputSize: 300,
		titleImage: 'img/FriendsPlus/Page27/E1/1.jpg',
		maxLength: 24,
		questionImage: [],
		questions: [
			{
				title: `
        <div style='margin-bottom:20px'><b>1.</b>Who’s this? &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;<span style='color:gray; text-decoration:underline'> Who’s this?</span></div>
        <div style='margin-bottom:20px'><b>2.</b> is this your teddy bear&emsp;#</div>
        <div><b>3.</b> where’s your bike&emsp;&emsp;&emsp;#</div>
        `,
				answer: [
					// "Did Dad work today?", "No, he didn't.",
					'Is this your teddy bear?',
					"Where's your bike?",
				],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 3',
		id: 'WB3V2-U3-P27-E2',
		audio: '',
		video: '',
		component: T6,
		checkUppercase: true,
		// exerciseKey: "img/FriendsPlus/Page27/E2/Key/answerKey.png",
		inputSize: 80,
		titleImage: 'img/FriendsPlus/Page27/E2/1.jpg',
		maxLength: 4,
		questionImage: [],
		questions: [
			{
				title: `
          <div style='margin:10px 100px;border:1px solid green; border-radius:5px;text-align:center;padding:5px 40px' >
            <span style='color:gray; text-decoration:line-through'>bag</span>
            &emsp;&emsp;bike&emsp;&emsp;pen&emsp;&emsp;car&emsp;&emsp;hat&emsp;&emsp;ball&emsp;&emsp;kite
          </div>
          <div style='margin:20px 80px'>
            <div style='display:flex;margin-bottom:20px'>
              <div style='margin-right:78px'>mom: <span style='color:gray; text-decoration:underline'>bag</span> </div>
              <div style='margin-right:40px'>sister:#</div>
              <div>uncle:#</div>
            </div>
            <div style='display:flex'>
              <div style='margin-right:50px'>dad:#</div>
              <div style='margin-right:50px'>aunt:#</div>
              <div>brother:#</div>
            </div>

          </div>

        `,
				answer: [
					// "Did Dad work today?", "No, he didn't.",
					'',
					'',
					'',
					'',
					'',
				],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 3',
		id: 'WB3V2-U3-P27-E3',
		audio: '',
		video: '',
		component: T6,
		checkUppercase: true,
		isHiddenCheck: true,
		textAlign: 'center',
		exerciseKey: 'img/FriendsPlus/Page27/E3/Key/answerKey.png',
		inputSize: 70,
		titleImage: 'img/FriendsPlus/Page27/E3/1.jpg',
		maxLength: 5,
		questionImage: [],
		questions: [
			{
				title: `
          <div style='margin:10px 300px;border:1px solid green;width:30%; border-radius:5px;text-align:center;padding:5px 10px' >
            
              its&emsp;&emsp;our&emsp;&emsp;their
          </div>
          <img src='img/FriendsPlus/Page27/E3/2.jpg' />
          <div style='display:flex;margin: 30px 70px'>
            <div style='margin-right:110px'>
              <b style='color:green;background:rgb(219 236 194);padding:8px 15px;border-radius:7px'>1</b>
              This is#house.<br> #door is blue.
            </div>
            <div>
              <b style='color:green;background:rgb(219 236 194);padding:8px 15px;border-radius:7px '>2</b>
              This is#house.<br> #door is #.

            </div>
          </div>
          

        `,
				answer: [
					// "Did Dad work today?", "No, he didn't.",
					'their',
					'its',
					'our',
					'its',
					'blue',
				],
			},
		],
	},
}

export default json
