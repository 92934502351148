import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    unit: "Starter",
    id: "WB3V2-S-P9-E1",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page9/E1/Key/answerKey.png",
    stylesTextInput: { borderBottom: "dotted" },
    inputSize: 700,
    maxLength: 200,
    fontSize: 30,
    checkUppercase: true,
    // exerciseKey: "img/FriendsPlus/Page32/E8/Key/answerKey.png",
    titleQuestion: [
      {
        num: "",
        title: " <img src='img/FriendsPlus/Page9/E1/6.jpg'>",
        color: "",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "150px",
              left: "110px",
              width: 180,
              height: "260px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "150px",
              left: "339px",
              width: 180,
              height: 260,
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "150px",
              left: "559px",
              width: 180,
              height: 260,
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "330px",
              left: "120px",
              width: 170,
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "330px",
              left: "349px",
              width: 225,
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "330px",
              left: "579px",
              width: 160,
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "390px",
              left: "200px",
              width: 160,
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "390px",
              left: "460px",
              width: 175,
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "550px",
              left: "215px",
              width: 180,
              height: 210,
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "550px",
              left: "450px",
              width: 180,
              height: 210,
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "235px",
              left: "580px",
              width: "30px",
              height: "30px",
              // borderRadius: "50%",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 10
        ],
        answers: ["0-6", "1-5", "2-3", "4-8", "7-9"],
        initialValue: [],
      },
      Layout: `
      <div style='display:flex;margin:20px 0px 40px 20px'>
        <div style='margin-right: 40px'><img src='img/FriendsPlus/Page9/E1/1.jpg'></div>
        <div style='margin-right: 40px'><img src='img/FriendsPlus/Page9/E1/2.jpg'></div>
        <div style='margin-right: 40px'><img src='img/FriendsPlus/Page9/E1/3.jpg'></div>
        <input id='0' type= 'boxMatch' />
        <input id='1' type= 'boxMatch' />
        <input id='2' type= 'boxMatch' />
        <input id='3' type= 'boxMatch' />
        <input id='4' type= 'boxMatch' />
        <input id='5' type= 'boxMatch' />
        <input id='6' type= 'boxMatch' />
        <input id='7' type= 'boxMatch' />
        <input id='8' type= 'boxMatch' />
        <input id='9' type= 'boxMatch' />
        
      </div>
      <div style='display:flex;margin-left:40px;font-size:20px'>
        <div style='margin-right: 40px'>Don’t talk, please!</div> 
        <div style='margin-right: 40px'>Close your book, please!</div> 
        <div>Sit down, please!</div> 
      </div> 
      <div style='display:flex; margin: 30px 0px 0px 120px;font-size:20px'>
          <div style='margin-right: 100px'>Stand up, please!</div> 
          <div>Open your book, please!</div> 
        
      </div> 
      <div style='display:flex;margin: 40px 120px'>
        <div style='margin-right: 50px'><img src='img/FriendsPlus/Page9/E1/4.jpg'></div>
        <div style='margin-right: 40px'><img src='img/FriendsPlus/Page9/E1/5.jpg'></div>

      </div>
        
      `,
    },
  },
  2: {
    // Exercise num
    unit: "Starter",
    id: "WB3V2-S-P9-E2",
    audio: "",
    video: "",
    component: T6,
    maxLength: 5,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page9/E2/Key/answerKey.png",
    inputSize: 120,
    titleImage: "img/FriendsPlus/Page9/E2/6.jpg",
    // titleQuestion: [{ num: '2', title: 'Read the story. Choose a word from 1. Write the correct word next to numbers 1–5', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
           <div style='display: flex'>
              <div style='margin-right: 40px'>
                <div><img src='img/FriendsPlus/Page9/E2/1.jpg'></div>
                <div><b>1.</b> # <br> your book.</div>
              </div>
              <div style='margin-right: 40px'>
                <div><img src='img/FriendsPlus/Page9/E2/2.jpg'></div>
                <div><b>2.</b> # <br> up.</div>
              </div>
              <div style='margin-right: 40px'>
                <div><img src='img/FriendsPlus/Page9/E2/3.jpg'></div>
                <div><b>3.</b> # <br> talk.</div>
              </div>
              <div style='margin-right: 40px'>
                <div><img src='img/FriendsPlus/Page9/E2/4.jpg'></div>
                <div><b>4.</b> # <br> your book.</div>
              </div>
              <div>
                <div><img src='img/FriendsPlus/Page9/E2/5.jpg'></div>
                <div><b>5.</b> # <br> down.</div>
              </div>
           </div>
          `,
        answer: [
          // "No, he didn't.",
          "Open",
          "stand",
          "don't",
          "close",
          "sit",
        ],
      },
    ],
  },
};

export default json;
