import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import TB2 from '../../components/ExcerciseTypes/Table/TB2'

const json = {
	1: {
		// Exercise num
		unit: 'Unit Review 4',
		id: 'WB3V2- Review 4-P88-E1',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page88/E1/Key/answerKey.png',
		inputSize: 145,
		maxLength: 9,
		checkUppercase: true,
		textAlign: 'center',
		titleImage: 'img/FriendsPlus/Page88/E1/1.jpg',
		// titleQuestion: [{ num: '2', title: 'Look and write.', color: "#5B5A5D" }],
		questionImage: [],
		questions: [
			{
				title: `
        <img src='img/FriendsPlus/Page88/E1/2.jpg' />
        
            <div style='margin: 10px 30px; line-height: 50px'>
              <b>1.</b> <u style="color: gray;">&emsp;There are&emsp;</u> two boys.<br>
              <b>2.</b># a bed.<br>
              <b>3.</b># two pillows on the bed.<br>
              <b>4.</b># a blanket on the bed.<br>
              <b>5.</b># drawers.<br>
              <b>6.</b># a door.<br>
            </div>
        `,
				answer: ["There's", 'There are', "There's", 'There are', "There's"],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Review 4',
		id: 'WB3V2-R4-P88-E2',
		audio: '',
		video: '',
		component: T6,
		hintBox: [
			{
				src: [
					'computer',
					'<s>twenty</s>',
					'gift shop',
					'chair',
					'board',
					'twelve',
					'tables',
					'eighteen',
					'fifteen',
					'posters',
					'audio guide',
				],
				borderColor: '#243f90',
				width: 700,
				textAlign: 'center',
			},
		],
		checkDuplicated: true,
		isHiddenCheck: true,
		exerciseKey: 'img/FriendsPlus/Page88/E2/Key/answerKey.png',
		inputSize: 220,
		textAlign: 'center',
		titleImage: 'img/FriendsPlus/Page88/E2/2.jpg',
		questionImage: [],
		questions: [
			{
				title: `
      <hintbox id='0'></hintbox>
  
      <div style="margin-top: 40px; max-width: 900px; 
                  display: flex; flex-wrap: wrap; margin-right: -15px; margin-left: -15px;">
      <div>
        <fieldset style='height: 100%; border: 2px solid rgb(37, 63, 142); border-radius: 25px; 
          margin-right: 25px; padding: 20px; text-align: center; font-size: 26px;'>
        <legend style='font-size: 26px; background-color: rgb(37, 63, 142); border-radius: 10px; 
          color: white; padding: 5px 10px; text-align: center; width: fit-content;'>
          numbers
        </legend>
        <div style='margin-top: -20px; display: flex; 
            flex-direction: column; gap: 20px;'>
          <div style='margin-top: -10px; border-bottom: 2px rgb(168,168,168) solid; color: rgb(168,168,168)'>twenty</div>
          <input style='color: black; font-size: 26px; width: 100%;' />
          <input style='color: black; font-size: 26px; width: 100%;' />
          <input style='color: black; font-size: 26px; width: 100%;' />
        </div>
        </fieldset>
      </div>
      <div>
        <fieldset style='height: 100%; border: 2px solid rgb(37, 63, 142); border-radius: 25px; 
          margin-right: 25px; padding: 20px; text-align: center; font-size: 26px;'>
        <legend style='font-size: 26px; background-color: rgb(37, 63, 142); border-radius: 10px; 
          color: white; padding: 5px 10px; text-align: center; width: fit-content;'>
          museum
        </legend>
        <div style='margin-top: -20px; display: flex; flex-direction: column; gap: 20px;'>
          <input style='color: black; font-size: 26px; width: 100%;' />
          <input style='color: black; font-size: 26px; width: 100%;' />
        </div>
        </fieldset>
      </div>
      <div>
        <fieldset style='height: 100%; border: 2px solid rgb(37, 63, 142); border-radius: 25px; 
          margin-right: 25px; padding: 20px; text-align: center; font-size: 26px;'>
        <legend style='font-size: 26px; background-color: rgb(37, 63, 142); border-radius: 10px; 
          color: white; padding: 5px 10px; text-align: center; width: fit-content;'>
          school things
        </legend>
        <div style='margin-top: -20px; display: flex; flex-direction: column; gap: 20px;'>
          <input style='color: black; font-size: 26px; width: 100%;' />
          <input style='color: black; font-size: 26px; width: 100%;' />
          <input style='color: black; font-size: 26px; width: 100%;' />
          <input style='color: black; font-size: 26px; width: 100%;' />
          <input style='color: black; font-size: 26px; width: 100%;' />
        </div>
        </fieldset>
      </div>
      </div>
    `,
				answer: [
					'twelve / eighteen / fifteen',
					'twelve / eighteen / fifteen',
					'twelve / eighteen / fifteen',
					'gift shop / audio guide',
					'gift shop / audio guide',
					'computer / chairs / board / tables / posters',
					'computer / chairs / board / tables / posters',
					'computer / chairs / board / tables / posters',
					'computer / chairs / board / tables / posters',
					'computer / chairs / board / tables / posters',
				],
			},
		],
	},
}

export default json
