import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DrawColor from "../../components/ExcerciseTypes/DrawColor";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
const json = {
  1: {
    // Exercise num
    unit: "Unit 11",
    id: "WB3V2-U11-P81-E1",
    audio: "",
    video: "",
    component: DesignUnderLine,
    totalInput: 4,
    exerciseKey: "img/FriendsPlus/Page81/E1/Key/answerKey.png",
    titleImage: "",
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page81/E1/a.jpg" }],
      [{ url: "img/FriendsPlus/Page81/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page81/E1/2.jpg" },
        { url: "img/FriendsPlus/Page81/E1/3.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page81/E1/4.jpg" },
        { url: "img/FriendsPlus/Page81/E1/5.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page81/E1/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page81/E1/7.jpg" }],

      [
        { url: "img/FriendsPlus/Page81/E1/8.jpg" },
        { url: "img/FriendsPlus/Page81/E1/9.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page81/E1/10.jpg" },
        { url: "img/FriendsPlus/Page81/E1/11.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page81/E1/12.jpg" },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 12",
    id: "WB3V2-U12-P81-E2",
    audio: "",
    video: "",
    component: T6,
    checkUppercase: true,
    exerciseKey: "img/FriendsPlus/Page81/E2/Key/answerKey.png",
    inputSize: 270,
    titleImage: "img/FriendsPlus/Page81/E2/1.jpg",
    questionImage: [],
    questions: [
      {
        title: `
          <div style="line-height: 58px;">
              <b>1.</b> what’s her name? <span style='color:gray; text-decoration:underline;margin-left: 100px'> What’s her name?&emsp;</span><br>
              <b>2.</b> her name’s Lola <span style='margin-left: 104px'> #</span><br>
              <b>3.</b> what’s on her shelf<span style='margin-left: 63px'> #</span>
          </div>
        `,
        answer: ["Her name's Lola.", "What's on her shelf?"],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 11",
    id: "WB3V2-U11-P81-E3",
    audio: "",
    video: "",
    component: Circle_Write,
    hideBtnFooter: true,
    exerciseKey: "img/FriendsPlus/Page84/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "",
        title: "<img src='img/FriendsPlus/Page81/E3/a.jpg'>",
        color: "",
      },
    ],
    question: {
      Write: {
        underlineStyle: { borderBottom: "none" },
        inputStyle: {
          color: "black",
          textAlign: "center",
          width: "40px",
          height: "40px",
          border: "1px solid gray",
          borderRadius: "7px",
          maxLength: 2,
        },
        answers: ["picture", "drawers", "poster"],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "5px",
          fontWeight: 600,
          color: "",
          fontSize: 30,
          borderRadius: "20px",
        },
        selectWordStyle: { border: "2px solid #2eb6e1" },
        limitSelect: 3,
        listWords: [
          "bed",
          "blanket",
          "pillow",
          "cabinet",
          "shelf",
          "rug",
          "book",
          "car",
          "doll",
          "train",
          "kite",
          "teddy_bear",
        ],
        answers: ["0-0", "0-2", "0-4"],
        initialValue: [],
      },
      Layout: `
        <div style='max-width: 1000px; display: flex; gap: 30px; flex-wrap: wrap; justify-content: center;'>
          <div style='display: flex; gap: 7px; align-items: center;'>
            <input id='0' type='Circle' />
            <input id='0' />
          </div>
          <div style='display: flex; gap: 7px; align-items: center;'>
            <input id='1' type='Circle' />
            <input id='1' />
          </div>
          <div style='display: flex; gap: 7px; align-items: center;'>
            <input id='2' type='Circle' />
            <input id='2' />
          </div>
          <div style='display: flex; gap: 7px; align-items: center;'>
            <input id='3' type='Circle' />
            <input id='3' />
          </div>
          <div style='display: flex; gap: 7px; align-items: center;'>
            <input id='4' type='Circle' />
            <input id='4' />
          </div>
          <div style='display: flex; gap: 7px; align-items: center;'>
            <input id='5' type='Circle' />
            <input id='5' />
          </div>
          <div style='display: flex; gap: 7px; align-items: center;'>
            <input id='6' type='Circle' />
            <input id='6' />
          </div>
          <div style='display: flex; gap: 7px; align-items: center;'>
            <input id='7' type='Circle' />
            <input id='7' />
          </div>
          <div style='display: flex; gap: 7px; align-items: center;'>
            <input id='8' type='Circle' />
            <input id='8' />
          </div>
          <div style='display: flex; gap: 7px; align-items: center;'>
            <input id='9' type='Circle' />
            <input id='9' />
          </div>
          <div style='display: flex; gap: 7px; align-items: center;'>
            <input id='10' type='Circle' />
            <input id='10' />
          </div>
          <div style='display: flex; gap: 7px; align-items: center;'>
            <input id='11' type='Circle' />
            <input id='11' />
          </div>
        </div>
      `,
    },
  },
  4: {
    // Exercise num
    unit: "Unit 12",
    id: "WB3V2-U12-P81-E4",
    audio: "",
    video: "",
    component: T6,
    hideBtnFooter: true,
    inputSize: 250,
    titleImage: "img/FriendsPlus/Page81/E4/2.jpg",
    questionImage: [],
    questions: [
      {
        title: `
         <div style="display: flex">\
            <img src='img/FriendsPlus/Page81/E4/1.jpg' />
         
            <div style='margin: 20px 30px; line-height: 50px'>
              There’s a#.<br>
              #<br>
              There are#.<br>
              #<br>
            </div>
         </div>
        `,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 11",
    id: "WB3V2-U11-P81-E5",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    questionImage: [[{ url: "img/FriendsPlus/Page81/E5/2.jpg" }]],
  },
};

export default json;
